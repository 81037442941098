import { collection, onSnapshot, query } from 'firebase/firestore';
import db from '../backend/DBFiresbase';

export const getProveedores = ( setState ) => {
  const q = query(collection(db, "proveedores"));

  
    onSnapshot(q, (querySnapshot) => {
      const docs = [];

      querySnapshot.forEach((doc) => {
        docs.push({...doc.data(), id:doc.id});
      });

      const listaOrdenada = docs.sort((a,b) => b.fechaRegistro.localeCompare(a.fechaRegistro));
      listaOrdenada.sort((a,b) => new Date(b.fechaRegistro).getTime() - new Date(a.fechaRegistro).getTime() );
      setState(listaOrdenada);
    });
}
