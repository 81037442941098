import { TablaRegistroManoObra } from "../../pure/form/TablaRegistroManoObra";
import styles from "./Registros.module.css";


export const RegistroManoObra = () => {

    return (
        <div className={ styles.WrapperContenedor } >
            <p className="h5 fw-bold align-self-start" >Registros de Mano de Obra</p>
            <hr className="border border-dark border-1  w-100 m-1"></hr>

            <div className={`${ styles.wrapperTable }`} >
                <TablaRegistroManoObra />
            </div>
        </div>
        
    )
}
