import {useEffect, useState} from 'react';
import { GraficoBarraDobleAvances } from '../../../helpers/graficos/GraficoBarraDobleAvances';
import { Chartjs } from '../../../helpers/graficos/Chartjs';
import CardImportes from '../../../models/CardImportes';
import GraficoTipoGastos from '../../../helpers/graficos/GraficoTipoGastos';
import getSumaPresupuesto from '../../../helpers/getSumaPresupuesto';
import getSumaGastos from '../../../helpers/getSumaGastos';
import getSumaVentas from '../../../helpers/getSumaVentas';
import getSumaFacturas from '../../../helpers/getSumaFacturas';
import GraficoGastosEBG from '../../../helpers/graficos/GraficoGastosEBG';
import iconVenta  from '../../../assets/icons/icons-ventas.png';
import iconCostos from '../../../assets/icons/icons-costos.png';
import iconUtilidades from '../../../assets/icons/icons-utilidades.png';
import styles from './Resultado.module.css';



export default function Resultado() {

// todo  ---- GETTING DATA DE FIREBASE  -----
    const [ gastos, setGastos ] = useState(undefined);
    const [ ventas, setVentas ] = useState(undefined);
    const [ sumaFacturas, setSumaFacturas ] = useState(undefined);
    const [ porCobrar, setPorCobrar ] = useState(undefined);
    const [ ganancia, setGanancia ] = useState(undefined);
    const [ isLoadingCard, setIsLoadingCard ] = useState(true);


    useEffect(() => {
        getSumaVentas("proyectos", setVentas);
    }, []);

    useEffect(() => {
        getSumaGastos("proyectos", setGastos);
    }, []);

    useEffect(() => {
        getSumaFacturas("proyectos", setSumaFacturas);
    }, []);

    useEffect(() => {
        getSumaPresupuesto("proyectos",setPorCobrar);
    }, []);

    useEffect(() => {
        ((ventas - gastos) > 0) ? setGanancia(true) : setGanancia(false);
    }, [gastos, ventas])

    useEffect(() => {
        ventas >= 0 && setIsLoadingCard(false);
    }, [ ventas ])



    return (<>
        <div className='mx-auto  w-100 '>
            
            <h1 className='text-center fs-3 fw-bold mt-xxl-3 ' >Resultados Totales</h1>
            
            <div className="row w-100 mx-auto my-4 d-flex flex-column flex-md-row justify-content-between  justify-content-md-evenly align-items-center ">
                <CardImportes nombre="Facturado" 
                    importe={ new Intl.NumberFormat('de-DE').format(ventas) }
                    iva={ new Intl.NumberFormat('de-DE').format(ventas * (19/100)) || 0 }
                    asunto="IVA:"
                    display="d-flex"
                    icon={ iconVenta }
                    isLoading = { isLoadingCard }
                />

                <CardImportes nombre="Por facturar" 
                    importe={ new Intl.NumberFormat('de-DE').format(porCobrar - ventas) }
                    iva={ 0 }
                    asunto="IVA:"
                    display="d-none"
                    icon={ iconUtilidades }
                    isLoading = { isLoadingCard }
                />

                <CardImportes nombre="Costos" 
                    importe={ new Intl.NumberFormat('de-DE').format(gastos) } 
                    iva={ gastos > 0 ? ( new Intl.NumberFormat('de-DE').format((sumaFacturas) * (19/100)) || 0 ) : 0}
                    asunto="IVA:"
                    display="d-flex"
                    icon={ iconCostos }
                    isLoading = { isLoadingCard }
                />

                <CardImportes nombre="Utilidades" 
                    importe={ new Intl.NumberFormat('de-DE').format(ventas - gastos) }
                    iva={ (gastos < ventas) ? ( new Intl.NumberFormat('de-DE').format((ventas * (19/100)) - (gastos * (19/100))) || 0 ) : 0}
                    asunto="IVA:"
                    display="d-flex"
                    colorImporte={ `${(ventas - gastos) > 0 ? styles.colorGanancia : styles.colorPerdida }` }
                    isLoading = { isLoadingCard }
                    utilidad={ ganancia }
                />
            </div>


{/* ************      Graficos     ************  */}
            <div className="w-100 mx-auto text-center d-flex flex-column align-items-center flex-lg-row justify-content-evenly my-5 ">

                <div className={` ${ styles.ContainerGrafico } d-flex flex-column mb-5 mb-lg-0 `}>
                    <p className={ `mb-0 ${ styles.TituloGraficos }` }>"Comparación de Presupuestos Iniciales, Proyectados y Finales"</p>
                    <p className={ `mb-3 ${ styles.SubTituloGraficos }` } >(Valores netos en CLP$)</p>
                    <Chartjs />
                </div>

                <div className={` ${ styles.ContainerGrafico } d-flex flex-column mb-3 mb-lg-0`}>
                    <p className={ `mb-0 ${ styles.TituloGraficos }` }>"Comparación entre Ventas y Avance de Producción"</p>
                    <p className={ `mb-3 ${ styles.SubTituloGraficos }` } >(Valores netos en CLP$)</p>
                    <GraficoBarraDobleAvances />
                </div>
            </div>


            <div className="mt-3 w-100 mx-auto text-center d-flex flex-column flex-lg-row justify-content-between justify-content-evenly align-items-center ">
                
                <div className={`  ${ styles.ContainerGrafico } d-flex flex-column mb-5 mb-lg-0`}>
                    <p className={ `mb-0 ${ styles.TituloGraficos }` }>"Distribución de Gastos por Concepto"</p>
                    <p className={ `mb-0 ${ styles.SubTituloGraficos }` } >(Valores netos en CLP$)</p>
                    <GraficoTipoGastos />
                </div>

                <div className={` ${ styles.ContainerGrafico } d-flex flex-column mb-4 mb-lg-0`}>
                    <p className={ `mb-0 ${ styles.TituloGraficos }` }>"Gastos EBG Casa Matriz"</p>
                    <p className={ `mb-0 ${ styles.SubTituloGraficos }` } >(Valores netos en CLP$)</p>
                    <GraficoGastosEBG />
                </div>
            </div>

        </div>
    </>)
}
