import { useAuth } from "../../../../context/authContext";
import ItemSubmenu from "./ItemSubmenu";
import "./Submenu.css"


export default function SubmenuRegistros() {
    const { user } = useAuth();

    return (<>

        <ul className="wrapper_SubmenuContainer">
            <ItemSubmenu titulo="Reporte de Gastos"  ruta="reportes/gastos" />
            {
                (user.perfil === "Gerente" || user.perfil === "Developer") && 
                <>
                    {/* <ItemSubmenu titulo="Plan Anual"/>
                    <ItemSubmenu titulo="Calendario de Pagos"/>
                    <ItemSubmenu titulo="Reporte estratégico"/> */}
                    <ItemSubmenu titulo="Reporte de Proyectos" ruta="reportes/proyectos" />
                </>
            }
        </ul>
    </>)
}