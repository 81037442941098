import styles from './CardRemuneraciones.module.css';



export const CardRemuneraciones = ({
  sueldo,
  gratificacion,
  bonoSeguridad,
  bonoAsistencia,
  bonoObjetivo,
  bonoProduccion,
  totalImponible,
  movilizacion,
  colacion,
  asig_herramientas,
  afp,
  salud,
  cesantia,
  mutual,
  sis,
  totalMO,
  totalNoImponible,
  aguinaldo,
  horasExtras,
  horasNoTrabajadas,
  loading

}) => {


  return (
    <div className={ `d-flex gap-3 justify-content-center mx-auto mt-4  ${ styles.wrapperCard }` }>
      {
        loading ?

        <>
          <div className="w-50  p-3 d-flex flex-column justify-content-between " >

            <div>
              <div className="d-flex justify-content-between" >
                <p className="m-0" >Sueldo Base</p>
                <span>$ { new Intl.NumberFormat('de-DE').format(sueldo) }</span>
              </div>

              <div className="d-flex justify-content-between">
                <p className="m-0">Gratificación Legal</p>
                <span>$ { new Intl.NumberFormat('de-DE').format(gratificacion) }</span>
              </div>

              <div className="d-flex justify-content-between">
                <p className="m-0">Bono Seguridad</p>
                <span>$ { new Intl.NumberFormat('de-DE').format(bonoSeguridad) }</span>
              </div>

              <div className="d-flex justify-content-between">
                <p className="m-0">Bono Asistencia y Responsabilidad</p>
                <span>$ { new Intl.NumberFormat('de-DE').format(bonoAsistencia) }</span>
              </div>

              <div className="d-flex justify-content-between">
                <p className="m-0">Bono Objetivo</p>
                <span>$ { new Intl.NumberFormat('de-DE').format(bonoObjetivo) }</span>
              </div>

              <div className="d-flex justify-content-between">
                <p className="m-0">Bono Producción</p>
                <span>$ { new Intl.NumberFormat('de-DE').format(bonoProduccion) }</span>
              </div>

              <div className="d-flex justify-content-between">
                <p className="m-0">Horas Extras</p>
                <span>$ { new Intl.NumberFormat('de-DE').format(horasExtras) }</span>
              </div>

              <div className="d-flex justify-content-between">
                <p className="m-0 ">Horas No Trabajadas</p>
                <span className='text-danger' >- $ { new Intl.NumberFormat('de-DE').format(horasNoTrabajadas) }</span>
              </div>

              <div className={`d-flex justify-content-between ${ styles.totales }`}>
                <b>Total Imponible</b>
                <b>$ { new Intl.NumberFormat('de-DE').format(totalImponible) }</b>
              </div>
            </div>

            <div>
              <div className="d-flex justify-content-between">
                <p className="m-0">Movilización</p>
                <span>$ { new Intl.NumberFormat('de-DE').format(movilizacion) }</span>
              </div>

              <div className="d-flex justify-content-between">
                <p className="m-0">Colación</p>
                <span>$ { new Intl.NumberFormat('de-DE').format(colacion) }</span>
              </div>

              <div className="d-flex justify-content-between">
                <p className="m-0">Asignación Herramientas</p>
                <span>$ { new Intl.NumberFormat('de-DE').format(asig_herramientas) }</span>
              </div>

              <div className="d-flex justify-content-between">
                <p className="m-0">Aguinaldo</p>
                <span>$ { new Intl.NumberFormat('de-DE').format(aguinaldo) }</span>
              </div>

              <div className={`d-flex justify-content-between ${ styles.totales }`}>
                <b className="">Total No Imponible</b>
                <b>$ { new Intl.NumberFormat('de-DE').format(totalNoImponible) }</b>
              </div>
            </div>

          </div>

          <div className="w-50  p-3  d-flex flex-column justify-content-between" >
            <div>
              <div className="d-flex justify-content-between ">
                <p  className="m-0">AFP</p>
                <span>$ { new Intl.NumberFormat('de-DE').format(afp) }</span>
              </div>

              <div className="d-flex justify-content-between ">
                <p  className="m-0">Salud</p>
                <span>$ { new Intl.NumberFormat('de-DE').format(salud) }</span>
              </div>

              <div className="d-flex justify-content-between ">
                <p  className="m-0">Seguro cesantía</p>
                <span>$ { new Intl.NumberFormat('de-DE').format(cesantia) }</span>
              </div>

              <div className={`d-flex justify-content-between ${ styles.totales }`}>
                <b>Total Imposiciones</b>
                <b>$ { new Intl.NumberFormat('de-DE').format( afp + salud + cesantia ) }</b>
              </div>

              <div className={`d-flex justify-content-between ${ styles.totales } mt-2`}>
                <b>Mutualidad</b>
                <b>$ { new Intl.NumberFormat('de-DE').format( mutual ) }</b>
              </div>

              <div className={`d-flex justify-content-between ${ styles.totales } mt-2`}>
                <b>SIS</b>
                <b>$ { new Intl.NumberFormat('de-DE').format( sis ) }</b>
              </div>
            </div>


            <div className={`d-flex justify-content-between align-items-start`}>
              <b className={ ` text-black ${ styles.valorTotal }` } >Costo Total MO</b>
              <b className={ styles.valorTotal }>$ { new Intl.NumberFormat('de-DE').format(totalMO) }</b>
            </div>
          </div>
        </>

        :

        <div className={ `spinner-border ${styles.spinnerCard} `} role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      }
      
    </div>
  )
}
