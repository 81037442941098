

export const validateName = (e, setActive, setError ) => {
  const value = e.target.value;
  const regName = /^[a-zA-Z0-9\u00f1\u00d1\u00fcÁÉÍÓÚáéíóú]+([\-\. ]?[a-zA-Z0-9\u00f1\u00d1\u00fcÁÉÍÓÚáéíóú]+)*\.?$/;

  if( value.trim() !== "" ){
    if( !regName.test(e.target.value.trim())){
      setActive(false);
      setError("El nombre es incorrecto");
    } else {
      setActive(true);
      setError('');
    }
  } else {
    setActive(false);
    setError("Ingresar Nombre");
  }
}
