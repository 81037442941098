import styles from '../Form.module.css';

export const TBodyLoading = ({col}) => {

  return (

    <tbody className={ `table-group-divider ${styles.tableBody}` }>
      <tr   className={ `table-secondary ` }  >
        <td colSpan={ col } > 
          <div className={ `spinner-border ${styles.tableSpinner}` } role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </td>
      </tr>
    </tbody>
  )
}
