import { useEffect, useState } from 'react';
import { SpinnerCard } from '../spinner/SpinnerCard';
import Chart from 'react-apexcharts';
import getSumaGastosXArea from '../getSumaGastosXArea';
import getSumaGastoXAreaYConcepto from '../getSumaGastosXAreaYConcepto';
import styles from './Graficos.module.css';


export default function GraficoBarraConceptoGastos({tipoArea}) {

    const [ valorEquipos, setValorEquipos ] = useState(0);
    const [ valorMateriales, setValorMateriales ] = useState(0);
    const [ valorManoObra, setValorManoObra ] = useState(0);
    const [ valorCombustible, setValorCombustible ] = useState(0);
    const [ valorArriendos, setValorArriendos ] = useState(0);
    const [ valorUtilesOficina, setValorUtilesOficina ] = useState(0);
    const [ valorEPP, setValorEPP ] = useState(0);
    const [ valorMantenimiento, setValorMantenimiento ] = useState(0);
    const [ valorSeguros, setValorSeguros ] = useState(0);
    const [ valorFletes, setValorFletes ] = useState(0);
    const [ valorServicios, setValorServicios ] = useState(0);
    const [ valorExamenesMed, setValorExamenesMed ] = useState(0);
    const [ valorViaticos, setValorViaticos ] = useState(0);
    const [ valorContratistas, setValorContratistas ] = useState(0);
    const [ valorOtros, setValorOtros ] = useState(0);
    const [ valorFiniquito, setValorFiniquito ] = useState(0);
    const [ valorEBGCasaMatriz, setValorEBGCasaMatriz ] = useState(0);
    const [ valorPatentes, setValorPatentes ] = useState(0);
    const [ valorMultas, setValorMultas ] = useState(0);
    const [ valorVehiculos, setValorVehiculos ] = useState(0);
    const [ valorUtilesAseo, setValorUtilesAseo ] = useState(0);
    const [ gastosTotal, setGastosTotal ] = useState(undefined);
    const [ isLoadingData, setIsLoadingData ] = useState(true);

    const options = {
        colors: ['#0faa16'],
        chart: {
            type: 'bar',
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
                colors: {
                    backgroundBarColors: ['#B5C0B7'],
                },
            },
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: "12px",
                fontWeight: 400,
            },
            formatter: function (val) {
                return (`$ ` + new Intl.NumberFormat('de-DE').format(val) );
            },
        },
        xaxis: {
            categories: [   'Arriendos', "Contratistas", 'Combustible', 'EBG Casa Matriz', 'EPPs', 'Equipos', "Exam. Médicos", 'Finiquitos', 'Fletes',
                            "Mano de Obra", 'Mantenimientos', 'Materiales y Herr.', 'Multas', "Otros", 'Patentes', 'Seguros y/o Garantías',
                            'Servicios','Utls. de Aseo', 'Utls. de Escritorio', 'Vehículos',  "Viáticos", 
            ],
            labels: {
                style: {
                    fontSize: "12px",
                    fontWeight: "600",
                },
                formatter: function (val) {
                    return (`$ ${new Intl.NumberFormat('de-DE').format(val / 1000000)}M`);
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: "12px",
                },
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return ( val !== 0  ?  `${((val / gastosTotal)*100).toFixed(2)}%` : 0 );
                },
            },
        }
    }

    const series = [
            {
                name: "Gasto",
                data: [ valorArriendos, valorContratistas, valorCombustible, valorEBGCasaMatriz, valorEPP, valorEquipos,
                    valorExamenesMed, valorFiniquito, valorFletes,  valorManoObra,
                    valorMantenimiento, valorMateriales, valorMultas, valorOtros, valorPatentes, valorSeguros, 
                    valorServicios, valorUtilesAseo, valorUtilesOficina, valorVehiculos, valorViaticos  ], 
            },
        ]

    useEffect( () => {
        getSumaGastosXArea("proyectos", "area", tipoArea, setGastosTotal);
    }, [tipoArea]);

    useEffect( () => {
        getSumaGastoXAreaYConcepto("proyectos", "area", tipoArea, "Equipos", setValorEquipos);
    }, [tipoArea] );

    useEffect( () => {
        getSumaGastoXAreaYConcepto("proyectos", "area", tipoArea, "Materiales y Herramientas", setValorMateriales);
    }, [tipoArea] );

    useEffect( () => {
        getSumaGastoXAreaYConcepto("proyectos", "area", tipoArea, "Mano de Obra", setValorManoObra);
    }, [tipoArea] );

    useEffect( () => {
        getSumaGastoXAreaYConcepto("proyectos", "area", tipoArea, "Combustible", setValorCombustible);
    }, [tipoArea] );

    useEffect( () => {
        getSumaGastoXAreaYConcepto("proyectos", "area", tipoArea, "Arriendos", setValorArriendos);
    }, [tipoArea] );

    useEffect( () => {
        getSumaGastoXAreaYConcepto("proyectos", "area", tipoArea, "EPPs", setValorEPP);
    }, [tipoArea] );

    useEffect( () => {
        getSumaGastoXAreaYConcepto("proyectos", "area", tipoArea, "Mantenimientos", setValorMantenimiento);
    }, [tipoArea] );

    useEffect( () => {
        getSumaGastoXAreaYConcepto("proyectos", "area", tipoArea, "Fletes", setValorFletes);
    }, [tipoArea] );

    useEffect( () => {
        getSumaGastoXAreaYConcepto("proyectos", "area", tipoArea, "Servicios", setValorServicios);
    }, [tipoArea] );

    useEffect( () => {
        getSumaGastoXAreaYConcepto("proyectos", "area", tipoArea, "Exámenes médicos", setValorExamenesMed);
    }, [tipoArea] );

    useEffect( () => {
        getSumaGastoXAreaYConcepto("proyectos", "area", tipoArea, "Viáticos", setValorViaticos);
    }, [tipoArea] );

    useEffect( () => {
        getSumaGastoXAreaYConcepto("proyectos", "area", tipoArea, "Contratistas", setValorContratistas);
    }, [tipoArea] );

    useEffect( () => {
        getSumaGastoXAreaYConcepto("proyectos", "area", tipoArea, "Otros", setValorOtros);
    }, [tipoArea] );

    useEffect( () => {
        getSumaGastoXAreaYConcepto("proyectos", "area", tipoArea, "Finiquito", setValorFiniquito);
    }, [tipoArea] );

    useEffect( () => {
        getSumaGastoXAreaYConcepto("proyectos", "area", tipoArea, "Utiles de escritorio", setValorUtilesOficina);
    }, [tipoArea] );

    useEffect( () => {
        getSumaGastoXAreaYConcepto("proyectos", "area", tipoArea, "Seguros", setValorSeguros);
    }, [tipoArea] );

    useEffect( () => {
        getSumaGastoXAreaYConcepto("proyectos", "area", tipoArea, "EBG Casa Matriz", setValorEBGCasaMatriz);
    }, [tipoArea] );

    useEffect( () => {
        getSumaGastoXAreaYConcepto("proyectos", "area", tipoArea, "Patentes", setValorPatentes);
    }, [tipoArea] );

    useEffect( () => {
        getSumaGastoXAreaYConcepto("proyectos", "area", tipoArea, "Multas", setValorMultas);
    }, [tipoArea] );

    useEffect( () => {
        getSumaGastoXAreaYConcepto("proyectos", "area", tipoArea, "Vehículos", setValorVehiculos);
    }, [tipoArea] );

    useEffect( () => {
        getSumaGastoXAreaYConcepto("proyectos", "area", tipoArea, "Utiles de aseo", setValorUtilesAseo);
    }, [tipoArea] );

    useEffect(() => {
        ( gastosTotal >= 0 ) && setIsLoadingData(false);
        
    }, [ gastosTotal ]);


    return (<>
        {
            isLoadingData 
            
                ?

            <div className={ `w-100 h-100 d-flex justify-content-center align-items-center ${ styles.wrapperLoading }` } >
                <SpinnerCard />
            </div> 
            
                :

            <Chart 
                options={options}
                series={series} 
                type="bar" 
                height={450}
            /> 
        }
    </>)
}