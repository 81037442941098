import { useEffect, useState } from 'react';
import { useAuth } from '../../../context/authContext';
import { FormRegistroColaboradores } from './FormRegistroColaboradores';
import { getDataDescFechaRegistro } from '../../../helpers/getDataDescFechaRegistro';
import { BtnNavigateRegisters } from './BtnNavigateRegisters';
import { BuscadorRazonSocial, ModalForm, TBodyLoading } from './components';
import { BtnDeleteRegister } from './BtnDeleteRegister';
import { BtnEditar } from './BtnEditar';
import styles from './Form.module.css';



//Cantidad de items para paginación de gastos de Mnao de Obra
const ITEMS_NAVEGACION = 15;

export const TablaRegistroColaboradores = () => {

  const { user } = useAuth();

  // Obteniendo  datos de Firebase
  const [ colaboradores, setColaboradores ] = useState([]);
  const [ loadingTable, setLoadingTable ] = useState(false);
  const [ colaboradorFiltrado, setColaboradorFiltrado ] = useState("");

  //? Mostrando los datos en el formulario
  const [ items, setItems ] = useState([]);
  const [ currentPage, setCurrentPage ] = useState(0);


  const colaboradoresFiltrados = colaboradores?.filter( el => {
    return (
      (!colaboradorFiltrado || el.nombre.toUpperCase().includes(colaboradorFiltrado))
    )
  })

  //todo Funciones de Navegación
  const nextHandler = () => {
    const totalElementos = colaboradoresFiltrados.length;

    const nextPage = currentPage + 1;
    const firstIndex = nextPage * ITEMS_NAVEGACION;

    if(firstIndex >= totalElementos) return;

    setItems([...colaboradoresFiltrados].splice(firstIndex, ITEMS_NAVEGACION));
    setCurrentPage(nextPage);
  }

  const prevHandler = () => {
    const prevPage = currentPage - 1;
    
    if(prevPage < 0) return;

    const firstIndex = prevPage * ITEMS_NAVEGACION;

    setItems([...colaboradoresFiltrados].splice(firstIndex, ITEMS_NAVEGACION));
    setCurrentPage(prevPage);
  }

  useEffect(() => {
    getDataDescFechaRegistro('colaboradores', setColaboradores)
  }, [])

  //? Separando los datos traídos de Firebase
  useEffect(() => {
    setItems([...colaboradoresFiltrados].splice(0, ITEMS_NAVEGACION))
    setCurrentPage(0);
    setTimeout(() => {
      setLoadingTable(true);
    }, 800);
  },[colaboradores, colaboradorFiltrado]);


  return (<>
    <form className=' mx-auto d-flex justify-content-evenly mt-3 mb-4 align-items-center'>
      
      <div className="d-flex justify-content-center">
        <BuscadorRazonSocial 
          doc={ colaboradorFiltrado }
          setDoc={ setColaboradorFiltrado }
          text='Buscar colaborador'
        />
      </div>

      {
        user.perfil !== 'Gerente' &&
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className={ `btn py-1 ${styles.btnPrimary} fw-normal w-100 ` }
            data-bs-toggle="modal"
            data-bs-target="#modalRegistroColaboradores"
          >
            Registrar
          </button>
        </div>
      }
    </form>

    {/* Modal de Formulario de Registro */}
    <ModalForm 
      id='modalRegistroColaboradores'
      form={ <FormRegistroColaboradores /> }
      title='Colaboradores'
    />

    <BtnNavigateRegisters 
      prevHandler={ prevHandler }
      currentPage={ currentPage }
      nextHandler={ nextHandler }
    />

    <div className="table-responsive">
      <table className={ `table text-center table-striped rounded-top mx-auto px-2` }>
        <thead className="rounded-top">
          <tr className={ `${ styles.tableHead }` } >
            { 
              (user.perfil === "Developer"  || user.perfil === "Administrador")  &&
              <th>Eliminar</th>
            }
            {
              user.perfil !== 'Gerente' &&
              <th>Editar</th>
            }
            <th>Colaborador</th>
            <th>Sueldo Base</th>
            <th>Gratificación</th>
            <th>Bono Asist. y Resp.</th>
            <th>Bono Seguridad</th>
            <th>Bono Objetivo</th>
            <th>Movilización</th>
            <th>Colación</th>
          </tr>
        </thead>

        {
          loadingTable ?
          <tbody className={ `table-group-divider ${styles.tableBody} ` }>
          {
            items.map( el => {
              return(
                <tr key= { el.id }  className="table-secondary">
                  { 
                    (user.perfil === "Developer"  || user.perfil === "Administrador")  &&
                    <td>
                      <BtnDeleteRegister 
                        coleccion="colaboradores"
                        id={ el.id }
                      />
                    </td>
                  }
                  {
                    user.perfil !== 'Gerente' &&
                    <td>
                      <BtnEditar id={ el.id } />
                    </td>
                  }
                  <td>{ el.nombre }</td>
                  <td>$ {new Intl.NumberFormat('de-DE').format(el.sueldo_base)}</td>
                  <td>$ {new Intl.NumberFormat('de-DE').format(el.gratificacion_legal)}</td>
                  <td>$ {new Intl.NumberFormat('de-DE').format(el.bono_asistencia)}</td>
                  <td>$ {new Intl.NumberFormat('de-DE').format(el.bono_seguridad)}</td>
                  <td>$ {new Intl.NumberFormat('de-DE').format(el.bono_objetivo)}</td>
                  <td>$ {new Intl.NumberFormat('de-DE').format(el.movilizacion)}</td>
                  <td>$ {new Intl.NumberFormat('de-DE').format(el.colacion)}</td>
                </tr>
                
              )
            })
          }
          </tbody>
          :
          <TBodyLoading col='10' />
        }
        
      </table>
    </div>


  </>)
}
