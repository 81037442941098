import { query, collection, onSnapshot, orderBy, where } from 'firebase/firestore';
import db from '../backend/DBFiresbase';

const  getUtilidadesFinales = (estado) => {
    onSnapshot(query(collection(db, "proyectos"), where("estado", "==", "Ejecución"), orderBy("fechaRegistro", "asc")), (querySnapshot) => {
        const docs = [];

        querySnapshot.forEach((doc) => {
            docs.push({...doc.data(), id:doc.id});
        });

        const gastoTotal = docs.map(el => el.gastos.length ? el.gastos : [0]);
        const listaPresupuesto = docs.map(el => el.presupuesto);

        let listaGastos = [];

        gastoTotal.forEach( (array) => {

            if(array.length){
                listaGastos.push(array.map(gasto => gasto ? parseInt(gasto.valor) : 0 ).reduce((a,b) => a+b))
                
            }
            
        })

        const listaFinal = listaPresupuesto.map((valor, indice) => valor - listaGastos[indice]);

        estado(listaFinal.length ? listaFinal : 0);
    });

}

export default  getUtilidadesFinales;