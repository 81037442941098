import {query, collection, onSnapshot, orderBy, where} from 'firebase/firestore';
import db from '../backend/DBFiresbase';



export const getCostosContratistasProy = ( estado ) => {
    const q = query(collection(db,"proyectos"), where("estado", "==", "Ejecución"), orderBy("fechaRegistro", "asc"));

    onSnapshot(q, (querySnapshot) => {
        const docs = [];

        querySnapshot.forEach((doc) => {
            docs.push({...doc.data(), id:doc.id});
        });

        const listaCostos = docs.map(el => el.costoContratistasProyectado ? parseInt(el.costoContratistasProyectado) : 0);

        estado(listaCostos);
    });
}