import { useEffect, useState } from "react";
import { useAuth } from "../../../context/authContext";
import { BtnNavigateRegisters } from "./BtnNavigateRegisters";
import { BtnDeleteRegister } from "./BtnDeleteRegister";
import { BtnEditar } from "./BtnEditar";
import { BuscadorRut } from "./components/BuscadorRut";
import { getProveedores } from "../../../helpers/getProveedores";
import { BuscadorRazonSocial, ErrorBusqueda, ModalForm } from "./components";
import FormRegistroProveedores from "./FormRegistroProveedores";
import styles from './Form.module.css';



//Cantidad de items para paginación de gastos de Mnao de Obra
const ITEMS_NAVEGACION = 20;

export const TablaRegistroProveedores = () => {

  const { user } = useAuth();

  const [ proveedores, setProveedores ] = useState([]);
  const [ loadingTable, setLoadingTable ] = useState(false);
  const [ rut, setRut ] = useState('');
  const [ razonSocial, setRazonSocial ] = useState('');


  // Obteniendo  datos de Firebase
  useEffect(() => {
      getProveedores(setProveedores);
  }, []);

  const proveedoresFiltrados = proveedores?.filter(el => {
    return (
      ( !rut || el.rut?.includes(rut) ) &&
      ( !razonSocial || el.razonSocial?.includes(razonSocial) )
    )
  })


  //? Mostrando los datos en el formulario
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);


  //? Separando los datos traídos de Firebase
  useEffect(() => {
    setItems([...proveedoresFiltrados].splice(0, ITEMS_NAVEGACION))
    setCurrentPage(0);
    setTimeout(() => {
      setLoadingTable(true);
    }, 800);
  },[ proveedores, rut, razonSocial ])



  //todo Funciones de Navegación
  const nextHandler = () => {
    const totalElementos = proveedoresFiltrados.length;

    const nextPage = currentPage + 1;
    const firstIndex = nextPage * ITEMS_NAVEGACION;

    if(firstIndex >= totalElementos) return;

    setItems([...proveedoresFiltrados].splice(firstIndex, ITEMS_NAVEGACION));
    setCurrentPage(nextPage);
  }


  const prevHandler = () => {
    const prevPage = currentPage - 1;
    
    if(prevPage < 0) return;

    const firstIndex = prevPage * ITEMS_NAVEGACION;

    setItems([...proveedoresFiltrados].splice(firstIndex, ITEMS_NAVEGACION));
    setCurrentPage(prevPage);
  }


  return (<>
    <form className=' mx-auto d-flex justify-content-evenly  my-4 align-items-start'>
      <div className=" form-check d-flex align-items-center flex-column" >
        <BuscadorRazonSocial 
          doc={ razonSocial }
          setDoc={ setRazonSocial }
          text="Buscar Razón Social..."
        />
      </div>

      <div className=" form-check d-flex align-items-center flex-column" >
        <BuscadorRut 
          doc={ rut }
          setDoc={ setRut }
        />
      </div>

      {
        user.perfil !== 'Gerente' &&
        <button 
          className={ `btn py-1 ${styles.btnPrimary} fw-normal` }  
          type="button" 
          data-bs-toggle="modal" 
          data-bs-target="#modalRegistroProveedores" 
        >
        Registrar
        </button>
      }
    </form>

    {/* Modal de Formulario de Registro de Proveedores */}
    <ModalForm 
      id='modalRegistroProveedores'
      form={ <FormRegistroProveedores proveedores={ proveedores } /> }
      title='Proveedores'
    />

    <BtnNavigateRegisters 
      prevHandler={ prevHandler }
      currentPage={ currentPage }
      nextHandler={ nextHandler }
    />

  <div className="table-responsive">
    <table className={ `table text-center table-striped rounded-top ${styles.tableXLarge} mx-auto px-2` }>
      <thead className="rounded-top">
        <tr className={ `${ styles.tableHead }` } >
          { 
            (user.perfil === "Developer"  || user.perfil === "Administrador")  &&
            <th>Eliminar</th>
          }
          {
            user.perfil !== 'Gerente' &&
            <th>Editar</th>
          }
          <th>Razón Social</th>
          <th>Forma de Pago</th>
          <th>RUT</th>
          <th>Giro</th>
          <th>Dirección</th>
          <th>Comuna</th>
          <th>Región</th>
        </tr>
      </thead>

    {
      loadingTable ? 
          <tbody className={ `table-group-divider ${styles.tableBody} ` }>
            { items.length >= 1 ?

              items.map((el) => {
                return(
                  <tr key= { el.id }  className={ `table-secondary ` }  >
                    { 
                      (user.perfil === "Developer"  || user.perfil === "Administrador")  &&
                      <td>
                        <BtnDeleteRegister 
                          coleccion="proveedores"
                          id={ el.id } />
                      </td>
                    }
                    {
                      user.perfil !== 'Gerente' &&
                      <td>
                        <BtnEditar id={ el.id } />
                      </td>
                    }
                    <td>{ el.razonSocial }</td>
                    <td className={ `${styles.thMedium}`} >{ el.formaPago }</td>
                    <td className={ `${styles.thMedium}`} >{ el.rut }</td>
                    <td>{ el.suministro }</td>
                    <td>{ el.direccion }</td>
                    <td className={ `${styles.thMedium}`} >{ el.comuna }</td>
                    <td>{ el.region }</td>
                  </tr>
                ) 
              }) :

              <ErrorBusqueda 
                colSpan={ 9 }
                text='proveedores registrados'
              />
            }
          </tbody>

      :

      <tbody className={ `table-group-divider ${styles.tableBody}` }>
        <tr   className={ `table-secondary ` }  >
          <td colSpan="9" > 
            <div className={ `spinner-border ${styles.tableSpinner}` } role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </td>
        </tr>
      </tbody>
    }

    </table>
  </div>

  </>)
}
