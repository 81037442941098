import {query, collection, onSnapshot , where} from 'firebase/firestore';
import { filterProjectsByState } from './filterProjectsByState';
import { getAscOrderedProjectsByDate } from './getAscOrderedProjectsByDate';
import db from '../backend/DBFiresbase';


export const getSalesProgressByAreaOfActiveProjects = ( area, setState ) => {
  
  const q = query(collection(db, 'proyectos' ), where( "area" , "==", area));

  onSnapshot(q, (querySnapshot) => {
    const docs = [];

    querySnapshot.forEach((doc) => {
        docs.push({...doc.data()});
    });

    const filteredProjectsInExecution = filterProjectsByState(docs, "Ejecución");

    const projectsOrderedByDate = getAscOrderedProjectsByDate(filteredProjectsInExecution);


    const salesProgress = projectsOrderedByDate.map(sale => {
        const budget = parseInt(sale.presupuesto);
        const totalSales = sale.ventas.length ? parseInt(sale.ventas.map(venta => parseInt(venta.valor)).reduce((a,b) => a+b)) : 0;
        const salesPercentage = parseInt(((totalSales / budget)*100).toFixed(2))
        return salesPercentage;
    });
    

    setState(salesProgress);
  });
}
