import { useEffect, useState } from 'react';
import { useAuth } from '../../../context/authContext';
import { BtnDeleteVenta } from './BtnDeleteVenta';
import { BtnEditar } from './BtnEditar';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { BuscadorDocs } from './components/BuscadorDocs';
import { BtnNavigateRegisters } from './BtnNavigateRegisters';
import { SelectAnio } from './SelectAnio';
import { SelectMes } from './SelectMes';
import { getListaClientes } from '../../../helpers/getListaClientes';
import { getListaProyectosAsc } from '../../../helpers/getListaProyectosAsc';
import { CardDataForm } from './CardDataForm';
import { TBodyLoading, ErrorBusqueda, ModalForm } from './components';
import db from '../../../backend/DBFiresbase';
import moment from 'moment';
import FormRegistroVentas from './FormRegistroVentas';
import styles from './Form.module.css';




//Cantidad de items para paginación de gastos de Mnao de Obra
const ITEMS_NAVEGACION = 15;

export const TablaRegistroVentas = () => {

  const { user } = useAuth();

  const [ loadingTable, setLoadingTable ] = useState(false);
  const [ loadingCardDataForm, setLoadingCardDataForm ] = useState(false);
  const [ numDocumento, setNumDocumento ] = useState('');
  const [ anio, setAnio ] = useState("");
  const [ mes, setMes ] = useState("");
  const [ proyecto, setProyecto ] = useState("");
  const [ cliente, setCliente ] = useState('');
  const [ precioVentaTotal, setPrecioVentaTotal ] = useState("");
  const [ tipoVenta, setTipoVenta ] = useState('');



  // Obteniendo  datos de Firebase
  const [ ventas, setVentas ] = useState([]);

  //Capturando los valores de los inputs del form
  const handleChange = (e, setState)=>{
    const { value } = e.target;
    setState(value);
  }

  const q = query(collection(db, "proyectos"), where('nombre', '!=', 'EBG Casa Matriz'));

  const getData = async () => {
  onSnapshot(q, (querySnapshot) => {
          const docs = [];
  
          querySnapshot.forEach((doc) => {
            docs.push({...doc.data(), id:doc.id});
          });

          const precioTotal = docs.map(el => el.ventas);

          let lista = [];
          
          precioTotal.forEach( (array) => {
            if(array.length){
              array.map(el => lista.push( el))
            }
          })

          const listaOrdenada = lista.sort((a,b) => b.fechaRegistro.localeCompare(a.fechaRegistro));
          listaOrdenada.sort((a,b) => new Date(b.fechaRegistro).getTime() - new Date(a.fechaRegistro).getTime());
          setVentas(listaOrdenada);
          setTimeout(() => {
            setLoadingTable(true);
          }, 800);
      });
  }


  const ventasFiltradas = ventas?.filter(el => {
    return (
      (!proyecto || el.proyecto === proyecto) &&
      (!anio || el.anioVenta === anio) &&
      (!mes || el.mesVenta === mes) &&
      (!cliente || el.cliente === cliente) &&
      (!numDocumento || el.numDocumento.includes(numDocumento)) &&
      (!tipoVenta || el.tipoVenta === tipoVenta)
    )
  }) 

  //? Mostrando los datos en el formulario
  const [ items, setItems ] = useState([]);
  const [ currentPage, setCurrentPage ] = useState(0);


  //? Separando los datos traídos de Firebase
  useEffect(() => {
    setItems([...ventasFiltradas].splice(0, ITEMS_NAVEGACION))
    setCurrentPage(0);
    setTimeout(() => {
      setLoadingTable(true);
    }, 800);
  },[ ventas, numDocumento, anio, mes, cliente, proyecto, tipoVenta ]);


  //todo Funciones de Navegación
  const nextHandler = () => {
    const totalElementos = ventasFiltradas.length;

    const nextPage = currentPage + 1;
    const firstIndex = nextPage * ITEMS_NAVEGACION;

    if(firstIndex >= totalElementos) return;

    setItems([...ventasFiltradas].splice(firstIndex, ITEMS_NAVEGACION));
    setCurrentPage(nextPage);
  }

  const prevHandler = () => {
    const prevPage = currentPage - 1;
    
    if(prevPage < 0) return;

    const firstIndex = prevPage * ITEMS_NAVEGACION;

    setItems([...ventasFiltradas].splice(firstIndex, ITEMS_NAVEGACION));
    setCurrentPage(prevPage);
  }

  // Mostrando los datos en la interfaz
  useEffect(() => {
      getData();
  }, []);

  //todo  Mostrando los datos en la interfaz  *****
  const [ proyectos, setProyectos ] = useState([]);
  const [ clientes, setClientes ] = useState([]);


  useEffect(() => {
    getListaProyectosAsc(setProyectos);
  }, []);


  useEffect(() => {
    getListaClientes(setClientes);
  }, []);


  useEffect(() => {
    setPrecioVentaTotal(ventasFiltradas
                        .reduce((acc, el) => acc + parseInt(el.valor), 0 ));

    setTimeout(() => {
      setLoadingCardDataForm(true);
    }, 800);
  }, [ventasFiltradas] );

  return (<>
    <div className="container d-flex justify-content-evenly mt-3">
      <CardDataForm 
        title='Total Ventas'
        data={ precioVentaTotal }
        loading={ loadingCardDataForm }
      />
    </div>

    <form className=' mx-auto d-flex justify-content-evenly  my-4 align-items-center'>
      <div className=" form-check d-flex align-items-center flex-column" >
        <BuscadorDocs
          doc={ numDocumento }
          setDoc={ setNumDocumento }
          texto="Buscar factura..."
        />
      </div>

      <div className='d-flex align-items-center'>
        <SelectAnio 
          className={ `form-select ${ styles.selectForm } text-center pe-0 py-1` }
          eventChange={ (e) => handleChange(e, setAnio) }
          anio= { anio }
        />
      </div>

      <div className=' d-flex align-items-center gap-1'>
        <SelectMes 
          handleChange= { (e) => handleChange(e, setMes) }
          className={ `form-select ${ styles.selectForm } pe-0 py-1` }
        />
      </div>

      {
        user.perfil !== 'Gerente' &&
        <button 
          className={ `btn py-1 ${styles.btnPrimary} fw-normal` } 
          type="button" 
          data-bs-toggle="modal" 
          data-bs-target="#modalRegistroVentas" 
        >
        Registar
        </button>
      }
    </form>

    {/* Modal de Formulario de Registro de Ventas */}
    <ModalForm 
      id='modalRegistroVentas'
      form={ <FormRegistroVentas ventas={ ventas }/> }
      title='Ventas'
    />

    <BtnNavigateRegisters 
      prevHandler={ prevHandler }
      currentPage={ currentPage }
      nextHandler={ nextHandler }
    />

    <div className="table-responsive">
      <table className={ `table text-center table-striped rounded-top ${styles.tableXLarge} mx-auto px-2` }>
        <thead className="rounded-top">
          <tr className={ `${ styles.tableHead }` } >
            { 
              (user.perfil === "Developer"  || user.perfil === "Administrador")  &&
              <th>Eliminar</th>
            }
            {
              user.perfil !== 'Gerente' &&
              <th>Editar</th>
            }
            <th>Fecha</th>
            <th>Venta</th>
            <th>
              <select
                onChange={(e) => handleChange(e, setProyecto)}
                className={ `form-select ${ styles.selectTable } pe-0 py-1 mx-auto` } 
                name="proyecto"
                value={ proyecto }
              >
                <option value="">Proyecto</option>
                { proyectos.map((el, index) => {
                    return(
                    <option value={el} key={ index + el }> {el} </option>
                    )
                })}
              </select>
            </th>
            <th>
              <select
                onChange={(e) => handleChange(e, setTipoVenta)}
                className={ `form-select ${ styles.selectTable } pe-0 py-1 mx-auto` } 
                name="tipoVenta"
                value={ tipoVenta }>
                <option value="">Tipo de Venta</option>
                <option value="Contractual">Contractual</option>
                <option value="Adicional">Adicional</option>
              </select>
            </th>
            <th>N° Factura</th>
            <th>N° Conformidad</th>
            <th>N° O. de Compra</th>
            <th>
              <select
                onChange={(e) => handleChange(e, setCliente)}
                className={ `form-select ${ styles.selectTable } pe-0 py-1 mx-auto` } 
                name="cliente"
                value={ cliente }>
                <option value="">Cliente</option>
                { clientes.map((el, index) => {
                  return(
                    <option value={el} key={ index + el }> {el} </option>
                  )
                })}
              </select>
            </th>
            <th>RUT Cliente</th>
          </tr>
        </thead>

        {
          loadingTable ? 
          <tbody className={ `table-group-divider ${styles.tableBody} ` }>
            { items.length >= 1 ?

              items.map((el,index) => {
                return(
                  <tr key= {index}  className="table-secondary" >
                    { 
                      (user.perfil === "Developer"  || user.perfil === "Administrador")  &&
                      <td>
                        <BtnDeleteVenta 
                          coleccion="proyectos"
                          proyecto={ `${el.proyecto}` } 
                          documento={ `${el.numDocumento}` }
                          tipo='ventas'
                        />
                      </td>
                    }
                    {
                      user.perfil !== 'Gerente' &&
                      <td>
                        <BtnEditar id={ `${el.proyecto}-${el.numDocumento}`} />
                      </td>
                    }
                    <td>{moment(el.fechaVenta).format('DD-MM-YYYY')}</td>
                    <td>$ {new Intl.NumberFormat('de-DE').format(el.valor)}</td>
                    <td className={ `${styles.thLarge}`} >{el.proyecto}</td>
                    <td>{ el.tipoVenta }</td>
                    <td>{el.numDocumento}</td>
                    <td>{el.numConformidad}</td>
                    <td>{el.ordenCompra || el.nOCAdicional}</td>
                    <td>{el.cliente}</td>
                    <td>{el.rutCliente}</td>
                  </tr>
                      )
                  }) :

                <ErrorBusqueda 
                  colSpan={ 11 }
                  text='ventas registradas'
                />
              }
          </tbody>

          :

          <TBodyLoading col='10' />
        }
      </table>
    </div>

  </>)
}
