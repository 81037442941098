import styles from '../Form.module.css';

export const SelectTipoDoc = ({
    title,
    handleChange,
    handleBlur,
    name,
    value,
    error,
  }) => {
    return (
      <div className="col-md-4">
        <label className={` form-label ${ styles.labelForm } `}> { title } </label>
        <select 
          className={`form-select ${ styles.inputForm } ${ error === '' ? "" : styles.errorInput }`}
          onChange={ handleChange }
          onBlur={ handleBlur }
          name={ name }
          value={ value }
        >
          <option value="">Seleccione</option>
          <option value="Boleta">Boleta</option>
          <option value="Boleta de Honorarios">Boleta de Honorarios</option>
          <option value="Boleta Exenta">Boleta Exenta</option>
          <option value="Comprobante">Comprobante</option>
          <option value="Costo de Oficina">Costo de Oficina</option>
          <option value="Costo de Personal">Costo de Personal</option>
          <option value="Factura">Factura</option>
          <option value="Factura exenta">Factura Exenta de IVA</option>
          <option value="Guía de Despacho">Guía de Despacho</option>
          <option value="Nota de Crédito">Nota de Crédito</option>
          <option value="Nota de Débito">Nota de Débito</option>
          <option value="Recibo">Recibo</option>
        </select>
        <span className={ styles.error }>
          { error !== "" ? error : "" }
        </span>
      </div>

    )
}
