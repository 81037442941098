import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGastos } from "../../../store/slices/thunks";
import { SelectAnio } from "./SelectAnio";
import { SelectMes } from "./SelectMes";
import { BtnNavigateRegisters } from "./BtnNavigateRegisters";
import { BtnEditar } from "./BtnEditar";
import { BtnDeleteGasto } from "./BtnDeleteGasto";
import { getListaProyectosAsc } from "../../../helpers/getListaProyectosAsc";
import { getDataCollectionColaboradores } from "../../../helpers/getDataCollectionColaboradores";
import { CardRemuneraciones } from "../../../models/CardRemuneraciones";
import { FormRegistroManoObra } from "./FormRegistroManoObra";
import { ModalForm, TBodyLoading } from "./components";
import { useAuth } from "../../../context/authContext";
import moment from "moment";
import styles from "./Form.module.css";



//Cantidad de items para paginación de gastos de Mnao de Obra
const ITEMS_NAVEGACION = 10;


export const TablaRegistroManoObra = () => {

  const { user } = useAuth();

  //todo  Obteniendo  todos los gatos de los proyectos con Redux
  const dispacth= useDispatch();
  const { gastos= [] } = useSelector( state => state.gastos )

  useEffect(() => {
      dispacth( getGastos() );
  }, []);
//todo FIN  Obteniendo  todos los gatos de los proyectos con Redux ***

//todo Mostrando los datos en el formulario
  const [ items, setItems ] = useState([]);
  const [ currentPage, setCurrentPage ] = useState(0);

//todo  Clonando los gastos traídos desde Redux *****
  const [ gastosMO, setGastosMO ] = useState(gastos.filter(objeto => objeto.concepto === "Mano de Obra"));
  const [ anio, setAnio ] = useState("");
  const [ mes, setMes ] = useState("");
  const [ proyecto, setProyecto ] = useState("");
  const [ colaborador, setColaborador ] = useState("");
  const [ precioGastoTotal, setPrecioGastoTotal ] = useState(0);
  const [ loadingCardDataForm, setLoadingCardDataForm ] = useState(false);


  //Capturando los valores de los inputs del form
  const handleChange = (e, setState) => {
    const { value } = e.target;
    setState(value);
  }

  useEffect(() => {
    const gastosFiltrados = gastos.filter(objeto => objeto.concepto === "Mano de Obra");
    const listaOrdenada = gastosFiltrados.sort((a,b) => b.fechaRegistro.localeCompare(a.fechaRegistro));
    listaOrdenada.sort((a,b) => new Date(b.fechaRegistro).getTime() - new Date(a.fechaRegistro).getTime() );
    setGastosMO(listaOrdenada);
    
    setTimeout(() => {
      setLoadingCardDataForm(true);
    }, 600);
  }, [gastos]);


//todo Generando los gastos filtrados
  const gastosFiltrados = gastosMO?.filter(el => {

    return (
      (!anio || el.anioGasto === anio) &&
      (!mes || el.mesGasto === mes) &&
      (!proyecto || el.proyecto === proyecto) &&
      (!colaborador || el.nombre_trabajador === colaborador)
    );
  });

//todo Separando los datos traídos de Firebase
  useEffect(() => {
    setItems([...gastosFiltrados].splice(0, ITEMS_NAVEGACION))
    setCurrentPage(0);
  },[ gastos, anio, mes, gastosMO, proyecto, colaborador ])


//todo Funciones de Navegación
  const nextHandler = () => {
    const totalElementos = gastosFiltrados.length;

    const nextPage = currentPage + 1;
    const firstIndex = nextPage * ITEMS_NAVEGACION;

    if(firstIndex >= totalElementos) return;

    setItems([...gastosFiltrados].splice(firstIndex, ITEMS_NAVEGACION));
    setCurrentPage(nextPage);
  }


  const prevHandler = () => {
    const prevPage = currentPage - 1;
    
    if(prevPage < 0) return;

    const firstIndex = prevPage * ITEMS_NAVEGACION;

    setItems([...gastosFiltrados].splice(firstIndex, ITEMS_NAVEGACION));
    setCurrentPage(prevPage);
  }

  const [ proyectos, setProyectos ] = useState([]);
  const [ colaboradores, setColaboradores ] = useState([]);
  const [ sueldoBase, setSueldoBase ] = useState(0);
  const [ gratificacion, setGratificacion ] = useState(0);
  const [ bonoSeguridad, setBonoSeguridad] = useState(0);
  const [ bonoAsistencia, setBonoAsistencia ] = useState(0);
  const [ bonoObjetivo, setBonoObjetivo ] = useState(0);
  const [ bonoProduccion, setBonoProduccion ] = useState(0);
  const [ totalImponible, setTotalImponible ] = useState(0);
  const [ movilizacion, setMovilizacion ] = useState(0);
  const [ colacion, setColacion ] = useState(0);
  const [ asigHerramientas, setAsigHerramientas] = useState(0);
  const [ afp, setAfp ] = useState(0);
  const [ salud, setSalud ] = useState(0);
  const [ cesantia, setCesantia ] = useState(0);
  const [ mutual, setMutual ] = useState(0);
  const [ sis, setSis ] = useState(0);
  const [ totalNoImponible, setTotalNoImponible ] = useState(0);
  const [ aguinaldo, setAguinaldo ] = useState(0);
  const [ horasExtras, setHorasExtras ] = useState(0);
  const [ horasNoTrabajadas, setHorasNoTrabajadas ] = useState(0);

  useEffect( () => {
    getListaProyectosAsc(setProyectos);
  }, []);

  useEffect(() => {
    getDataCollectionColaboradores(setColaboradores);
  }, []);

  useEffect( () => {
    setPrecioGastoTotal(gastosFiltrados.reduce((acc, el) => acc + parseInt(el.valor), 0 ));
    setSueldoBase(gastosFiltrados.reduce((acc, el) => acc + parseInt(el.sueldo_base), 0 ));
    setGratificacion(gastosFiltrados.reduce((acc, el) => acc + parseInt(el.gratificacion), 0 ));
    setBonoSeguridad(gastosFiltrados.reduce((acc, el) => acc + parseInt(el.bono_seguridad), 0 ));
    setBonoAsistencia(gastosFiltrados.reduce((acc, el) => acc + parseInt(el.bono_asistencia), 0 ));
    setBonoObjetivo(gastosFiltrados.reduce((acc, el) => acc + parseInt(el.bono_objetivo), 0 ));
    setBonoProduccion(gastosFiltrados.reduce((acc, el) => acc + parseInt(el.bono_produccion), 0 ));
    setTotalImponible(gastosFiltrados.reduce((acc, el) => acc + parseInt(el.total_imponible), 0 ));
    setMovilizacion(gastosFiltrados.reduce((acc, el) => acc + parseInt(el.movilizacion), 0 ));
    setColacion(gastosFiltrados.reduce((acc, el) => acc + parseInt(el.colacion), 0 ));
    setAsigHerramientas(gastosFiltrados.reduce((acc, el) => acc +  parseInt(el.asig_herramientas), 0 ));
    setAfp(gastosFiltrados.reduce((acc, el) => acc + parseInt(el.afp), 0 ));
    setSalud(gastosFiltrados.reduce((acc, el) => acc + parseInt(el.prevision_salud), 0 ));
    setCesantia(gastosFiltrados.reduce((acc, el) => acc + parseInt(el.cesantia), 0 ));
    setMutual(gastosFiltrados.reduce((acc, el) => acc + parseInt(el.mutual), 0 ));
    setSis(gastosFiltrados.reduce((acc, el) => acc + parseInt(el.sis), 0 ));
    setAguinaldo(gastosFiltrados.reduce((acc, el) => acc + parseInt(el.aguinaldo), 0 ));
    setHorasExtras(gastosFiltrados.reduce((acc, el) => acc + parseInt(el.horas_extras), 0 ));
    setHorasNoTrabajadas(gastosFiltrados.reduce((acc, el) => acc + parseInt(el.horas_no_trabajadas), 0 ));
    setTotalNoImponible(gastosFiltrados.reduce((acc, el) => acc + parseInt(el.total_no_imponible), 0 ));
  }, [ gastosFiltrados ]);
//todo ******** Fin Clonando los gastos traídos desde Redux *****


  return (<>
      <CardRemuneraciones 
        sueldo={ sueldoBase }
        gratificacion={ gratificacion }
        bonoSeguridad={ bonoSeguridad }
        bonoAsistencia={ bonoAsistencia }
        bonoObjetivo={ bonoObjetivo }
        bonoProduccion={ bonoProduccion }
        totalImponible={ totalImponible }
        movilizacion={ movilizacion }
        colacion={ colacion }
        asig_herramientas={ asigHerramientas }
        afp={ afp }
        salud={ salud }
        cesantia={ cesantia }
        mutual={ mutual }
        sis={ sis }
        totalMO={ precioGastoTotal }
        totalNoImponible={ totalNoImponible }
        aguinaldo={ aguinaldo }
        horasExtras={ horasExtras }
        horasNoTrabajadas={ horasNoTrabajadas }
        loading={ loadingCardDataForm }
      />
    

    <form className=' mx-auto d-flex justify-content-evenly mt-5 mb-4 align-items-center'>

      <div className='d-flex align-items-center'>
        <SelectAnio 
          className={ `form-select ${ styles.selectForm } text-center pe-0 py-1` }
          eventChange={ (e) => handleChange(e, setAnio) }
          anio= { anio }
        />
      </div>

      <div className=' d-flex align-items-center gap-1'>
        <SelectMes 
          handleChange= { (e) => handleChange(e, setMes) }
          className={ `form-select ${ styles.selectForm } pe-0 py-1` }
        />
      </div>

      {
        user.perfil !== 'Gerente' &&
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className={ `btn py-1 ${styles.btnPrimary} fw-normal w-100 ` }
            data-bs-toggle="modal"
            data-bs-target="#modalRegistroMO"
          >
            Registrar
          </button>
        </div>
      }
    </form>

    <BtnNavigateRegisters 
      prevHandler={ prevHandler }
      currentPage={ currentPage }
      nextHandler={ nextHandler }
    />

    {/* Modal de Formulario de Registro */}
    <ModalForm 
      id='modalRegistroMO'
      form={ <FormRegistroManoObra /> }
      title='Mano de Obra'
    />

    <div className="table-responsive">
      <table  className={ `table text-center table-striped rounded-top ${ styles.tableXXXLarge } mx-auto px-2 `}>
        <thead className="rounded-top">
          <tr className={`${ styles.tableHead } `}  >
            { 
              (user.perfil === "Developer"  || user.perfil === "Administrador")  &&
              <th>Eliminar</th>
            }
            {
              user.perfil !== 'Gerente' &&
              <th>Editar</th>
            }
            <th>Fecha</th>
            <th>
              <select
                onChange={(e) => handleChange(e, setProyecto)}
                className={ `form-select ${ styles.selectTable } pe-0 py-1 mx-auto` } 
                name="proyecto"
                value={ proyecto }>
                <option value="">Proyecto</option>
                  { 
                    proyectos.map((el, index) => {
                      return(
                      <option value={ el } key={index + el}> { el } </option>
                      )
                    })
                  }
              </select>
            </th>
            <th>
              <select
                onChange={(e) => handleChange(e, setColaborador)}
                className={ `form-select ${ styles.selectTable } pe-0 py-1 mx-auto` } 
                name="colaborador"
                value={ colaborador }>
                <option value="">Colaborador</option>
                  { 
                    colaboradores.map((el, index) => {
                      return(
                      <option value={ el } key={index + el}> { el } </option>
                      )
                    })
                  }
              </select>
            </th>
            <th>D. Trab.</th>
            <th>Costo MO</th>
            <th>T. Imponi.</th>
            <th>S. Base</th>
            <th>Gratif.</th>
            <th>B. Asist.</th>
            <th>B. Seg.</th>
            <th>B. Prod.</th>
            <th>B. Objet.</th>
            <th>Hrs. Extras</th>
            <th>Hrs. No Trab.</th>
            <th>T. No Imponi.</th>
            <th>Movili.</th>
            <th>Colación</th>
            <th>Asig. Herr.</th>
            <th>Aguinaldo</th>
            <th>AFP</th>
            <th>Salud</th>
            <th>Cesantía</th>
            <th>Mutual</th>
            <th>SIS</th>
          </tr>
        </thead>

        {
          loadingCardDataForm 
          ?
            <tbody className={ `table-group-divider ${styles.tableBody}` } >
            {
              items.map((el,index) => {
                return(
                  <tr key= {index}  className={`table-secondary `} >
                  { 
                    (user.perfil === "Developer"  || user.perfil === "Administrador")  &&
                    <td>
                      <BtnDeleteGasto 
                        proyecto={ `${el.proyecto}` } 
                        documento={ `${el.fechaRegistro}` }
                        numDocumento="fechaRegistro"
                      />
                    </td>
                  }
                  {
                    user.perfil !== 'Gerente' &&
                    <td>
                      <BtnEditar id={ `${el.proyecto}-${el.fechaRegistro}`} />
                    </td>
                  }
                    <td> { moment(el.fechaGasto).format('YYYY-MM-DD') } </td>
                    <td> { el.proyecto } </td>
                    <td> { el.nombre_trabajador } </td>
                    <td> { el.dias_trabajados } </td>
                    <td className="fw-bold " >$ { Intl.NumberFormat('de-DE').format(el.valor) } </td>
                    <td className="fw-bold " >$ { Intl.NumberFormat('de-DE').format(  Math.round(parseFloat(el.total_imponible))) } </td>
                    <td>$ { Intl.NumberFormat('de-DE').format(  Math.round(parseFloat(el.sueldo_base))) } </td>
                    <td>$ { Intl.NumberFormat('de-DE').format(  Math.round(parseFloat(el.gratificacion))) } </td>
                    <td>$ { Intl.NumberFormat('de-DE').format(  Math.round(parseFloat(el.bono_asistencia))) } </td>
                    <td>$ { Intl.NumberFormat('de-DE').format(  Math.round(parseFloat(el.bono_seguridad))) } </td>
                    <td>$ { Intl.NumberFormat('de-DE').format(  Math.round(parseFloat(el.bono_produccion))) }</td>
                    <td>$ { Intl.NumberFormat('de-DE').format(  Math.round(parseFloat(el.bono_objetivo))) }</td>
                    <td>$ { Intl.NumberFormat('de-DE').format(  Math.round(parseFloat(el.horas_extras))) }</td>
                    <td>$ { Intl.NumberFormat('de-DE').format(  Math.round(parseFloat(el.horas_no_trabajadas))) }</td>
                    <td className="fw-bold " >$ { Intl.NumberFormat('de-DE').format(  Math.round(parseFloat(el.total_no_imponible))) }</td>
                    <td>$ { Intl.NumberFormat('de-DE').format(  Math.round(parseFloat(el.movilizacion))) }</td>
                    <td>$ { Intl.NumberFormat('de-DE').format(  Math.round(parseFloat(el.colacion))) }</td>
                    <td>$ { Intl.NumberFormat('de-DE').format(  Math.round(parseFloat(el.asig_herramientas))) }</td>
                    <td>$ { Intl.NumberFormat('de-DE').format(  Math.round(parseFloat(el.aguinaldo))) }</td>
                    <td>$ { Intl.NumberFormat('de-DE').format(  Math.round(parseFloat(el.afp))) } </td>
                    <td>$ { Intl.NumberFormat('de-DE').format(  Math.round(parseFloat(el.prevision_salud))) } </td>
                    <td>$ { Intl.NumberFormat('de-DE').format(  Math.round(parseFloat(el.cesantia))) } </td>
                    <td>$ { Intl.NumberFormat('de-DE').format(  Math.round(parseFloat(el.mutual))) }</td>
                    <td>$ { Intl.NumberFormat('de-DE').format(  Math.round(parseFloat(el.sis))) }</td>
                  </tr>
                )
              })
            }
            </tbody>
            
          :

          <TBodyLoading col='16' />
        }

      </table>
    </div>

  </>)
}
