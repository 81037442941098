import { useEffect, useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import Swal from "sweetalert2";
import db from "../../../backend/DBFiresbase";
import getIDDoc from "../../../helpers/getIDDoc";

export const BtnDeleteVenta = ( { proyecto, documento, coleccion='proyectos' } ) => {
  const [ idProyecto, setIdProyecto ] = useState("");

  const deleteData = async () => {
    const proyectoRef = doc(db, coleccion, idProyecto);
    const docRef = await  getDoc(proyectoRef);
    const ventaIndex = docRef.data().ventas.findIndex(el => el.numDocumento === documento );
    if(ventaIndex !== -1){
      const nuevosVentasArray = [...docRef.data().ventas];
      nuevosVentasArray.splice(ventaIndex, 1);
      updateDoc(proyectoRef, { ventas: nuevosVentasArray });
    }
  }

  const handleClick = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success m-2',
        cancelButton: 'btn btn-danger m-2'
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: '¿Desea eliminar el registro?',
      text: "Si elimina, ya no lo podrá recuperar",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {

        deleteData();

        swalWithBootstrapButtons.fire(
          '¡Eliminado!',
          'Registro fue eliminado con éxito',
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          '¡Cancelado!',
          'El registro se mantiene',
          'error'
        )
      }
    })
  }

  useEffect(() => {
    getIDDoc("proyectos", proyecto, setIdProyecto)
  }, [])

  return (<>
    <button className='btn btn-danger py-0' onClick={ handleClick }>
      <i className="bi bi-trash3"></i>
    </button>
  </>)
}
