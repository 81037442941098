

export const getNumFloatToString = ( valorPorcentual ) => {

  if( valorPorcentual === '' ) return 0;

  if(valorPorcentual < 1){
    let valorNumerico = ( parseFloat(valorPorcentual) * 100 ).toFixed(2);
    let decimalLimpio = parseFloat(valorNumerico).toString().replace(/(\.\d*?[1-9])0+$/g, "$1");
    return  decimalLimpio;
  } else {
    return valorPorcentual.toString()
  }
}
