import { useEffect, useState } from 'react';
import { SpinnerCard } from '../spinner/SpinnerCard';
import { getProductionProgressByAreaOfActiveProjects } from '../getProductionProgressByAreaOfActiveProjects';
import { getValuesActiveProjectSalesByArea } from '../getValuesActiveProjectSalesByArea';
import { getActiveProjectsNamesByArea } from '../getActiveProjectsNamesByArea';
import { getSalesProgressByAreaOfActiveProjects } from '../getSalesProgressByAreaOfActiveProjects';
import Chart from 'react-apexcharts';
import styles from './Graficos.module.css';




export  const GraficoBarraDobleAvancesXArea = ( { tipoArea } ) => {

    const [ projectNames, setProjectNames ] = useState([]);
    const [ projectSalesValues, setProjectSalesValues ] = useState([]);
    const [ salesProgress, setSalesProgress ] = useState([]);
    const [ productionProgress, setProductionProgress ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(true);


    const options = {
        colors: ['#008ffb','#0faa16'],
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false,
            },
        },
        legend:{
            fontWeight: 500,
            fontSize: '13px',
            position: 'top',
            horizontalAlign: 'center',
            itemMargin: {
                horizontal: 20,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
                columnWidth: '55%',
                endingShape: 'rounded',
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val + "%";
            },
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: projectNames,
            labels: {
                style: {
                    fontSize: "12px",
                    fontWeight: "600",
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: "12px",
                    fontWeight: "600",
                },
            },
        },
        fill: {
            opacity: 1
        },
        tooltip: {

            y: {
                formatter: function (value, {  seriesIndex, dataPointIndex, w } ) {
                    return  ("$ " +  new  Intl.NumberFormat('de-DE').format(projectSalesValues[dataPointIndex]));
                }
            },
        }
    }



    const series = [
        {
            name: '% Ventas',
            data: salesProgress,
        }, 
        {
            name: '% Producción',
            data: productionProgress,
        }, 
    ];

    useEffect(() => {
        getActiveProjectsNamesByArea( tipoArea, setProjectNames );        
    }, [ tipoArea ]);

    useEffect(() => {
        getProductionProgressByAreaOfActiveProjects( tipoArea, setProductionProgress );
    }, [ tipoArea ])

    useEffect(() => {
        getValuesActiveProjectSalesByArea( tipoArea, setProjectSalesValues );        
    }, [ tipoArea ]);

    useEffect(() => {
        getSalesProgressByAreaOfActiveProjects( tipoArea, setSalesProgress )
    }, [tipoArea]);

    

    setTimeout(() => {
        setIsLoading(false);        
    }, 500)



    return (<>
        {
            isLoading 
            
            ?

                <div className={ `w-100 h-100 d-flex justify-content-center align-items-center ${ styles.wrapperLoading }` } >
                    <SpinnerCard />
                </div>

            :

                <Chart 
                    options={ options }
                    series={ series }
                    type="bar"
                    height={ 450 }
                />
        }
    </>)
}