

export const FormLogin = ( { handleSubmit, handleChange, focusEmail, focusPass } ) => {


  return (
    <form
      onSubmit={handleSubmit}
      id="FormAuth"
      className="form_login p-4 d-flex flex-column gap-4 align-items-center justify-content-evenly"
    >

      <div className="col-md-12">
        <input
          onChange={handleChange}
          type="email"
          className={`form-control  ${focusEmail ? "foco" : ""}`} 
          id="AuthEmail"
          name="usuario"
        />
        <label className="form-label mt-1">EMAIL</label>
      </div>

      <div className="col-md-12">
        <input
          onChange={handleChange} 
          type="password"
          className={`form-control  ${focusPass ? "foco" : ""}`} 
          id="AuthPassword"
          name="password"
        />
        <label className="form-label mt-1">PASSWORD</label>
      </div>

      <div className='w-75 mx-auto'>
        <button className="btn btn-light w-100">INGRESAR</button>
      </div>                    
  </form>

  )
}
