import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getDataTrabajador } from "../../../helpers/getDataTrabajador";
import { InputFormRegister } from "../../pure/form/components";
import {  handleInputFloat, handleInputIntegerAtZero } from "../../../helpers/handles";
import { validateInput } from "../../../helpers/validaciones";
import { ToastContainer } from "react-toastify";
import { getNumFloatToString } from "../../../helpers/validaciones/getNumFloatToString";
import { showMessageError, showMessageExit } from "../../../helpers/ShowMessage";
import { doc, updateDoc } from "firebase/firestore";
import { BtnNavigateDetail } from "../../pure/form/BtnNavigateDetail";
import db from "../../../backend/DBFiresbase";
import styles from './DetailStyles.module.css';




export const ColaboradoresDetail = () => {

  const { id } = useParams();
  const navigate = useNavigate();

  const valoresIniciales = {
    sueldo_base: "",
    afp: "",
    bono_asistencia: "",
    bono_objetivo: "",
    bono_seguridad: "",
    cesantia: "",
    colacion: "",
    gratificacion_legal: "",
    movilizacion: "",
    prevision_salud: "",
    seguro_mutual: "",
    sis: "",
  }

  const [ valores, setValores ] = useState(valoresIniciales);
  const [ activeSueldoBase, setActiveSueldoBase ] = useState(true);
  const [ errorSueldoBase, setErrorSueldoBase]  = useState("");
  const [ activeGratificacionLegal, setActiveGratificacionLegal ] = useState(true);
  const [ errorGratificacionLegal, setErrorGratificacionLegal ] = useState("");
  const [ activeBonoAsistencia, setActiveBonoAsistencia ] = useState(true);
  const [ errorBonoAsistencia, setErrorBonoAsistencia ] = useState("");
  const [ activeBonoSeguridad, setActiveBonoSeguridad ] = useState(true);
  const [ errorBonoSeguridad, setErrorBonoSeguridad ] = useState("");
  const [ activeBonoObjetivo, setActiveBonoObjetivo ] = useState(true);
  const [ errorBonoObjetivo, setErrorBonoObjetivo ] = useState("");
  const [ activeMovilizacion, setActiveMovilizacion ] = useState(true);
  const [ errorMovilizacion, setErrorMovilizacion ] = useState("");
  const [ activeColacion, setActiveColacion ] = useState(true);
  const [ errorColacion, setErrorColacion ] = useState("");
  const [ activeAfp, setActiveAfp ] = useState(true);
  const [ errorAfp, setErrorAfp ] = useState("");
  const [ activeSalud, setActiveSalud ] = useState(true);
  const [ errorSalud, setErrorSalud ] = useState("");
  const [ activeCesantia, setActiveCesantia ] = useState(true);
  const [ errorCesantia, setErrorCesantia ] = useState("");
  const [ activeMutual, setActiveMutual ] = useState(true);
  const [ errorMutual, setErrorMutual ] = useState("");
  const [ activeSis, setActiveSis ] = useState(true);
  const [ errorSis, setErrorSis ] = useState("");


  const handleSubmit = (e) => {
    e.preventDefault();

    if( activeSueldoBase &&
        activeGratificacionLegal &&
        activeBonoAsistencia &&
        activeBonoSeguridad &&
        activeBonoObjetivo &&
        activeMovilizacion &&
        activeColacion &&
        activeAfp &&
        activeCesantia &&
        activeSalud &&
        activeMutual &&
        activeSis
      ){
        showMessageExit("¡Registro actualizado con éxito!");
        const registroActualizado = doc(db, "colaboradores", id);

        const updateProyecto = {
          ...valores,
          sueldo_base: parseInt(valores.sueldo_base),
          afp: parseFloat(valores.afp) ,
          bono_asistencia: parseInt(valores.bono_asistencia),
          bono_objetivo: parseInt(valores.bono_objetivo),
          bono_seguridad: parseInt(valores.bono_seguridad),
          cesantia: (valores.cesantia),
          colacion: parseInt(valores.colacion),
          gratificacion_legal: parseInt(valores.gratificacion_legal),
          movilizacion: parseInt(valores.movilizacion),
          prevision_salud: parseFloat(valores.prevision_salud),
          seguro_mutual: parseFloat(valores.seguro_mutual),
          sis: parseFloat(valores.sis),
        }

        updateDoc(registroActualizado, updateProyecto );

    } else {
      showMessageError("¡Error!, tiene una casilla sin llenar", "bottom-center");
    }
  
  }

  useEffect(() => {
    getDataTrabajador(id, setValores)
  }, [id])



  return (
    <form 
      className={`row g-4 mx-auto mt-2 rounded ${styles.Form}`}  
      onSubmit={ handleSubmit }
      autoComplete="off"
    >

      <p className="text-center mb-0" > { valores.nombre } </p>

      <InputFormRegister
        col='3'
        title='Sueldo Base'
        handleChange={ (e) => handleInputIntegerAtZero(e, setValores, valores ) }
        handleBlur={ (e) =>  validateInput(e, setErrorSueldoBase, setActiveSueldoBase, 'Ingresar sueldo base') }
        name='sueldo_base'
        value={ valores.sueldo_base }
        error={ errorSueldoBase }
      />

      <InputFormRegister
        col='3'
        title='Gratificación Legal'
        handleChange={ (e) => handleInputIntegerAtZero(e, setValores, valores ) }
        handleBlur={ (e) =>  validateInput(e, setErrorGratificacionLegal, setActiveGratificacionLegal, 'Ingresar gratificación legal') }
        name='gratificacion_legal'
        value={ valores.gratificacion_legal }
        error={ errorGratificacionLegal }
      />

      <InputFormRegister
        col='3'
        title='Bono de Seguridad'
        handleChange={ (e) => handleInputIntegerAtZero(e, setValores, valores ) }
        handleBlur={ (e) =>  validateInput(e, setErrorBonoSeguridad, setActiveBonoSeguridad, 'Ingresar bono de seguridad') }
        name='bono_seguridad'
        value={ valores.bono_seguridad }
        error={ errorBonoSeguridad }
      />

      <InputFormRegister
        col='3'
        title='Bono de Asist.'
        handleChange={ (e) => handleInputIntegerAtZero(e, setValores, valores ) }
        handleBlur={ (e) =>  validateInput(e, setErrorBonoAsistencia, setActiveBonoAsistencia, 'Ingresar bono de asistencia') }
        name='bono_asistencia'
        value={ valores.bono_asistencia }
        error={ errorBonoAsistencia }
      />

      <InputFormRegister
        col='3'
        title='Bono Objetivo.'
        handleChange={ (e) => handleInputIntegerAtZero(e, setValores, valores ) }
        handleBlur={ (e) =>  validateInput(e, setErrorBonoObjetivo, setActiveBonoObjetivo, 'Ingresar bono objetivo') }
        name='bono_objetivo'
        value={ valores.bono_objetivo }
        error={ errorBonoObjetivo }
      />

      <InputFormRegister
        col='3'
        title='Movilización'
        handleChange={ (e) => handleInputIntegerAtZero(e, setValores, valores ) }
        handleBlur={ (e) =>  validateInput(e, setErrorMovilizacion, setActiveMovilizacion, 'Ingresar movilización') }
        name='movilizacion'
        value={ valores.movilizacion }
        error={ errorMovilizacion }
      />

      <InputFormRegister
        col='3'
        title='Colación'
        handleChange={ (e) => handleInputIntegerAtZero(e, setValores, valores ) }
        handleBlur={ (e) =>  validateInput(e, setErrorColacion, setActiveColacion, 'Ingresar colación') }
        name='colacion'
        value={ valores.colacion }
        error={ errorColacion }
      />

      <InputFormRegister
        col='3'
        title='AFP'
        handleChange={ (e) => handleInputFloat(e, setValores, valores ) }
        handleBlur={ (e) =>  validateInput(e, setErrorAfp, setActiveAfp, 'Ingresar % AFP') }
        name='afp'
        value={ getNumFloatToString(valores.afp) }
        error={ errorAfp }
        firstSymbol=''
        secondSymbol="%"
      />

      <InputFormRegister
        col='3'
        title='Salud'
        handleChange={ (e) => handleInputFloat(e, setValores, valores ) }
        handleBlur={ (e) =>  validateInput(e, setErrorSalud, setActiveSalud, 'Ingresar % salud ') }
        name='prevision_salud'
        value={ getNumFloatToString(valores.prevision_salud) }
        error={ errorSalud }
        firstSymbol=''
        secondSymbol="%"
      />

      <InputFormRegister
        col='3'
        title='Seguro de Cesantía'
        handleChange={ (e) => handleInputFloat(e, setValores, valores ) }
        handleBlur={ (e) =>  validateInput(e, setErrorCesantia, setActiveCesantia, 'Ingresar % cesantía') }
        name='cesantia'
        value={ getNumFloatToString(valores.cesantia) }
        error={ errorCesantia }
        firstSymbol=''
        secondSymbol="%"
      />

      <InputFormRegister
        col='3'
        title='Seguro de Mutual'
        handleChange={ (e) => handleInputFloat(e, setValores, valores ) }
        handleBlur={ (e) =>  validateInput(e, setErrorMutual, setActiveMutual, 'Ingresar % mutual') }
        name='seguro_mutual'
        value={ getNumFloatToString(valores.seguro_mutual) }
        error={ errorMutual }
        firstSymbol=''
        secondSymbol="%"
      />

      <InputFormRegister
        col='3'
        title='SIS'
        handleChange={ (e) => handleInputFloat(e, setValores, valores ) }
        handleBlur={ (e) =>  validateInput(e, setErrorSis, setActiveSis, 'Ingresar % SIS') }
        name='sis'
        value={ getNumFloatToString(valores.sis) }
        error={ errorSis }
        firstSymbol=''
        secondSymbol="%"
      />

      <BtnNavigateDetail handleClick={ () => navigate(-1) }  />

      <ToastContainer />

    </form>
  )
}
