import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getGastos } from "../../../store/slices/thunks";
import { exportToExcel } from "../../../helpers/exportToExcel";
import { BtnNavigateRegisters } from "./BtnNavigateRegisters";
import { CardDataForm } from "./CardDataForm";
import { SelectAnio } from "./SelectAnio";
import { SelectMes } from "./SelectMes";
import { BuscadorDocs } from "./components/BuscadorDocs";
import { getListaProyectosAsc } from "../../../helpers/getListaProyectosAsc";
import getDataCollectionProveedores from "../../../helpers/getDataCollectionProveedores";
import moment from "moment";
import styles from './Form.module.css';




//Cantidad de items para paginación de gastos de Mnao de Obra
const ITEMS_NAVEGACION = 15;

export const TablaReporteGastos = (  ) => {

  //todo  Obteniendo  todos los gatos de los proyectos con Redux
  const dispacth= useDispatch();
  const { gastos= [] } = useSelector( state => state.gastos );


  //todo  Clonando los gastos traídos desde Redux *****
  const [ cloneGastos, setCloneGastos ] = useState(gastos);
  const [ conceptoGasto, setConceptoGasto ] = useState("");
  const [ proyecto, setProyecto ] = useState("");
  const [ proveedor, setProveedor ] = useState("");
  const [ factura, setFactura ] = useState("");
  const [ anio, setAnio ] = useState("");
  const [ mes, setMes ] = useState("");
  const [ tipoDocumento, setTipoDocumento ]  = useState("");
  const [ precioGastoTotal, setPrecioGastoTotal ] = useState(0);
  const [ gastosTotalEBG, setGastosTotalEBG ] = useState(0);
  const [ loadingCardDataForm, setLoadingCardDataForm ] = useState(false);
  const [ loadingTable, setLoadingTable ] = useState(false);

  const [proyectos, setProyectos] = useState([]);
  const [proveedores, setProveedores] = useState([]);

  //? Mostrando los datos en el formulario
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  //Capturando los valores de los inputs del form
  const handleChange = (e, setState)=>{
    const { value } = e.target;
    setState(value);
  }

//todo Generando los gastos filtrados
const gastosFiltrados = cloneGastos.filter(el => {

  return (
    (!proyecto || el.proyecto === proyecto) &&
    (!conceptoGasto || el.concepto === conceptoGasto) &&
    (!proveedor || el.proveedor === proveedor) &&
    (!anio || el.anioGasto === anio) &&
    (!mes || el.mesGasto === mes) &&
    (!factura || el.numDocumento.includes(factura)) &&
    (!tipoDocumento || el.tipoDocumento === tipoDocumento)
  );
})

    //todo Funciones de Navegación
    const nextHandler = () => {
      const totalElementos = gastosFiltrados.length;
  
      const nextPage = currentPage + 1;
      const firstIndex = nextPage * ITEMS_NAVEGACION;
  
      if(firstIndex >= totalElementos) return;
  
      setItems([...gastosFiltrados].splice(firstIndex, ITEMS_NAVEGACION));
      setCurrentPage(nextPage);
    }
  
    const prevHandler = () => {
      const prevPage = currentPage - 1;
      
      if(prevPage < 0) return;
  
      const firstIndex = prevPage * ITEMS_NAVEGACION;
  
      setItems([...gastosFiltrados].splice(firstIndex, ITEMS_NAVEGACION));
      setCurrentPage(prevPage);
    }

  useEffect(() => {
    dispacth( getGastos() );
  }, [])


  useEffect(() => {
      const gastosFiltrados = gastos.filter(objeto => objeto.concepto !== "Mano de Obra");
      const gastosOrdenados = gastosFiltrados.sort((a, b) => a.fechaGasto.localeCompare(b.fechaGasto));
      gastosOrdenados.sort((a,b) => new Date(a.fechaGasto).getTime() - new Date(b.fechaGasto).getTime());
      setCloneGastos(gastosOrdenados);
  }, [gastos])

  useEffect(() => {
    getListaProyectosAsc(setProyectos);
  }, []);

  useEffect(() => {
    getDataCollectionProveedores(setProveedores);
  }, []);

  useEffect(() => {
    setPrecioGastoTotal(gastosFiltrados
                        .filter(el => el.proyecto !== 'EBG Casa Matriz')
                        .reduce((acc, el) => acc + parseInt(el.valor), 0 )
    );
  }, [gastosFiltrados])


  useEffect(() => {
    setGastosTotalEBG(gastosFiltrados
                        .filter(el => el.proyecto === 'EBG Casa Matriz')
                        .reduce((acc, el) => acc + parseInt(el.valor), 0 ));
    setTimeout(() => {
      setLoadingCardDataForm(true);
    }, 1200)
  }, [gastosFiltrados])

  //? Separando los datos traídos de Firebase
  useEffect(() => {
    setItems([...gastosFiltrados].splice(0, ITEMS_NAVEGACION))
    setCurrentPage(0);
    setTimeout(() => {
      setLoadingTable(true);
    }, 1000);
},[ proyecto, conceptoGasto, proveedor, anio, mes, factura, cloneGastos, tipoDocumento])



//todo FIN  Obteniendo  todos los gatos de los proyectos con Redux ***

  return (<>
    <div className="container d-flex justify-content-evenly mt-3">
      <CardDataForm 
        title='Total Gastos'
        data={ precioGastoTotal }
        loading={ loadingCardDataForm }
      />

      <CardDataForm 
        title='Total Gastos EBG Casa Matriz'
        data={ gastosTotalEBG }
        loading={ loadingCardDataForm }
      />
    </div>

    <form className=' mx-auto d-flex justify-content-evenly mt-5 mb-4 align-items-center'>

      <div className=" form-check d-flex align-items-center" >
        <BuscadorDocs 
          doc={ factura }
          setDoc={ setFactura }
        />
      </div>

      <div className='d-flex align-items-center'>
        <SelectAnio 
          className={ `form-select ${ styles.selectForm } text-center pe-0 py-1` }
          eventChange={ (e) => handleChange(e, setAnio) }
          anio= { anio }
        />
      </div>

      <div className=' d-flex align-items-center gap-1'>
        <SelectMes 
          handleChange= { (e) => handleChange(e, setMes) }
          className={ `form-select ${ styles.selectForm } pe-0 py-1` }
        />
      </div>

      <div className="d-flex justify-content-center">
        <span className={`me-2 fw-bold mb-0 ${styles.titleGasto}`} >Descargar:</span>
        <button
          type="button"
          className={ `btn py-0 ${styles.btnPrimary}` }
          onClick={ () => exportToExcel(gastosFiltrados, `reporte_gastos_${moment().format('DD-MM-YYYY HH:mm:ss')}`) }
        >
          <i className="bi bi-file-earmark-spreadsheet me-2"></i>
          Excel
        </button>
      </div>

    </form>

    <BtnNavigateRegisters 
      prevHandler={ prevHandler }
      currentPage={ currentPage }
      nextHandler={ nextHandler }
    />

    <div className="table-responsive">
      <table className={ `table text-center table-striped rounded-top mx-auto px-2`}> 
        <thead className="rounded-top">
          <tr className={ `${ styles.tableHead }` } >
            <th>#</th>
            <th>Fecha</th>
            <th>Gasto</th>
            <th>
              <select
                onChange={ (e) => handleChange(e, setConceptoGasto) }
                className={ `form-select ${ styles.selectTable } pe-0 py-1` }
                name="concepto"
                value={ conceptoGasto }
              >
                <option value="">Concepto</option>
                <option value="Arriendos">Arriendos</option>
                <option value="Contratistas">Contratistas</option>
                <option value="Combustible">Combustible</option>
                <option value="EBG Casa Matriz">EBG Casa Matriz</option>
                <option value="EPPs">EPPs</option>
                <option value="Equipos">Equipos</option>
                <option value="Exámenes médicos">Exámenes Médicos</option>
                <option value="Finiquito">Finiquito</option>
                <option value="Fletes">Fletes</option>
                <option value="Herramientas">Herramientas</option>
                <option value="Leasing">Leasing</option>
                <option value="Mantenimientos">Mantenimientos</option>
                <option value="Materiales">Materiales</option>
                <option value="Multas">Multas</option>
                <option value="Otros">Otros</option>
                <option value="Patentes">Patentes</option>
                <option value="Seguros">Seguros y/o garantías</option>
                <option value="Servicios">Servicios</option>
                <option value="Utiles de aseo">Útiles de aseo</option>
                <option value="Utiles de escritorio">Útiles de escritorio</option>
                <option value="Viáticos">Viáticos</option>
              </select>
            </th>

            <th >
              <select
                onChange={(e) => handleChange(e, setProyecto)}
                className={ `form-select ${ styles.selectTable } pe-0 py-1 mx-auto` } 
                name="proyecto"
                value={ proyecto }>
                <option value="">Proyecto</option>
                { proyectos.map((el, index) => {
                    return(
                    <option value={ el } key={ index + el}> { el } </option>
                    )
                })}
              </select>
            </th>

            <th  className={` w-xl-100 w-25`}>
              <select
                onChange={(e) => handleChange(e, setProveedor)}
                className={ `form-select ${ styles.selectTable } pe-0 py-1 mx-auto ` } 
                name="proveedor"
                value={ proveedor }>
                <option value="">Proveedor</option>
                { proveedores.map((el) => {
                    return(
                    <option value={el.razonSocial} key={el.razonSocial}> {el.razonSocial.toUpperCase()} </option>
                    )
                })}
              </select>
            </th>
            <th>
              <select 
                className={ `form-select ${ styles.selectTable } pe-0 py-1 mx-auto `}
                onChange={ (e) => handleChange(e, setTipoDocumento) }
                name="tipoDocumento"
                value={ tipoDocumento }
              >
                <option value="">Tipo Doc</option>
                <option value="Factura">Factura</option>
                <option value="Boleta">Boleta</option>
                <option value="Recibo">Recibo</option>
                <option value="Comprobante">Comprobante</option>
                <option value="Nota de Crédito">Nota de Crédito</option>
                <option value="Nota de Débito">Nota de Débito</option>
                <option value="Guía de Despacho">Guía de Despacho</option>
                <option value="Factura exenta">Factura Exenta de IVA</option>
                <option value="Costo de Oficina">Costo de Oficina</option>
              </select>
            </th>
            <th>N° Documento</th>
          </tr>
        </thead>

        {
          loadingTable ? 
          <tbody className={ `table-group-divider ${styles.tableBody}` }>
          { items.map((el,index) => {

            return(
              <tr key= { index }  className={ `table-secondary ` }  >
                  <td> { index + 1  } </td>
                  <td> { moment(el.fechaGasto).format('DD-MM-YYYY') }</td>
                  <td>$ { new Intl.NumberFormat('de-DE').format(el.valor) }</td>
                  <td> { el.concepto } </td>
                  <td> { el.proyecto } </td>
                  <td> { el.proveedor } </td>
                  <td> { el.tipoDocumento } </td>
                  <td> { el.numDocumento } </td>
              </tr>
              )
          })}
          </tbody>

          :

          <tbody className={ `table-group-divider ${styles.tableBody}` }>
            <tr   className={ `table-secondary ` }  >
              <td colSpan="8" > 
                <div className={ `spinner-border ${styles.tableSpinner}` } role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
        }
      </table>
    </div>
      
    </>)
}
