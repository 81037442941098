import { createSlice } from "@reduxjs/toolkit";


export const gastosSlice = createSlice({
    name: 'gasto',
    initialState: {
        gastos: [],
        isLoading: false,
        proyectos:[],
    },
    reducers: {
        startLoadingGastos: (state) =>{
            state.isLoading = true;
        },
        setGastos: ( state, action ) => {
            state.isLoading = false;
            state.gastos = action.payload.gastos;
        },
        setProyectos: ( state, action) => {
            state.proyectos = action.payload.proyectos;
        }
    }
})

export const { startLoadingGastos, setGastos, setProyectos } = gastosSlice.actions;
