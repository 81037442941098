import { query, collection, onSnapshot, where} from 'firebase/firestore';
import { getSumFilteredExpense } from './getSumFilteredExpense';
import db from '../backend/DBFiresbase';

const  getSumaGastosXArea = (coleccion, clave, valor, estado) => {
    onSnapshot(query(collection(db,coleccion), where(clave, "==", valor)), (querySnapshot) => {
        const docs = [];
        let valoresGastos = [];

        querySnapshot.forEach((doc) => {
            docs.push({...doc.data(), id:doc.id});
        });

        const gastosGenerales = docs.map(el => el.gastos);

        const gastosDiferentesNotaCredito = gastosGenerales?.map(gasto => gasto.filter(el => el.tipoDocumento === 'Nota de Crédito').length
                                                ? gasto.filter(el => el.tipoDocumento !== 'Nota de Crédito')
                                                : gasto
                                            );

        gastosDiferentesNotaCredito.forEach( (array) => {

            if(array.length){
                if(array.length === 1){
                    array.map(gasto => valoresGastos.push( parseInt(gasto.valor)))
                    
                }
                
                if(array.length !== 1){
                    valoresGastos.push(array.map(gasto => parseInt(gasto.valor)).reduce((a,b) => a+b));
                }
            }
        })

        const totalGastos = valoresGastos.length >= 1 ? valoresGastos.reduce((a,b) => a+b) : 0;

        const totalNotaCredito = getSumFilteredExpense(gastosGenerales);

        estado( totalGastos - totalNotaCredito );
    });

}

export default  getSumaGastosXArea;
