import { collection, onSnapshot, query, where } from "firebase/firestore";
import db from "../backend/DBFiresbase";

export const getRUTCliente = ( nombre, estado ) => {
  const q = query(collection(db, 'clientes' ), where("razonSocial", "==", nombre));

    onSnapshot(q, (querySnapshot) => {
        const docs = [];

        querySnapshot.forEach((doc) => {
            docs.push(doc.data());
        });

        let rut = docs[0].rut ? docs[0].rut : 0;
        estado(rut);
    });
}
