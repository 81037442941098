import { useState, useEffect } from 'react';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { getGastos } from '../../../store/slices/thunks';
import { showMessageError, showMessageExit } from '../../../helpers/ShowMessage';
import { getFechaPago } from '../../../helpers/getFechaPago';
import { ToastContainer } from 'react-toastify';
import { getListaProyectosAsc } from '../../../helpers/getListaProyectosAsc';
import { formatNumDoc } from '../../../helpers/formatNumDoc';
import { InputFormRegister, SelectTipoDoc, SelectConcepto, BtnsFormRegister } from './components';
import { validateCostDates } from '../../../helpers/validaciones/validateCostDates';
import { useAuth } from '../../../context/authContext';
import moment from "moment";
import db from '../../../backend/DBFiresbase';
import getIDDoc from '../../../helpers/getIDDoc.js';
import getDataCollectionProveedores from '../../../helpers/getDataCollectionProveedores';
import styles from './Form.module.css';


export default function FormRegistroDeGastos() {

  const { user } = useAuth();

  //todo  Obteniendo  todos los gatos de los proyectos con Redux
  const dispacth= useDispatch();
  const { gastos= [] } = useSelector( state => state.gastos )

  useEffect(() => {
      dispacth( getGastos() );
  }, []);

  const valoresIniciales = {
    tipoDocumento: "",
    numDocumento:"",
    fechaGasto: "",
    valor: "",
    proveedor: "",
    concepto: "",
    proyecto: "",
    formaPago: "",
    fechaRegistro: "",
    mesGasto:"",
    anioGasto:"",
    fechaPago:"",
  }

  const [ valores, setValores ] = useState(valoresIniciales);

  //fecha de Registro
  const [ fechaRegistro, setFechaRegistro ] = useState("");

  //Almacena el nombre de proyecto
  const [ nombreProyecto, setNombreProyecto ] = useState("");

  //Almacena el ID del proyecto
  const [ id, setId ] = useState("");

  //Almacena fecha de pago para forma de pago 'CREDITO'
  const [ fechaPago, setFechaPago ] = useState('');

  // Obteniendo  datos de Firebase (proveedores y proyectos)
  const [ proveedores, setProveedores ] = useState([]);
  const [ proyectos, setProyectos ] = useState([]);
  const [ activeNumDoc, setActiveNumDoc ] = useState(false);
  const [ errorNumDoc, setErrorNumDoc ] = useState('');
  const [ activeValorGasto, setActiveValorGasto ] = useState(false);
  const [ errorValorGasto, setErrorValorGasto ] = useState('');
  const [ errorTipoDoc, setErrorTipoDoc ] = useState('');
  const [ activeTipoDoc, setActiveTipoDoc ] = useState(false);
  const [ gastoDuplicado, setGastoDuplicado ] = useState('');
  const [ errorFecha, setErrorFecha ] = useState('');
  const [ activeFecha, setActiveFecha ] = useState(false);
  const [ errorProveedor, setErrorProveedor ] = useState('');
  const [ activeProveedor, setActiveProveedor ] = useState(false);
  const [ activeConcepto, setActiveConcepto ] = useState(false);
  const [ errorConcepto, setErrorConcepto ] = useState('');
  const [ activeProyecto, setActiveProyecto ] = useState(false);
  const [ errorProyecto, setErrorProyecto ] = useState('');
  const [ errorFormaPago, setErrorFormaPago ] = useState('');
  const [ activeFormaPago, setActiveFormaPago ] = useState(false);


//todo **** F U N C I O N E S  ****
// ?  *** Capturar los valores de los inputs del Form  ***
  const handleInput = (e) => {
    const {name, value} = e.target;
    setValores({...valores, [name]:value});
  }

  const handleNumDoc = (e) => {
    const {name, value} = e.target;
    const formatValue = formatNumDoc(value);
    setValores({ ...valores, [name]:formatValue });
  }

  //Validar Numero de documento
  const validateNumDoc = (e) => {
    const value = e.target.value;

    if(value === "" || valores.numDocumento === ''){
      setErrorNumDoc('Ingresar N° de Documento');
      setActiveNumDoc(false);
      return;
    }

    if(value !== ""  && gastoDuplicado.length >= 1){
      setErrorNumDoc('El documento ya está registrado');
      setActiveNumDoc(false);
      return;
    }

    setActiveNumDoc(true);
    setErrorNumDoc('');
  }


  const validateInput = (e, setError, setActive, mensaje) => {
    const value = e.target.value;

    if(value !== ""){
      setError('');
      setActive(true);
      return;
    }

    setError(mensaje);
    setActive(false);
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    //Se capturan el mes y año de la fecha de la venta
    const mes = moment(valores.fechaGasto).format("MMMM");
    const anio = moment(valores.fechaGasto).format("YYYY");

    if( activeTipoDoc && 
        activeNumDoc && 
        activeFecha && 
        activeValorGasto && 
        activeProveedor && 
        activeConcepto &&
        activeProyecto &&
        activeFormaPago){

      //Se envían los datos de la venta al proyecto correspondiente
      const nuevoGasto = doc(db, "proyectos", id);

      showMessageExit("Registro exitoso");
      updateDoc(nuevoGasto, {gastos: arrayUnion(
        {...valores, 
          fechaRegistro: fechaRegistro, 
          anioGasto:anio, 
          mesGasto:mes, 
          proyecto:nombreProyecto,
          fechaPago: fechaPago })}
      );

      //Se limpian los valores del formulario
      cleanInputs();
      setNombreProyecto('');

    } else {
      !activeTipoDoc && setErrorTipoDoc('Seleccione un Tipo de Documento');
      !activeNumDoc && setErrorNumDoc('Ingresar N° de Documento');
      !activeFecha && setErrorFecha('Ingresar fecha correcta');
      !activeValorGasto && setErrorValorGasto('Ingresar valor del gasto');
      !activeProveedor && setErrorProveedor('Seleccione un Proveedor');
      !activeConcepto && setErrorConcepto('Seleccione un Concepto');
      !activeProyecto && setErrorProyecto('Seleccione un Proyecto');
      !activeFormaPago && setErrorFormaPago('Seleccione una Forma de Pago');
      showMessageError("¡Error!, tiene una casilla sin llenar", "bottom-center");
    }
  }


  useEffect(() => {
    setGastoDuplicado(gastos.filter(gasto => gasto.numDocumento === valores.numDocumento && gasto.proveedor === valores.proveedor))
  },[ gastos,  valores.numDocumento, valores.proveedor ])


  // Mostrando los datos en la interfaz
  useEffect(() => {

    // Obteniendo  datos de Firebase (proveedores y proyectos)
    getDataCollectionProveedores(setProveedores);
    getListaProyectosAsc(setProyectos);

    // Trayendo el ID del proyecto seleccionado
    const obtenerID = async () => {
      nombreProyecto !== "" && getIDDoc("proyectos", nombreProyecto, setId);
  }
  obtenerID();
}, [nombreProyecto]);

  useEffect(() => {
    if(valores.proveedor !== '' && valores.fechaGasto !== '' && valores.formaPago !== ''){
      const proveedor = proveedores.filter(el => el.razonSocial === valores.proveedor);
      const arrayDias = proveedor.map(el => el.diasPago !== '' ? parseInt(el.diasPago) : 0);
      const diasCredito = valores.formaPago === 'CREDITO' ? arrayDias[0] : 0;

      getFechaPago(valores.fechaGasto, diasCredito, setFechaPago);
    }
  },[proveedores, valores.proveedor, valores.fechaGasto, valores.formaPago]);


  //Limpia los inputs
  const cleanInputs = () => {
    setErrorNumDoc('');
    setErrorTipoDoc('');
    setErrorValorGasto('');
    setErrorFecha('');
    setErrorProveedor('');
    setErrorConcepto('');
    setErrorProyecto('');
    setErrorFormaPago('');
    setNombreProyecto('');
    setActiveNumDoc(false);
    setActiveValorGasto(false);
    setActiveTipoDoc(false);
    setActiveFecha(false);
    setActiveProveedor(false);
    setActiveConcepto(false);
    setActiveProyecto(false);
    setActiveFormaPago(false);
    setValores(valoresIniciales);
  }


  //Registrar decha de registro
  const handleClick = () => {
    setFechaRegistro(moment().format('YYYY-MM-DD HH:mm:ss'));
  }

    return (<>

      <form 
        className="row g-4"
        onSubmit={ handleSubmit }
        autoComplete="off"
      >

        <SelectTipoDoc 
          title='Tipo de Documento:'
          handleChange={ handleInput }
          handleBlur={ (e) => validateInput(e, setErrorTipoDoc, setActiveTipoDoc, 'Seleccione un Tipo de Documento')}
          name="tipoDocumento"
          value={ valores.tipoDocumento }
          error={ errorTipoDoc }
        />

        <InputFormRegister 
          title='N° de Documento'
          handleChange={ handleNumDoc }
          handleBlur={ validateNumDoc }
          name="numDocumento"
          value={ valores.numDocumento }
          error={ errorNumDoc }
          placeholder = 'Ejm: 67465'
          firstSymbol=''
        />


        <div className="col-md-4">
          <label className={` form-label ${ styles.labelForm } `}>Fecha del Documento:</label>
          <input
            onChange={ handleInput }
            onBlur={ (e) => validateCostDates(e, user.perfil, setErrorFecha, setActiveFecha) }
            type="date" 
            className={`form-control ${ styles.inputForm } ${ errorFecha === '' ? "" : styles.errorInput }`}  
            name="fechaGasto"
            value={ valores.fechaGasto }
          />
          <span className={ styles.error }>
            { errorFecha !== "" ? errorFecha : "" }
          </span>
        </div>

        <InputFormRegister 
          title='Valor neto del gasto:'
          handleChange={ handleNumDoc }
          handleBlur={ (e) =>  validateInput(e, setErrorValorGasto, setActiveValorGasto, 'Ingresar valor del gasto') }
          name="valor"
          value={ valores.valor }
          error={ errorValorGasto }
        />


        <div className="col-md-4">
          <label className={ `form-label ${ styles.labelForm }` }>Proveedor:</label>
          <select
            onChange={ handleInput }
            onBlur={ (e) =>  {validateInput(e, setErrorProveedor, setActiveProveedor, 'Seleccione un Proveedor'); validateNumDoc(e)} }
            className={`form-select ${ styles.inputForm } ${ errorProveedor === '' ? "" : styles.errorInput }`}  
            name="proveedor"
            value={valores.proveedor}>
              <option  value="">Seleccione</option>
                { proveedores.map((el, index) => {
                  return(
                    <option value={el.razonSocial} key={index}> {el.razonSocial} </option>
                  )
                })}
          </select>
          <span className={ styles.error }>
            { errorProveedor !== "" ? errorProveedor : "" }
          </span>
        </div>


        <SelectConcepto 
          title='Concepto:'
          handleChange={ handleInput }
          handleBlur={  (e) => validateInput(e, setErrorConcepto, setActiveConcepto, 'Seleccione un Concepto')} 
          name="concepto"
          value={ valores.concepto }
          error={ errorConcepto }
        />


        <div className="col-md-4">
          <label className={` form-label ${ styles.labelForm } `}>Proyecto:</label>
          <select 
            className={`form-select ${ styles.inputForm } ${ errorProyecto === '' ? "" : styles.errorInput }`} 
            onChange={(e) => setNombreProyecto(e.target.value)} 
            onBlur={ (e) => validateInput(e, setErrorProyecto, setActiveProyecto, 'Seleccione un Proyecto') }
            name="proyecto"
            value={ nombreProyecto }>
              <option value="">Seleccione</option>
              { proyectos.map((el, index) => {
                  return(
                    <option value={ el } key={index}> { el } </option>
                  )
                })}
          </select>
          <span className={ styles.error }>
            { errorProyecto !== "" ? errorProyecto : "" }
          </span>
        </div>

        
        <div className="col-md-4">
          <label className={` form-label ${ styles.labelForm } `}>Forma de pago:</label>
          <select 
            className={`form-select ${ styles.inputForm } ${ errorFormaPago === '' ? "" : styles.errorInput }`} 
            onChange={ handleInput }
            onBlur={ (e) => validateInput(e, setErrorFormaPago, setActiveFormaPago, 'Seleccione una Forma de Pago') } 
            name="formaPago"
            value={valores.formaPago}
          >
            <option value="">Seleccione</option>
            <option value="CONTADO">CONTADO</option>
            <option value="CREDITO">CREDITO</option>
          </select>
          <span className={ styles.error }>
            { errorFormaPago !== "" ? errorFormaPago : "" }
          </span>
        </div>


        {
          (activeFormaPago && activeProveedor && activeFecha  !== '') &&

          <div className="col-md-4">
            <label className={` form-label ${ styles.labelForm } `}>Fecha de pago:</label>
            <input
              className={ `form-control ${ styles.inputForm }` }
              type="date" 
              name="fechaPago"
              value={ fechaPago }
              readOnly
            />
          </div>
        }

        <BtnsFormRegister 
          cleanInputs={ cleanInputs }
          handleClick={ handleClick }
        />

        <ToastContainer/>
      </form>
    </>)
}
