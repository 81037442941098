import { query, collection, onSnapshot, where } from 'firebase/firestore';
import db from "../backend/DBFiresbase";

export const getListaGastoMO = (proyecto, id, estado) => {
  onSnapshot(query(collection(db,"proyectos"), where("nombre", "==", proyecto)),
  (querySnapshot) => {
    const docs = [];

    
    querySnapshot.forEach((doc) => {
        const gastoIndex = doc.data().gastos.findIndex(el => el.fechaRegistro === id );
        docs.push({...doc.data().gastos[gastoIndex]});
    });

    estado(docs[0]);
  });
}
