import { useState } from "react";
import { collection, doc, setDoc } from "firebase/firestore";
import { InputFormRegister } from "./components/InputFormRegister";
import { showMessageError, showMessageExit } from "../../../helpers/ShowMessage";
import { handleInputIntegerAtZero, handleInput, handleInputFloat  } from "../../../helpers/handles";
import { ToastContainer } from "react-toastify";
import { setRegisterDate } from "../../../helpers/setRegisterDate";
import { validateInput, validateName  } from "../../../helpers/validaciones";
import { BtnsFormRegister } from "./components";
import db from "../../../backend/DBFiresbase";



export const FormRegistroColaboradores = () => {

  const valoresIniciales = {
    nombre: "",
    afp: "",
    bono_asistencia: "",
    bono_objetivo: 0,
    bono_seguridad: "",
    cesantia: "",
    colacion: "",
    gratificacion_legal: "",
    movilizacion: "",
    prevision_salud: "",
    seguro_mutual: "",
    sis: "",
    sueldo_base: "",
  }

  const [ valores, setValores ] = useState(valoresIniciales);
  const [ fechaRegistro, setFechaRegistro ] = useState("");
  const [ activeNombre, setActiveNombre ] = useState(false);
  const [ errorNombre, setErrorNombre ] = useState("");
  const [ activeSueldoBase, setActiveSueldoBase ] = useState(false);
  const [ errorSueldoBase, setErrorSueldoBase]  = useState("");
  const [ activeGratificacionLegal, setActiveGratificacionLegal ] = useState(false);
  const [ errorGratificacionLegal, setErrorGratificacionLegal ] = useState("");
  const [ activeBonoAsistencia, setActiveBonoAsistencia ] = useState(false);
  const [ errorBonoAsistencia, setErrorBonoAsistencia ] = useState("");
  const [ activeBonoSeguridad, setActiveBonoSeguridad ] = useState(false);
  const [ errorBonoSeguridad, setErrorBonoSeguridad ] = useState("");
  const [ activeBonoObjetivo, setActiveBonoObjetivo ] = useState(true);
  const [ errorBonoObjetivo, setErrorBonoObjetivo ] = useState("");
  const [ activeMovilizacion, setActiveMovilizacion ] = useState(false);
  const [ errorMovilizacion, setErrorMovilizacion ] = useState("");
  const [ activeColacion, setActiveColacion ] = useState(false);
  const [ errorColacion, setErrorColacion ] = useState("");
  const [ activeAfp, setActiveAfp ] = useState(false);
  const [ errorAfp, setErrorAfp ] = useState("");
  const [ activeSalud, setActiveSalud ] = useState(true);
  const [ errorSalud, setErrorSalud ] = useState("");
  const [ activeCesantia, setActiveCesantia ] = useState(true);
  const [ errorCesantia, setErrorCesantia ] = useState("");
  const [ activeMutual, setActiveMutual ] = useState(true);
  const [ errorMutual, setErrorMutual ] = useState("");
  const [ activeSis, setActiveSis ] = useState(true);
  const [ errorSis, setErrorSis ] = useState("");



  const cleanInputs = () => {
    setValores(valoresIniciales);
    setActiveNombre(false);
    setActiveSueldoBase(false);
    setActiveGratificacionLegal(false);
    setActiveBonoAsistencia(false);
    setActiveBonoSeguridad(false);
    setActiveBonoObjetivo(true);
    setActiveMovilizacion(false);
    setActiveColacion(false);
    setActiveAfp(true);
    setActiveSalud(true);
    setActiveCesantia(true);
    setActiveMutual(true);
    setActiveSis(true);
    setErrorNombre('');
    setErrorSueldoBase('');
    setErrorGratificacionLegal('');
    setErrorBonoAsistencia('');
    setErrorBonoSeguridad('');
    setErrorBonoObjetivo('');
    setErrorMovilizacion('');
    setErrorColacion('');
    setErrorAfp('');
    setErrorSalud('');
    setErrorCesantia('');
    setErrorMutual('');
    setErrorSis('');
  }


  const handleSubmit = (e) => {
    e.preventDefault();

    if( activeNombre &&
        activeSueldoBase &&
        activeGratificacionLegal &&
        activeBonoAsistencia &&
        activeBonoSeguridad &&
        activeBonoObjetivo &&
        activeMovilizacion &&
        activeColacion &&
        activeAfp &&
        activeSalud &&
        activeCesantia &&
        activeMutual &&
        activeSis
      ) {
        showMessageExit("Registro exitoso");
      
        const nuevoColaborador = doc(collection(db, "colaboradores"));
        setDoc( nuevoColaborador, {
          ...valores,
          fechaRegistro: fechaRegistro,
          sueldo_base: parseInt(valores.sueldo_base),
          gratificacion_legal: parseInt(valores.gratificacion_legal),
          bono_asistencia: parseInt(valores.bono_asistencia),
          bono_seguridad: parseInt(valores.bono_seguridad),
          bono_objetivo: parseInt(valores.bono_objetivo),
          movilizacion: parseInt(valores.movilizacion),
          colacion: parseInt(valores.colacion),
          afp: parseFloat((parseFloat(valores.afp) / 100).toFixed(4)),
          prevision_salud: parseFloat((parseFloat(valores.prevision_salud) / 100).toFixed(4)),
          seguro_mutual: parseFloat((parseFloat(valores.seguro_mutual) / 100).toFixed(4)),
          sis: parseFloat((parseFloat(valores.sis) / 100).toFixed(4)),
          cesantia: parseFloat((parseFloat(valores.cesantia) / 100).toFixed(4)),
        } );
        cleanInputs();

    } else {
        !activeNombre && setErrorNombre('Ingrese nombre y apellido');
        !activeSueldoBase && setErrorSueldoBase('Ingrese sueldo base');
        !activeGratificacionLegal && setErrorGratificacionLegal('Ingrese gratificación')
        !activeBonoAsistencia && setErrorBonoAsistencia('Ingrese bono de asistencia');
        !activeBonoSeguridad && setErrorBonoSeguridad('Ingrese bono de seguridad');
        !activeBonoObjetivo && setErrorBonoObjetivo('Ingrese bono objetivo');
        !activeMovilizacion && setErrorMovilizacion('Ingrese movilización');
        !activeColacion && setErrorColacion('Ingrese colación');
        !activeAfp && setErrorAfp('Ingrese % AFP');
        !activeSalud && setErrorSalud('Ingrese % salud');
        !activeCesantia && setErrorCesantia('Ingrese % cesantía');
        !activeMutual && setErrorMutual('Ingrese % mutual');
        !activeSis && setErrorSis('Ingrese % SIS');
        showMessageError("¡Error!, tiene una casilla sin llenar", "bottom-center");
    }
  }

  return (<>
    <form 
      className="row g-4" 
      onSubmit={ handleSubmit }
      autoComplete="off"
    >

      <InputFormRegister 
        title='Nombre y Apellido'
        handleChange={ (e) => handleInput(e, setValores, valores) }
        handleBlur={ (e) => validateName(e, setActiveNombre, setErrorNombre) }
        name='nombre'
        value={ valores.nombre.toUpperCase() }
        error={ errorNombre }
        placeholder="JUAN PÉREZ"
        firstSymbol=''
      />

      <InputFormRegister 
        title='Sueldo Base'
        handleChange={ (e) => handleInputIntegerAtZero(e, setValores, valores ) }
        handleBlur={ (e) =>  validateInput(e, setErrorSueldoBase, setActiveSueldoBase, 'Ingresar sueldo base') }
        name='sueldo_base'
        value={ valores.sueldo_base }
        error={ errorSueldoBase }
      />

      <InputFormRegister 
        title='Gratificación Legal'
        handleChange={ (e) => handleInputIntegerAtZero(e, setValores, valores ) }
        handleBlur={ (e) =>  validateInput(e, setErrorGratificacionLegal, setActiveGratificacionLegal, 'Ingresar gratificación legal') }
        name='gratificacion_legal'
        value={ valores.gratificacion_legal }
        error={ errorGratificacionLegal }
      />

      <InputFormRegister 
        title='Bono de Seguridad'
        handleChange={ (e) => handleInputIntegerAtZero(e, setValores, valores ) }
        handleBlur={ (e) =>  validateInput(e, setErrorBonoSeguridad, setActiveBonoSeguridad, 'Ingresar bono de seguridad') }
        name='bono_seguridad'
        value={ valores.bono_seguridad }
        error={ errorBonoSeguridad }
      />

      <InputFormRegister 
        title='Bono de Asistencia y Respons.'
        handleChange={ (e) => handleInputIntegerAtZero(e, setValores, valores ) }
        handleBlur={ (e) =>  validateInput(e, setErrorBonoAsistencia, setActiveBonoAsistencia, 'Ingresar bono de asistencia') }
        name='bono_asistencia'
        value={ valores.bono_asistencia }
        error={ errorBonoAsistencia }
      />

      <InputFormRegister 
        title='Bono Objetivo.'
        handleChange={ (e) => handleInputIntegerAtZero(e, setValores, valores ) }
        handleBlur={ (e) =>  validateInput(e, setErrorBonoObjetivo, setActiveBonoObjetivo, 'Ingresar bono objetivo') }
        name='bono_objetivo'
        value={ valores.bono_objetivo }
        error={ errorBonoObjetivo }
      />

      <InputFormRegister 
        title='Movilización'
        handleChange={ (e) => handleInputIntegerAtZero(e, setValores, valores ) }
        handleBlur={ (e) =>  validateInput(e, setErrorMovilizacion, setActiveMovilizacion, 'Ingresar movilización') }
        name='movilizacion'
        value={ valores.movilizacion }
        error={ errorMovilizacion }
      />

      <InputFormRegister 
        title='Colación'
        handleChange={ (e) => handleInputIntegerAtZero(e, setValores, valores ) }
        handleBlur={ (e) =>  validateInput(e, setErrorColacion, setActiveColacion, 'Ingresar colación') }
        name='colacion'
        value={ valores.colacion }
        error={ errorColacion }
      />

      <InputFormRegister 
        title='AFP'
        handleChange={ (e) => handleInputFloat(e, setValores, valores) }
        handleBlur={ (e) =>  validateInput(e, setErrorAfp, setActiveAfp, 'Ingresar % AFP') }
        name='afp'
        value={ valores.afp }
        error={ errorAfp }
        firstSymbol=''
        secondSymbol="%"
      />

      <InputFormRegister 
        title='Salud'
        handleChange={ (e) => handleInputFloat(e, setValores, valores) }
        handleBlur={ (e) =>  validateInput(e, setErrorSalud, setActiveSalud, 'Ingresar % salud ') }
        name='prevision_salud'
        value={ valores.prevision_salud }
        error={ errorSalud }
        firstSymbol=''
        secondSymbol="%"
      />

      <InputFormRegister 
        title='Seguro de Cesantía'
        handleChange={ (e) => handleInputFloat(e, setValores, valores) }
        handleBlur={ (e) =>  validateInput(e, setErrorCesantia, setActiveCesantia, 'Ingresar % cesantía') }
        name='cesantia'
        value={ valores.cesantia }
        error={ errorCesantia }
        firstSymbol=''
        secondSymbol="%"
      />

      <InputFormRegister 
        title='Seguro de Mutual'
        handleChange={ (e) => handleInputFloat(e, setValores, valores) }
        handleBlur={ (e) =>  validateInput(e, setErrorMutual, setActiveMutual, 'Ingresar % mutual') }
        name='seguro_mutual'
        value={ valores.seguro_mutual }
        error={ errorMutual }
        firstSymbol=''
        secondSymbol="%"
      />

      <InputFormRegister 
        title='SIS'
        handleChange={ (e) => handleInputFloat(e, setValores, valores) }
        handleBlur={ (e) =>  validateInput(e, setErrorSis, setActiveSis, 'Ingresar % SIS') }
        name='sis'
        value={ valores.sis }
        error={ errorSis }
        firstSymbol=''
        secondSymbol="%"
      />

      <BtnsFormRegister 
        cleanInputs={ cleanInputs }
        handleClick={ () => { setRegisterDate(setFechaRegistro) } }
      />

      <ToastContainer />
    </form>

  </>)
}
