import { query, collection, onSnapshot, where } from 'firebase/firestore';
import { filterProjectsByState } from './filterProjectsByState';
import { getAscOrderedProjectsByDate } from './getAscOrderedProjectsByDate';
import db from '../backend/DBFiresbase';



export const getActiveProjectsNamesByArea = ( area, setState ) => {

    const q = query(collection(db, 'proyectos' ), where( "area" , "==", area));

    onSnapshot( q, (querySnapshot) => {
        const docs = [];

        querySnapshot.forEach((doc) => {
            docs.push({...doc.data(), id:doc.id});
        });

        const filteredProjectsInExecution = filterProjectsByState(docs, "Ejecución");

        const projectsOrderedByDate = getAscOrderedProjectsByDate(filteredProjectsInExecution);

        let projectNames = projectsOrderedByDate.map(el => [ el.nombre,  "$ " + new Intl.NumberFormat('de-DE').format(el.presupuesto)])

        setState(projectNames);
    });

}
