import styles from '../Form.module.css';

export const SelectConcepto = ({title,
                              handleChange,
                              handleBlur,
                              name,
                              value,
                              error,
  }) => {
    return (
      <div className="col-md-4">
            <label className={` form-label ${ styles.labelForm } `}> { title } </label>
            <select 
              className={`form-select ${ styles.inputForm } ${ error === '' ? "" : styles.errorInput }`}
              onChange={ handleChange }
              onBlur={ handleBlur }
              name={ name }
              value={ value }
            >
              <option value="">Seleccione</option>
              <option value="Arriendos">Arriendos</option>
              <option value="Combustible">Combustible</option>
              <option value="Contratistas">Contratistas</option>
              <option value="EBG Casa Matriz">EBG Casa Matriz</option>
              <option value="EPPs">EPPs</option>
              <option value="Equipos">Equipos</option>
              <option value="Exámenes médicos">Exámenes Médicos</option>
              <option value="Finiquito">Finiquito</option>
              <option value="Fletes">Fletes</option>
              <option value="Mano de Obra">Mano de Obra</option>
              <option value="Mantenimientos">Mantenimientos</option>
              <option value="Materiales y Herramientas">Materiales y Herramientas</option>
              <option value="Multas">Multas</option>
              <option value="Otros">Otros</option>
              <option value="Patentes">Patentes</option>
              <option value="Seguros">Seguros y/o garantías</option>
              <option value="Servicios">Servicios</option>
              <option value="Utiles de aseo">Útiles de aseo</option>
              <option value="Utiles de escritorio">Útiles de escritorio</option>
              <option value="Vehículos">Vehículos(TAG/Mantenc./Leasing)</option>
              <option value="Viáticos">Viáticos</option>
            </select>
            <span className={ styles.error }>
              { error !== "" ? error : "" }
            </span>
          </div>
    )
}
