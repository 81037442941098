import { collection, doc, onSnapshot, orderBy, query, setDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { BtnDeleteRegister } from './BtnDeleteRegister';
import { BtnEditar } from './BtnEditar';
import { BuscadorRut } from './components/BuscadorRut';
import { BuscadorRazonSocial } from './components/BuscadorRazonSocial';
import { BtnNavigateRegisters } from './BtnNavigateRegisters';
import { TBodyLoading, ErrorBusqueda, ModalForm } from './components';
import { useAuth } from '../../../context/authContext';
import db from '../../../backend/DBFiresbase';
import FormRegistroClientes from './FormRegistroClientes';
import styles from './Form.module.css';


//Cantidad de items para paginación de gastos de Mnao de Obra
const ITEMS_NAVEGACION = 20;

export const TablaRegistroClientes = () => {

    const { user } = useAuth();

    const [ loadingTable, setLoadingTable ] = useState(false);
    const [rut, setRut] = useState('');
    const [razonSocial, setRazonSocial] = useState('');

    // Enviando datos a Firebase
        const setData = async (linkObjeto) => {
        const nuevoCliente = doc(collection(db, "clientes"));
        await setDoc(nuevoCliente, linkObjeto);
    }

    // Obteniendo  datos de Firebase
    const [ clientes, setClientes ] = useState([]);

    const q = query(collection(db, "clientes"), orderBy("fechaRegistro", "desc"));

    const getData = async () => {
        onSnapshot(q, (querySnapshot) => {
            const docs = [];
    
            querySnapshot.forEach((doc) => {
                docs.push({...doc.data(), id:doc.id});
            });
            const listaOrdenada = docs.sort((a,b) => b.fechaRegistro.localeCompare(a.fechaRegistro));
            listaOrdenada.sort((a,b) => new Date(b.fechaRegistro).getTime() - new Date(a.fechaRegistro).getTime() );
            setClientes(listaOrdenada);
        });
    }

    const clientesFiltrados = clientes?.filter(el => {
        return (
            ( !rut || el.rut.includes(rut) ) &&
            ( !razonSocial || el.razonSocial?.includes(razonSocial) )
        )
    })

    //? Mostrando los datos en el formulario
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    //? Separando los datos traídos de Firebase
    useEffect(() => {
        setItems([...clientesFiltrados].splice(0, ITEMS_NAVEGACION))
        setCurrentPage(0);
        setTimeout(() => {
        setLoadingTable(true);
        }, 800);
    },[ clientes, rut, razonSocial ])

      //todo Funciones de Navegación
    const nextHandler = () => {
        const totalElementos = clientesFiltrados.length;

        const nextPage = currentPage + 1;
        const firstIndex = nextPage * ITEMS_NAVEGACION;

        if(firstIndex >= totalElementos) return;

        setItems([...clientesFiltrados].splice(firstIndex, ITEMS_NAVEGACION));
        setCurrentPage(nextPage);
    }


    const prevHandler = () => {
        const prevPage = currentPage - 1;
        
        if(prevPage < 0) return;
    
        const firstIndex = prevPage * ITEMS_NAVEGACION;
    
        setItems([...clientesFiltrados].splice(firstIndex, ITEMS_NAVEGACION));
        setCurrentPage(prevPage);
    }


    // Mostrando los datos en la interfaz
    useEffect(() => {
        getData();
    }, []);

    return (<>
        <form className=' mx-auto d-flex justify-content-evenly  my-4 align-items-start'>
            <div className=" form-check d-flex align-items-center flex-column" >
                <BuscadorRazonSocial 
                    doc={ razonSocial }
                    setDoc={ setRazonSocial }
                    text="Buscar Razón Social..."
                />
            </div>

            <div className=" form-check d-flex align-items-center flex-column" >
                <BuscadorRut 
                    doc={ rut }
                    setDoc={ setRut }
                />
            </div>

            {
                user.perfil !== 'Gerente' &&
                <button 
                    className={ `btn py-1 ${styles.btnPrimary} fw-normal` } 
                    type="button" 
                    data-bs-toggle="modal" 
                    data-bs-target="#modalRegistroClientes" 
                >
                Registar
            </button>
            }
        </form>

        {/* Modal de Formulario de Registro de Clientes */}
        <ModalForm 
            id='modalRegistroClientes'
            form={ <FormRegistroClientes setData={ setData } clientes= { clientes } /> }
            title='Clientes'
        />


        <BtnNavigateRegisters 
            prevHandler={ prevHandler }
            currentPage={ currentPage }
            nextHandler={ nextHandler }
        />

    <div className="table-responsive">
        <table className={ `table text-center table-striped rounded-top ${styles.tableLarge} mx-auto px-2` }>
            <thead className="rounded-top">
                <tr className={ `${ styles.tableHead }` } >
                { 
                    (user.perfil === "Developer"  || user.perfil === "Administrador")  &&
                    <th>Eliminar</th>
                }
                {
                    user.perfil !== 'Gerente' &&
                    <th>Editar</th>
                }
                    <th>Razón Social</th>
                    <th>RUT</th>
                    <th>Suministro</th>
                    <th>Dirección</th>
                    <th>Comuna</th>
                    <th>Región</th>
                </tr>
            </thead>

            {
                loadingTable ? 
                    <tbody className={ `table-group-divider ${styles.tableBody} ` }>
                        {   items.length >= 1 ?
                            
                            items.map((el) => {
                                return(
                                    <tr key= {el.id}  className="table-secondary" >
                                    { 
                                        (user.perfil === "Developer"  || user.perfil === "Administrador")  &&
                                        <td>
                                            <BtnDeleteRegister 
                                                coleccion="clientes"
                                                id={ el.id } 
                                            />
                                        </td>
                                    }
                                    {
                                        user.perfil !== 'Gerente' &&
                                        <td>
                                            <BtnEditar id={ el.id } />
                                        </td>
                                    }
                                        <td>{ el.razonSocial }</td>
                                        <td>{ el.rut }</td>
                                        <td>{ el.suministro }</td>
                                        <td>{ el.direccion }</td>
                                        <td>{ el.comuna }</td>
                                        <td>{ el.region }</td>
                                    </tr>
                                )
                            })  :

                            <ErrorBusqueda 
                                colSpan={ 8 }
                                text='clientes registrados'
                            />
                        }
                    </tbody>

                :

                <TBodyLoading col='8' />
            }
        </table>
    </div>
    </>)
}
