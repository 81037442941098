import { doc, onSnapshot } from "firebase/firestore";
import db from "../backend/DBFiresbase";

export const getClienteProyecto = ( id, setCliente ) => {
  const documentoRef = doc(db, "proyectos", id);

  onSnapshot(documentoRef, (doc) => {
    const data = doc.data();
    setCliente(data.cliente ? data.cliente : 'Por definir');
  });
}
