

export const formatRut = ( value ) => {
  const cleanValue = value.toUpperCase().replace(/[^\dK]/g, '');
  

  const formatValue = cleanValue.replace(/^(\d{1,2})(\d{3})(\d{3})([0-9K]{1})$/, '$1.$2.$3-$4');


  return formatValue;

}
