import { TablaRegistroVentas } from '../../pure/form/TablaRegistroVentas';
import styles from "./Registros.module.css";



export default function RegistrosVentas() {

    return (
        <div className={ styles.WrapperContenedor } >
            <p className="h5 fw-bold align-self-start" >Registros de Ventas</p>
            <hr className="border border-dark border-1  w-100 m-1"></hr>

            <div className={`${ styles.wrapperTable }`} >
                <TablaRegistroVentas />
            </div>
        </div>
    )
}
