import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Title,
} from 'chart.js';

import 'chartjs-plugin-datalabels';

import { Bar } from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import { getProfitsOfActiveProjectsByArea } from '../getProfitsOfActiveProjectsByArea';
import { getCostsOfActiveProjectsByArea } from '../getCostsOfActiveProjectsByArea';
import { getFinalCostsOfActiveProjectsByArea } from '../getFinalCostsOfActiveProjectsByArea';
import { getBudgetsOfActiveProjectsByArea } from '../getBudgetsOfActiveProjectsByArea';
import { SpinnerCard } from '../spinner/SpinnerCard';
import { getActiveProjectsNamesByArea } from '../getActiveProjectsNamesByArea';
import { getFinalProfitsOfActiveProjectsByArea } from '../getFinalProfitsOfActiveProjectsByArea';
import { getFinalIndirectCostsOfActiveProjectsByArea } from '../getFinalIndirectCostsOfActiveProjectsByArea';
import styles from './Chartjs.module.css'
import LeyendaPresupuestos from './LeyendaPresupuestos';




ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Title,
);


const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      display: false,
    },
    title: {
      display: false,
      text: 'Gráfico de Presupuestos',
    },
    tooltip:{
      backgroundColor: 'rgba(12, 59, 35, 0.8)',
      callbacks: {
        label: function( context ) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }

                if (context.parsed.y !== null) {
                  label += `$ ${new Intl.NumberFormat('de-DE').format(context.parsed.y)}`;
                }

                return label;
        },
        title: function ( context ) {
          let titleCompleto = context[0].xLabel;
          let data = JSON.stringify(titleCompleto);          
          return data;
        }                
      },
    },
  },
  scales: {
      y: {
        ticks: {
          callback: function(val) {
            return (`$ ${new Intl.NumberFormat('de-DE').format(val / 1000000)}M`) 
          },
          font:{
            weight: 'bold',
            size: 12,
          }
        },
      },
      x: {
        ticks: {
          font:{
            weight: 'bold',
          }
        }
      }
  },
  
};

export const ActiveProjectsBudgets = ( { area } ) => {

  const [ proyectos, setProyectos ] = useState([]);
  const [ costosMatInic, setCostosMatInic ] = useState([]);
  const [ costosIndirectosInic, setCostosIndirectosInic ] = useState([]);
  const [ costosMOInic, setCostosMOInic ] = useState([]);
  const [ costosContratistasInic, setCostosContratistasInic ] = useState([]);
  const [ utilidadesInic, setutilidadesInic ] = useState([]);
  const [ utilidadesProy, setUtilidadesProy ] = useState([]);
  const [ costosMOProy, setCostosMOProy ] = useState([]);
  const [ costosContratistasProy, setCostosContratistasProy ] = useState([]);
  const [ costosMatProy, setcostosMatProy ] = useState([]);
  const [ costosIndirectosProy, setCostosIndirectosProy ] = useState([]);
  const [ utilidadesFinales, setUtilidadesFinales ] = useState([]);
  const [ costosMOFinales, setcostosMOFinales ] = useState([]);
  const [ costosContratistasFinales, setCostosContratistasFinales ] = useState([]);
  const [ costosMaterialesFinales, setcostosMaterialesFinales ] = useState([]);
  const [ costosIndirectosFinales, setCostosIndirectosFinales ] = useState([]);
  const [ PresupuestosTotales, setPresupuestosTotales ] = useState([]);
  const [ loading, setLoading ] = useState(true);


  useEffect(() => {
    getActiveProjectsNamesByArea( area, setProyectos );
  }, [ area ]);

  useEffect(() => {
    getProfitsOfActiveProjectsByArea( area, 'costoContratistasInicial', 'costosMaterialesInicial', 'costoManoObraInicial', 'costosIndirectosInicial', setutilidadesInic );
  }, [ area ]);

  useEffect(() => {
    getCostsOfActiveProjectsByArea( area, 'costoManoObraInicial', setCostosMOInic );
  }, [ area ]);

  useEffect(() => {
    getCostsOfActiveProjectsByArea( area, 'costoContratistasInicial', setCostosContratistasInic );
  }, [ area ]);

  useEffect(() => {
    getCostsOfActiveProjectsByArea( area, 'costosMaterialesInicial', setCostosMatInic );
  }, [ area ]);

  useEffect(() => {
    getCostsOfActiveProjectsByArea( area, 'costosIndirectosInicial', setCostosIndirectosInic );
  }, [area]);

  useEffect(() => {
    getProfitsOfActiveProjectsByArea( area, 'costoContratistasProyectado', 'costosMaterialesProyectado', 'costoManoObraProyectado', 'costosIndirectosProyectado', setUtilidadesProy );
  }, [ area ]);

  useEffect(() => {
    getCostsOfActiveProjectsByArea( area, 'costoManoObraProyectado', setCostosMOProy );
  }, [ area ]);

  useEffect(() => {
    getCostsOfActiveProjectsByArea( area, 'costoContratistasProyectado', setCostosContratistasProy );
  }, [ area ]);

  useEffect(() => {
    getCostsOfActiveProjectsByArea( area, 'costosMaterialesProyectado', setcostosMatProy );
  }, [ area ]);

  useEffect(() => {
    getCostsOfActiveProjectsByArea( area, 'costosIndirectosProyectado',setCostosIndirectosProy);
  }, [ area ]);

  useEffect(() => {
    getFinalProfitsOfActiveProjectsByArea( area, setUtilidadesFinales);
  }, [ area ]);

  useEffect(() => {
    getFinalCostsOfActiveProjectsByArea( area, 'Mano de Obra', setcostosMOFinales);
  }, [ area ]);

  useEffect(() => {
    getFinalCostsOfActiveProjectsByArea( area, 'Contratistas', setCostosContratistasFinales);
  }, [ area ]);

  useEffect(() => {
    getFinalCostsOfActiveProjectsByArea( area, 'Materiales', setcostosMaterialesFinales);
  }, [ area ]);

  useEffect(() => {
    getFinalIndirectCostsOfActiveProjectsByArea( area, setCostosIndirectosFinales);
  }, [ area ]);

  useEffect(() => {
    getBudgetsOfActiveProjectsByArea( area, setPresupuestosTotales);
  }, [ area ]);

  setTimeout(() => {
    setLoading(false);
  }, 600);



  const barData = {
      labels: proyectos,
      datasets: [
          {
              label: 'Util. Inic.',
              data: utilidadesInic,
              backgroundColor: 'rgb(67, 191, 117)',
              stack: 'Stack 0',
              order: 1,
          },
          {
              label: 'MO EBG Inic',
              data: costosMOInic,
              backgroundColor: 'rgb(65, 139, 171)',
              stack: 'Stack 0',
              order: 1,
          },
          {
              label: 'MO Contrat. Inic.',
              data: costosContratistasInic,
              backgroundColor: 'rgb(128, 61, 192)',
              stack: 'Stack 0',
              order: 1,
          },
          {
              label: 'Material. Inic.',
              data: costosMatInic,
              backgroundColor: 'rgb(194, 66, 129)',
              stack: 'Stack 0',
              order: 1,
          },
          {
              label: 'CIP Inic.',
              data: costosIndirectosInic,
              backgroundColor: 'rgb(183, 152, 60)',
              stack: 'Stack 0',
              order: 1,
          },


          {
              label: 'Util. Proy.',
              data: utilidadesProy,
              backgroundColor: 'rgb(67, 191, 117)',
              stack: 'Stack 1',
              order: 2,
          },
          {
              label: 'Costos MO Proy.',
              data: costosMOProy,
              backgroundColor: 'rgb(65, 139, 171)',
              stack: 'Stack 1',
              order: 2,
          },
          {
              label: 'MO Contrat. Proy.',
              data: costosContratistasProy,
              backgroundColor: 'rgb(128, 61, 192)',
              stack: 'Stack 1',
              order: 2,
          },
          {
              label: 'Material. Proy.',
              data: costosMatProy,
              backgroundColor: 'rgb(194, 66, 129)',
              stack: 'Stack 1',
              order: 2,
          },
          {
              label: 'CIP Proy.',
              data: costosIndirectosProy,
              backgroundColor: 'rgb(183, 152, 60)',
              stack: 'Stack 1',
              order: 2,
          },


          {
              label: 'Util. Final.',
              data: utilidadesFinales,
              backgroundColor: 'rgb(67, 191, 117)',
              stack: 'Stack 2',
              order: 3,
          },
          {
              label: 'Costos MO Final.',
              data: costosMOFinales,
              backgroundColor: 'rgb(65, 139, 171)',
              stack: 'Stack 2',
              order: 3,
          },
          {
              label: 'MO Contrat. Final.',
              data: costosContratistasFinales,
              backgroundColor: 'rgb(128, 61, 192)',
              stack: 'Stack 2',
              order: 3,
          },
          {
              label: 'Material. Final.',
              data: costosMaterialesFinales,
              backgroundColor: 'rgb(194, 66, 129)',
              stack: 'Stack 2',
              order: 3,
          },
          {
              label: 'CIP Final.',
              data: costosIndirectosFinales,
              backgroundColor: 'rgb(183, 152, 60)',
              stack: 'Stack 2',
              order: 3,
          },


          {
              label: 'Presupuesto Total',
              data: PresupuestosTotales,
              backgroundColor: 'rgba(255, 0, 0, 0.7)',
              borderColor: 'rgba(255, 0, 0, 1)',
              borderDash: [5, 5],
              tension: 0,
              pointStyle: 'circle',
              pointRadius: 6,
              pointHoverRadius: 8,
              type: 'line',
              order: 0,
              stack: true,
          },
      ],
  };

  return (<>
      {
          loading

              ?

              <div className={ `w-100 h-100 d-flex justify-content-center align-items-center ${ styles.wrapperLoading }` } >
                  <SpinnerCard />
              </div>

              :
              <>
                  <LeyendaPresupuestos />
                  <div className={`${styles.Container}`}>
                      <Bar options={ options } data={ barData } />
                  </div>
              </>
              
      }
  </>)
}
