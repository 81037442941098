import { TablaRegistroColaboradores } from '../../pure/form/TablaRegistroColaboradores';
import styles from './Registros.module.css';

export const RegistroColaboradores = () => {
  return (
    <div className={ styles.WrapperContenedor } >
            <p className="h5 fw-bold align-self-start" >Registros de Colaboradores</p>
            <hr className="border border-dark border-1  w-100 m-1"></hr>

            <div className={`${ styles.wrapperTable }`} >
                <TablaRegistroColaboradores />
            </div>
        </div>
  )
}
