import { query, collection, onSnapshot } from 'firebase/firestore';
import { getSumFilteredExpense } from './getSumFilteredExpense';
import db from '../backend/DBFiresbase';


const  getSumaGastos = (coleccion, estado) => {
    onSnapshot(query(collection(db,coleccion)), (querySnapshot) => {
        const docs = [];
        let valoresGastos = [];

        querySnapshot.forEach((doc) => {
            docs.push({...doc.data(), id:doc.id});
        });

        const gastosGenerales = docs.map(el => el.gastos);

        const gastosDiferenteEBG = gastosGenerales?.map(gasto => gasto.length 
                                    ?
                                    gasto.filter(el => el.proyecto !== 'EBG Casa Matriz') 
                                    : 0 
                                    );

        const gastosDiferentesNotaCredito = gastosDiferenteEBG?.map(gasto => gasto.length 
                                            ?
                                            gasto.filter(el => (el.tipoDocumento !== 'Nota de Crédito'))
                                            : 0 
                                            );

        gastosDiferentesNotaCredito.forEach( (array) => {
            array.length && valoresGastos.push(array.map(gasto => parseInt(gasto.valor)).reduce((a,b) => a+b))
        });


        const totalGastos = valoresGastos.reduce((a,b) => a+b);

        const totalNotaCredito = getSumFilteredExpense(gastosGenerales);


        estado(valoresGastos.length ? (totalGastos - totalNotaCredito) : 0);
    });

}

export default  getSumaGastos;


