import { useState, useEffect } from 'react';
import { doc, arrayUnion, updateDoc } from 'firebase/firestore';
import { formatNumDoc } from '../../../helpers/formatNumDoc';
import { showMessageError, showMessageExit } from '../../../helpers/ShowMessage';
import { ToastContainer } from 'react-toastify';
import { getFechaPago } from '../../../helpers/getFechaPago';
import { getRUTCliente } from '../../../helpers/getRUTCliente';
import { getClienteProyecto } from '../../../helpers/getClienteProyecto';
import { getListaProyectosAsc } from '../../../helpers/getListaProyectosAsc';
import { BtnsFormRegister, InputFormRegister } from './components';
import moment from 'moment';
import db from '../../../backend/DBFiresbase';
import getIDDoc from "../../../helpers/getIDDoc.js";
import styles from './Form.module.css';



export default function FormRegistroVentas( { ventas } ) {

    //Se almacenan array de los proyectos para extraer sus nombres en las opciones del input select
    const [ proyectos, setProyectos ] = useState([]);


//todo  ****  Setting data a Firebase ***
    //Data del objeto a enviar
    const valoresIniciales = {
        numDocumento: "",
        proyecto: "",
        tipoVenta: "",
        valor: "",
        fechaVenta: "",
        mesVenta: "",
        anioVenta: "",
        fechaRegistro: "",
        nOCAdicional:"",
        numConformidad: "",
        fechaVencimiento: '',
        ordenCompra: "",
        cliente: "",
        rutCliente: "",
    }

    const [valores, setValores] = useState(valoresIniciales);

    //fecha de Registro
    const [fechaRegistro, setFechaRegistro] = useState("");

    //Almacena el nombre de proyecto
    const [nombreProyecto, setNombreProyecto] = useState("");

    //Almacena el ID del proyecto
    const [id, setId] = useState("");

    const [ ventaDuplicada, setventaDuplicada   ] = useState("");
    const [ conformidadDuplicada, setConformidadDuplicada ] = useState("");
    const [ cliente, setCliente ] = useState("");
    const [ rutCliente, setRutCliente ] = useState("");

    const [ errorProyecto, setErrorProyecto ] = useState('');
    const [ activeProyecto, setActiveProyecto ] = useState(false);
    const [ errorNumDoc, setErrorNumDoc ] = useState('');
    const [ activeNumDoc, setActiveNumDoc ] = useState(false);
    const [ errorTipoVenta, setErrorTipoVenta ] = useState('');
    const [ activeTipoVenta, setActiveTipoVenta ] = useState(false);
    const [ errorVentaAdicional, setErrorVentaAdicional ] = useState('');
    const [ activeVentaAdicional, setActiveVentaAdicional   ] = useState(false);
    const [ errorValorVenta, setErrorValorVenta ] = useState('');
    const [ activeValorVenta, setActiveValorVenta ] = useState(false);
    const [ errorFecha, setErrorFecha ] = useState('');
    const [ activeFecha, setActiveFecha ] = useState(false);
    const [ activeOrdenCompra, setActiveOrdenCompra ] = useState(false);
    const [ errorOrdenCompra, setErrorOrdenCompra ] = useState('');
    const [ errorNumConformidad, setErrorNumConformidad ] = useState('');
    const [ activeNumConformidad, setActiveNumConformidad ] = useState(false);
    const [ fechaVencimiento, setFechaVencimiento ] = useState('');


//todo **** F U N C I O N E S  ****

// ?  *** Capturar los valores de los inputs del Form  ***
    const handleInput = (e) => {
        const {name, value} = e.target;
        setValores({...valores, [name]:value});
    }

    const validateInputNumeric = (e) => {
        const {name, value} = e.target;
        const formatValue = formatNumDoc(value);
        setValores({ ...valores, [name]:formatValue });
    }

    const validateInput = (e, setError, setActive, mensaje) => {
        const value = e.target.value;
    
        if(value === ""){
          setError(mensaje);
          setActive(false);
        } else{
          setError('');
          setActive(true);
        }
    }

    //Validar Numero de documento
    const validateNumDoc = (e, setError, setActive, duplicado, doc) => {
        const value = e.target.value;

        if(value === ""){
            setError(`Ingresar N° de ${doc}`);
            setActive(false);
        } else {
            if(duplicado=== true || duplicado.length >= 1){
                setError(`La ${doc} ya está registrada`);
                setActive(false);
            } else {
                setActive(true);
                setError('');
            }
        }
    }

    //Limpia los inputs
    const cleanInputs = () => {
        setNombreProyecto('');
        setId('');
        setCliente('');
        setRutCliente('');
        setErrorOrdenCompra('');
        setErrorNumDoc('');
        setErrorTipoVenta('');
        setErrorValorVenta('');
        setErrorFecha('');
        setErrorProyecto('');
        setErrorVentaAdicional('');
        setErrorNumConformidad('');
        setActiveOrdenCompra(false);
        setActiveFecha(false);
        setActiveNumDoc(false);
        setActiveProyecto(false);
        setActiveTipoVenta(false);
        setActiveValorVenta(false);
        setActiveVentaAdicional(false);
        setActiveNumConformidad(false);
        setValores(valoresIniciales);
    }

//?  *** Enviar la data a firebase
    const handleSubmit = (e) => {
        e.preventDefault();

        //Se capturan el mes y año de la fecha de la venta
        const mes = moment(valores.fechaVenta).format("MMMM");
        const anio = moment(valores.fechaVenta).format("YYYY");

        if( activeTipoVenta && 
            activeNumDoc && 
            activeFecha && 
            activeValorVenta && 
            activeProyecto &&
            activeNumConformidad &&
            (activeOrdenCompra || activeVentaAdicional) ) {

            //Se envían los datos de la venta al proyecto correspondiente
            const nuevaVenta = doc(db, "proyectos", id);

            showMessageExit("Registro exitoso");

            updateDoc(nuevaVenta,{ ventas: arrayUnion(
                {...valores, 
                fechaRegistro: fechaRegistro, 
                anioVenta: anio, 
                mesVenta: mes, 
                proyecto: nombreProyecto,
                fechaVencimiento: fechaVencimiento,
                cliente: cliente,
                rutCliente: rutCliente })}
            );

            //Se limpian los valores del formulario
            cleanInputs();

        } else {
            !activeTipoVenta && setErrorTipoVenta('Ingresar Razón Social');
            !activeNumDoc && setErrorNumDoc('Ingresar Número de Documento');
            !activeFecha && setErrorFecha('Ingrese unaa Fecha');
            !activeValorVenta && setErrorValorVenta('Ingresar el Valor Venta');
            !activeProyecto && setErrorProyecto('Seleccione un Proyecto');
            !activeNumConformidad && setErrorNumConformidad('Ingrese Número de Conformidad');
            ( valores.tipoVenta === "Contractual" && !activeOrdenCompra) && setErrorOrdenCompra('Ingresar Orden de Compra');
            ( valores.tipoVenta === "Adicional" && !activeVentaAdicional) && setErrorVentaAdicional('Ingresar Orden de Compra');
            showMessageError("¡Error!, tiene una casilla sin llenar", "bottom-center");
        }
    }


//? *** Registar la fecha en la que se envía la venta ***
    const handleClick = () => {
        setFechaRegistro(moment().format('YYYY-MM-DD HH:mm:ss'));
    }


//todo   *** MOSTRANDO  DATA  DE LOS NOMBRES DE LOS PROYECTOS  DE  FIREBASE  ***
    useEffect(() => {
        // Trayendo los nombres de los proyectos de Firestore
        getListaProyectosAsc(setProyectos);

        // Trayendo el ID del proyecto seleccionado
        const obtenerID = async () => {
            nombreProyecto !== "" && getIDDoc("proyectos", nombreProyecto, setId);
        }
        obtenerID();

    }, [nombreProyecto]);


    //Obteniendo ID del cliente
    useEffect(() => {
        if(nombreProyecto !== "" && id !== '' && valores.tipoVenta !== ''){
            getClienteProyecto(id, setCliente);
        } else{
            setCliente('');
        }
    },[id, nombreProyecto, valores.tipoVenta]);

    // Obteniendo RUT del Cliente
    useEffect(() => {
        if(cliente !== ""){
            getRUTCliente(cliente, setRutCliente);
        } else {
            setRutCliente('');
        }
    }, [cliente]);

    //Validando si hay una numDocumento duplicada
    useEffect(() => {
        setventaDuplicada(ventas.filter(venta => venta.numDocumento === valores.numDocumento))
    },[ventas,  valores.numDocumento]);

    //Validando si hay un N° de conformidad duplicado
    useEffect(() => {
        let conformidad = ventas.map(el => el.numConformidad);

        if(valores.numConformidad !== ''){
            setConformidadDuplicada(conformidad.includes(valores.numConformidad));
        }
    },[ventas, valores.numConformidad, conformidadDuplicada]);
    
    //Mostrando la fecha de pago o fecha de cobro
    useEffect(() => {
        valores.fechaVenta !== '' &&
        getFechaPago(valores.fechaVenta, 30, setFechaVencimiento)
    }, [valores.fechaVenta])



    return (<>
        <form 
            className="row g-4 " 
            onSubmit={ handleSubmit }
            autoComplete="off">

            <div className="col-md-4">
                <label className={` form-label ${ styles.labelForm } `}>Tipo de Venta:</label>
                <select
                    onChange={ handleInput }
                    className={`form-select ${ styles.inputForm } ${ errorTipoVenta === '' ? "" : styles.errorInput }`}
                    onBlur={ (e) => validateInput(e, setErrorTipoVenta, setActiveTipoVenta, 'Seleccione un Tipo de Venta') }
                    id="inputProveedorGasto" 
                    name="tipoVenta"
                    value={valores.tipoVenta}>
                    <option value="">Seleccione</option>
                    <option value="Contractual">Contractual</option>
                    <option value="Adicional">Adicional</option>
                </select>
                <span className={ styles.error }>
                    { errorTipoVenta !== "" ? errorTipoVenta : "" }
                </span>
            </div>

            <div className="col-md-4">
                <label className={` form-label ${ styles.labelForm } `}>Proyecto:</label>
                <select
                    onChange={ (e) => setNombreProyecto(e.target.value) }
                    onBlur={ (e) => validateInput(e, setErrorProyecto, setActiveProyecto, 'Seleccione un Proyecto') }
                    className={`form-select ${ styles.inputForm } ${ errorProyecto === '' ? "" : styles.errorInput }`} 
                    name="proyecto"
                    value={ nombreProyecto }>
                    <option value="">Seleccione</option>

                    {/* Se extraen los nombres de los proyectos */}
                        { proyectos.map((el, index) => {
                        return(
                            <option value={ el } key={ index + el }> { el } </option>
                        )
                        })}
                </select>
                <span className={ styles.error }>
                    { errorProyecto !== "" ? errorProyecto : "" }
                </span>
            </div>

            {cliente  && 
                <>
                    <div className="col-md-4">
                        <label className={` form-label ${ styles.labelForm } `}>Cliente:</label>
                        <input 
                            type="text"
                            className={`form-control ${ styles.inputForm }`} 
                            name="cliente"
                            value={ cliente } 
                            readOnly />
                    </div>

                    <div className="col-md-4">
                        <label className={` form-label ${ styles.labelForm } `}>RUT del Cliente:</label>
                        <input 
                            type="text"
                            className={`form-control ${ styles.inputForm }`} 
                            name="rutCliente"
                            value={ rutCliente } 
                            readOnly />
                    </div>
                </>
            }

            {
                (valores.tipoVenta === "Contractual")  &&
                
                <InputFormRegister 
                    title='N° Orden de Compra:'
                    handleChange={ validateInputNumeric }
                    handleBlur={ (e) => validateInput(e, setErrorOrdenCompra, setActiveOrdenCompra, 'Ingresar Orden de Compra') }
                    name="ordenCompra"
                    value={ valores.ordenCompra }
                    error={ errorOrdenCompra }
                    placeholder = 'Ejm: 67465'
                    firstSymbol=''
                />
            }

            {
                (valores.tipoVenta === "Adicional") &&
                
                <InputFormRegister 
                    title='N° Orden de Compra Adicional:'
                    handleChange={ validateInputNumeric }
                    handleBlur={ (e) => validateInput(e, setErrorVentaAdicional, setActiveVentaAdicional, 'Ingresar Orden de Compra') }
                    name="nOCAdicional"
                    value={ valores.nOCAdicional }
                    error={ errorVentaAdicional }
                    placeholder = 'Ejm: 67465'
                    firstSymbol=''
                />
            }

            <InputFormRegister 
                title='N° de Conformidad:'
                handleChange={ validateInputNumeric }
                handleBlur={ (e) => validateNumDoc(e, setErrorNumConformidad, setActiveNumConformidad, conformidadDuplicada, 'Conformidad') }
                name="numConformidad"
                value={ valores.numConformidad }
                error={ errorNumConformidad }
                placeholder = 'Ejm: 67465'
                firstSymbol=''
            />

            <InputFormRegister 
                title='N° de Factura:'
                handleChange={ validateInputNumeric }
                handleBlur={ (e) => validateNumDoc(e, setErrorNumDoc, setActiveNumDoc, ventaDuplicada, 'Factura') }
                name="numDocumento"
                value={ valores.numDocumento }
                error={ errorNumDoc }
                placeholder = 'Ejm: 67465'
                firstSymbol=''
            />

            <InputFormRegister 
                title='Valor de la Factura:'
                handleChange={ validateInputNumeric }
                handleBlur={ (e) => validateInput(e, setErrorValorVenta, setActiveValorVenta, 'Ingresar el Valor Venta') }
                name="valor"
                value={ valores.valor }
                error={ errorValorVenta }
            />

            <div className="col-md-4">
                <label className={ `form-label ${ styles.labelForm }` }>Fecha de Facturacion:</label>
                <input
                    onChange={ handleInput }
                    onBlur={(e) => validateInput(e, setErrorFecha, setActiveFecha, 'Ingresar fecha de la venta') }
                    type="date" 
                    className={`form-control ${ styles.inputForm } ${ errorFecha === '' ? "" : styles.errorInput }`} 
                    name="fechaVenta"
                    value={valores.fechaVenta}
                />
                <span className={ styles.error }>
                    { errorFecha !== "" ? errorFecha : "" }
                </span>
            </div>

            {
                valores.fechaVenta !== '' &&

                <div className="col-md-4">
                    <label className={ `form-label ${ styles.labelForm }` }>Fecha de Vencimiento:</label>
                    <input
                        className={`form-control ${ styles.inputForm }`}
                        type="date" 
                        name="fechaVencimiento"
                        value={ fechaVencimiento }
                        readOnly/>
                </div>
            }

            <BtnsFormRegister 
                cleanInputs={ cleanInputs }
                handleClick={ handleClick }
            />
            <ToastContainer/>
        </form>
    </>)
}
