import styles from "./Form.module.css";

export const BtnNavigateRegisters = ( { prevHandler, currentPage, nextHandler } ) => {

  return (
    <div className={`mx-auto w-50 gap-5 d-flex justify-content-center my-3`}>
      <button
          onClick={ prevHandler }
          className={` ${styles.btnNav}`}>
          <i className="bi bi-caret-left-fill fw-bold"></i>Atrás
      </button>
      <b className={ `${ styles.textPagina }` } >Página { currentPage + 1 }</b>
      <button
          onClick={ nextHandler } 
          className={` ${styles.btnNav}`}>
          Siguiente<i className="bi bi-caret-right-fill fw-bold"></i>
      </button>
    </div>
  )
}
