import styles from './Form.module.css';


export const BtnNavigateDetail = ({ 
  col=10, 
  handleClick,
  margin=''

  }) => {

  return (
    <div className={ `${margin}  col-${col} mx-auto d-flex  justify-content-evenly ${styles.divBtnNavigateDetail}`}>
      <button
        className={ `btn h-75 btn-danger fw-normal ${styles.btnFormRegister}` } 
        type="button"
        onClick={ handleClick }
      >
        <i className="bi bi-arrow-left me-1 "></i>
        Regresar
      </button>

      <button
        className={ `btn h-75 fw-normal ${styles.btnPrimary}` }
        type="submit" >
        Actualizar
      </button>
    </div>
  )
}
