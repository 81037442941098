import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from 'react';
import {  useNavigate, useParams } from 'react-router-dom';
import { formatRut } from "../../../helpers/formatRut";
import { showMessageError, showMessageExit } from "../../../helpers/ShowMessage";
import { APIRegiones } from "../../pure/form/APIRegiones";
import { ToastContainer } from "react-toastify";
import db from "../../../backend/DBFiresbase";
import getDataCollection from "../../../helpers/getDataCollection";
import styles from "./DetailStyles.module.css"
import { BtnNavigateDetail } from "../../pure/form/BtnNavigateDetail";



export const ProveedoresDetail = () => {

  const { id } = useParams();

  const navigate = useNavigate();

  const valoresIniciales = {
    razonSocial: "",
    suministro: "",
    direccion: "",
    comuna: "",
    region: "",
    fechaRegistro: "",
    rut:"",
    formaPago:"",
    diasPago: "",
  }

  const [ dataProveedores, setDataProveedores ] = useState( valoresIniciales );
  const [ proveedores, setProveedores ] = useState([]);
  const [ inputCredito, setInputCredito ] = useState(false);
  const [ activeRut, setActiveRut ] = useState(true);
  const [ errorRut, setErrorRut ] = useState("");
  const [ activeRegion, setActiveRegion ] = useState(true);
  const [ errorRegion, setErrorRegion ] = useState("");
  const [ activeComuna, setActiveComuna ] = useState(true);
  const [ errorComuna, setErrorComuna ] = useState("");

  const getDataProveedorDB = async () => {
    const docRef = doc(db, "proveedores", id);
    const docSnap = await getDoc(docRef);

    try {
      if (docSnap.exists()) {
        setDataProveedores(docSnap.data())
      }
    } catch (error) {
      console.error(error)
    }
  }

  //Obteniendo el valor del input
  const handleInput = (e) => {
    const {name, value} = e.target;
    setDataProveedores({...dataProveedores, [name]:value.toUpperCase()});
  }

  const handleInputAPI = (e) => {
    const {name, value} = e.target;
    setDataProveedores({...dataProveedores, [name]:value});
  }

  const handleRut = (e) => {
    const {name, value} = e.target;
    const formatValue = formatRut(value);
    setDataProveedores({ ...dataProveedores, [name]:formatValue });
  }

  const validateRut = (e) => {
    const value = e.target.value;
    const formatValue = formatRut(value);

    const regName = /^[1-9][0-9]{0,1}\.[0-9]{3}\.[0-9]{3}\-[0-9kK]$/;

    if( value !== "" ){
        if( !regName.test(formatValue)){
            setActiveRut(false);
            setErrorRut("El RUT ingresado es incorrecto");
        }
        else {
            if( proveedores.filter(el => el.rut !== dataProveedores.rut).some(el => el.rut === formatValue)){
                setActiveRut(false);
                setErrorRut("El RUT ya está registrado");
            } else {
                setActiveRut(true);
                setErrorRut('');
            }
        }
    } else {
        setActiveRut(false);
        setErrorRut('Ingrese RUT sin punto ni guión');
    }
  }

  //Envío de formulario
  const handleSubmit = (e) => {
    e.preventDefault();

    if( activeRut &&
        activeRegion &&
        activeComuna ){
      showMessageExit("¡Cliente actualizado con éxito!");
      const proyectoActualizado = doc(db, "proveedores", id);

      updateDoc(proyectoActualizado, 
        {...dataProveedores, 
        diasPago: dataProveedores.formaPago === 'CREDITO' ? dataProveedores.diasPago : 0} );
    } else {
      showMessageError(`¡Error!, RUT incorrecto`, "bottom-center");
    }
    
  }

  useEffect(() => {
    getDataCollection("clientes", setProveedores)
  }, [])

  useEffect(() => {
    dataProveedores.formaPago === 'CREDITO' ? 
    setInputCredito(true) :
    setInputCredito(false);
}, [ dataProveedores.formaPago, inputCredito ])

  useEffect(() => {
    getDataProveedorDB();
  }, []);



  return (
    <form 
      className={`row g-4 mx-auto mt-2 rounded ${styles.Form}`}
      onSubmit={ handleSubmit }
      autoComplete="off">
    
      <p className="text-center mb-0" > { dataProveedores.razonSocial } </p>

      <div className="m-0 col-md-3">
          <label className={`form-label ${styles.labelForm} `}>Razón Social del Proveedor:</label>
          <input 
            type="text"
            className={`form-control ${ styles.inputForm }`} 
            name="nombre"
            defaultValue={ dataProveedores.razonSocial } 
            readOnly />
          <span className={ styles.error }>Este dato no puede ser editado</span>
      </div>

      <div className="m-0 col-md-3">
        <label className={`form-label ${styles.labelForm}`}>RUT:</label>
        <input
          onChange={ handleRut }
          onBlur={ validateRut }
          name="rut"
          value={ dataProveedores.rut }
          type="text" 
          className={`form-control ${ styles.inputForm } ${ errorRut === "" ? ""  : styles.errorInput } `} 
          maxLength={ 12 } 
          autoComplete="off" />
        <span className={ styles.error }>
          {   errorRut !== "" ? 
              errorRut : 
              (
                  activeRut ? "" : "Ingrese RUT sin punto ni guión"
              )
          }
        </span>
      </div>

      <div className="m-0 col-md-3">
        <label className={`form-label ${styles.labelForm}`}>Giro:</label>
        <input
          onChange={ handleInput }
          name="suministro"
          value={ dataProveedores.suministro }
          type="text" 
          className={`form-control ${ styles.inputForm }`} />
      </div>

      <div className="m-0 col-md-3">
        <label className={`form-label ${styles.labelForm}`}>Forma de Pago</label>
        <select 
            className={`form-select ${ styles.inputForm }`}
            onChange={ handleInput }
            name="formaPago"
            value={ dataProveedores.formaPago }>
            <option value="">Seleccione</option>
            <option value="CONTADO">CONTADO</option>
            <option value="CREDITO">CREDITO</option>
        </select>
      </div>

      {
        inputCredito &&
        
        <div className="m-0 col-md-3">
          <label className={ `form-label ${styles.labelForm}` }>Días de pago:</label>
          <div className={ `input-group ${styles.inputSmall}` }>
              <input
                  onChange={ handleInput }
                  type="text" 
                  className={ `form-control ${ styles.inputForm }` } 
                  name="diasPago"
                  value={ dataProveedores.diasPago }
                  maxLength="2" 
                  min="0"
                  />
              <span className={`input-group-text py-1 px-1 ${styles.labelForm}`}>días</span>
          </div>
        </div>
      }

      <div className="m-0 col-md-3">
        <label className={`form-label ${styles.labelForm}`}>Dirección:</label>
        <input
          onChange={handleInput}
          name="direccion"
          value={ dataProveedores.direccion }
          type="text" 
          className={`form-control ${ styles.inputForm }`} />
      </div>

      <APIRegiones 
        handleInput={ handleInputAPI }
        valores={ dataProveedores }
        col={ `col-3 m-0` }
        select={`form-select ${ styles.inputForm } `}
        label={`form-label ${styles.labelForm} `}
        activeRegion={ activeRegion }
        activeComuna={ activeComuna }
        errorRegion={ errorRegion }
        errorComuna={ errorComuna }
        setActiveRegion={ setActiveRegion }
        setActiveComuna={ setActiveComuna }
        setErrorRegion={ setErrorRegion }
        setErrorComuna={ setErrorComuna }
      />

      <BtnNavigateDetail handleClick={ () => navigate(-1) }  />

      <ToastContainer/>
    </form>
  )
}
