import {query, collection, onSnapshot, orderBy, where} from 'firebase/firestore';
import db from '../backend/DBFiresbase';



export const getUtilidadesInic = ( estado ) => {
    const q = query(collection(db,"proyectos"), where("estado", "==", "Ejecución"), orderBy("fechaRegistro", "asc"));

    onSnapshot(q, (querySnapshot) => {
        const docs = [];

        querySnapshot.forEach((doc) => {
            docs.push({...doc.data(), id:doc.id});
        });

        const listaCostos = docs.map(el => {
            const contratistas = el.costoContratistasInicial ? parseInt(el.costoContratistasInicial) : 0;
            const materiales = el.costosMaterialesInicial ? parseInt(el.costosMaterialesInicial) : 0;
            const mo = el.costoManoObraInicial ? parseInt(el.costoManoObraInicial) : 0;
            const cip = el.costosIndirectosInicial ? parseInt(el.costosIndirectosInicial) : 0;
            const presupuesto = el.presupuesto ? parseInt(el.presupuesto) : 0;

            const utilidad = presupuesto - mo - contratistas - materiales - cip;

            return utilidad;
        });

        estado(listaCostos);
    });
}