import { NavLink } from "react-router-dom";
import { useAuth } from '../../../context/authContext';
import moment from "moment/moment";
import styles from "./Header.module.css";



export default function Header() {
    const { user } = useAuth();


    return (<>
        <div className= { styles.WrapperHeader } >
            <div className="d-flex align-items-center justify-content-between w-100 ">
                {
                    (user.perfil === "Gerente" || user.perfil === "Developer")  && 
                    <div className={ `d-flex align-items-center justify-content-evenly  ${ styles.wrapperLinks }` }>
                        <NavLink
                            className={ ( { isActive } ) =>  ` ${ isActive ? styles.activeLink : styles.textLink}` }
                            to="/home/resultados/oocc"  
                        >
                            OOCC
                        </NavLink>

                        <NavLink 
                            className={ ( { isActive } ) =>  ` ${ isActive ? styles.activeLink : styles.textLink}` }
                            to="/home/resultados/remodelaciones" 
                        >
                            Remodelaciones
                        </NavLink>

                        <NavLink 
                            className={ ( { isActive } ) =>  ` ${ isActive ? styles.activeLink : styles.textLink}` }
                            to="/home/resultados/licitaciones" 
                        >
                            Licitaciones
                        </NavLink>
        
                    </div>
                }

                <p className="m-0 ">{ moment().format("DD MMM YYYY") } </p>
            </div>
            
        </div>
    </>)
}
