import styles from './LeyendaPresupuesto.module.css';

const LeyendaPresupuestos = () => {
  return (<>
    <div className={`w-100 d-flex flex-column my-3`}>

      <div className={`w-100 d-flex justify-content-between align-items-center px-4 mb-3 `}>
        <div className='d-flex' >
          <i className={`bi bi-file-fill me-1 ${styles.Fuente} ${styles.LeyendaUtil}` }></i>
          <p className={` ${styles.Fuente}` } >Utilidades</p>
        </div>
        <div className='d-flex'>
          <i className={`bi bi-file-fill me-1 ${styles.Fuente} ${styles.LeyendaMO}` }></i>
          <p className={` ${styles.Fuente}` }>Mano de Obra EBG</p>
        </div>
        <div className='d-flex'>
          <i className={`bi bi-file-fill me-1 ${styles.Fuente} ${styles.LeyendaContrata}` }></i>
          <p className={` ${styles.Fuente}` }>Contratistas</p>
        </div>
      </div>

      <div className={`w-100 d-flex justify-content-between  align-items-center px-4 mb-3 `}>
        <div className='d-flex'>
          <i className={`bi bi-file-fill me-1 ${styles.Fuente} ${styles.LeyendaMat}` }></i>
          <p className={` ${styles.Fuente}` }>Materiales</p>
        </div>
        <div className='d-flex'>
          <i className={`bi bi-file-fill me-1 ${styles.Fuente} ${styles.LeyendaCIP}` }></i>
          <p className={` ${styles.Fuente}` }>Costos Indirectos</p>
        </div>
        <div className='d-flex'>
          <i className={`bi bi-circle-fill me-1 ${styles.Fuente} ${styles.LeyendaPresupuesto}` }></i>
          <p className={` ${styles.Fuente}` }>Presupuesto Total</p>
        </div>
      </div>
    </div>
  </>)
}

export default LeyendaPresupuestos