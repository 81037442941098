import { useEffect, useState } from 'react';
import { GraficoBarraDobleAvancesXArea } from '../../../helpers/graficos/GraficoBarraDobleAvancesXArea';
import { ActiveProjectsBudgets } from '../../../helpers/graficos/ActiveProjectsBudgets';
import CardImportes from '../../../models/CardImportes';
import getSumaVentasXArea from '../../../helpers/getSumaVentasXArea';
import getSumaGastosXArea from '../../../helpers/getSumaGastosXArea';
import getSumaPresupuestoXArea from '../../../helpers/getSumaPresupuestoXArea';
import getSumaFacturasXArea from '../../../helpers/getSumaFacturasXArea';
import GraficoBarraConceptoGastos from '../../../helpers/graficos/GraficoBarraConceptoGastos';
import iconVenta  from '../../../assets/icons/icons-ventas.png';
import iconCostos from '../../../assets/icons/icons-costos.png';
import iconUtilidades from '../../../assets/icons/icons-utilidades.png';
import styles from './Resultado.module.css';




export const ResultadoRemodelaciones = () => {
    // todo  ---- GETTING DATA DE FIREBASE  -----
    const [ gastos, setGastos ] = useState(undefined);
    const [ ventas, setVentas ] = useState(undefined);
    const [ sumaFacturas, setSumaFacturas ] = useState(undefined);
    const [ porCobrar, setPorCobrar ] = useState(undefined);
    const [ ganancia, setGanancia ] = useState(undefined);
    const [ isLoadingCard, setIsLoadingCard ] = useState(true);


    useEffect(() => {
        getSumaVentasXArea("proyectos","area","REMODELACIONES",setVentas);
    }, []);

    useEffect(() => {
        getSumaGastosXArea("proyectos","area","REMODELACIONES",setGastos);
    }, []);
    useEffect(() => {
        getSumaPresupuestoXArea("proyectos","area","REMODELACIONES",setPorCobrar);
    }, []);
    useEffect(() => {
        getSumaFacturasXArea("proyectos", "area", "REMODELACIONES", setSumaFacturas );
    }, []);

    useEffect(() => {
        ((ventas - gastos) > 0) ? setGanancia(true) : setGanancia(false);
    }, [gastos, ventas])

    useEffect(() => {
        if( ventas >= 0 ){
                setIsLoadingCard(false)
            }
    }, [ ventas, gastos, porCobrar ])



    return (<>
        <div className='mx-auto  w-100 '>
            <h1 className='text-center fs-3 fw-bold mt-xxl-3 ' >Resultados Remodelaciones</h1>

{/* ************      Cards de Resultados     ************  */}            
            <div className="row w-100 mx-auto my-4 d-flex flex-column flex-md-row justify-content-between  justify-content-md-evenly align-items-center ">

                <CardImportes nombre="Facturado" 
                    importe={ new Intl.NumberFormat('de-DE').format(ventas) } 
                    asunto="IVA:"
                    iva={ new Intl.NumberFormat('de-DE').format(ventas * (19/100)) || 0 }
                    icon={ iconVenta }
                    display="d-flex"
                    isLoading = { isLoadingCard }
                />

                <CardImportes nombre="Por facturar" 
                    importe={ new Intl.NumberFormat('de-DE').format(porCobrar - ventas) } 
                    asunto="IVA:"
                    iva={ new Intl.NumberFormat('de-DE').format((ventas * (19/100)) - (gastos * (19/100))) }
                    display="d-none"
                    icon={ iconUtilidades }
                    isLoading = { isLoadingCard }
                />

                <CardImportes nombre="Costos" 
                    importe={new Intl.NumberFormat('de-DE').format(gastos)}  
                    asunto="IVA:"
                    iva={ gastos > 0 ? ( new Intl.NumberFormat('de-DE').format((sumaFacturas) * (19/100)) || 0 ) : 0 }
                    icon={ iconCostos }
                    display="d-flex"
                    isLoading = { isLoadingCard }
                />

                <CardImportes nombre="Utilidades" 
                    importe={ new Intl.NumberFormat('de-DE').format(ventas - gastos) } 
                    asunto="IVA:"
                    iva={ (gastos < ventas) ? ( new Intl.NumberFormat('de-DE').format((ventas * (19/100)) - (gastos * (19/100)))  || 0 ) : 0 }
                    colorImporte={ `${(ventas - gastos) > 0 ? styles.colorGanancia : styles.colorPerdida }` }
                    display="d-flex"
                    isLoading = { isLoadingCard }
                    utilidad={ ganancia }
                />
            </div>

{/* ************      Graficos     ************  */}
            <div className="w-100 mx-auto text-center d-flex flex-column align-items-center flex-lg-row justify-content-evenly my-5 ">
                <div className={` ${ styles.ContainerGrafico } d-flex flex-column mb-5 mb-lg-0 `}>
                    <p className={ `mb-0 ${ styles.TituloGraficos }` }>"Comparación de Presupuestos Iniciales, Proyectados y Finales"</p>
                    <p className={ `mb-3 ${ styles.SubTituloGraficos }` } >(Valores netos en CLP$)</p>
                    <ActiveProjectsBudgets area="REMODELACIONES" />
                </div>

                <div className={` ${styles.ContainerGrafico} d-flex flex-column mb-5 mb-lg-0 `}>
                    <p className={ `mb-0 ${styles.TituloGraficos}` }>"Comparación entre Ventas y Avance de Producción"</p>
                    <p className={ `mb-3 ${styles.SubTituloGraficos}` } >(Valores netos en CLP$)</p>
                    <GraficoBarraDobleAvancesXArea tipoArea="REMODELACIONES" />
                </div>
            </div>


            <div className="w-100 mx-auto text-center d-flex flex-column align-items-center flex-lg-row justify-content-evenly my-5">
                <div className={` ${styles.ContainerGrafico} d-flex flex-column mb-5 mb-lg-0 `}>
                    <p className={ `mb-0 ${styles.TituloGraficos}` }>"Distribución de Gastos por Concepto"</p>
                    <p className={ `mb-3 ${styles.SubTituloGraficos}` } >(Valores netos en CLP$)</p>
                    <GraficoBarraConceptoGastos tipoArea="REMODELACIONES" />
                </div>
            </div>
            
        </div>
    </>)
}
