import { query, collection, onSnapshot, where } from 'firebase/firestore';
import { filterProjectsByState } from './filterProjectsByState';
import { getAscOrderedProjectsByDate } from './getAscOrderedProjectsByDate';
import db from '../backend/DBFiresbase';


export const getFinalIndirectCostsOfActiveProjectsByArea = ( area, setState  ) => {
  
  const q = query(collection(db, 'proyectos' ), where( "area" , "==", area));

  onSnapshot(q, (querySnapshot) => {
    const docs = [];

    querySnapshot.forEach((doc) => {
        docs.push({...doc.data(), id:doc.id});
    });

    const filteredProjectsInExecution = filterProjectsByState(docs, "Ejecución");

    const projectsOrderedByDate = getAscOrderedProjectsByDate(filteredProjectsInExecution);

    const costs = projectsOrderedByDate.map(project => project.gastos.length ? project.gastos : [0]);

    let indirectCosts = [];

    costs.forEach( (array) => {
      indirectCosts.push(array
        .filter(el => el ? (el.concepto !== "Materiales" && el.concepto !== "Contratistas" && el.concepto !== "Mano de Obra") : 0)
        .reduce((acc, el) => acc + parseInt(el.valor), 0))
    })

    setState(indirectCosts.length ? indirectCosts : 0);
  });
}
