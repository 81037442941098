

export const ErrorBusqueda = ({ colSpan, text }) => {

  return (
    <tr className="table-secondary" >
      <td colSpan={ colSpan } className={`text-danger fw-bold`} > 
        { `"Lo sentimos, no hay ${ text } que coincidan con la búsqueda realizada"` } 
      </td>
    </tr>
  )
}
