import { regions } from "../../../backend/APIRegionesComunas";
import { validateInput } from "../../../helpers/validaciones";
import styles from "./Form.module.css";


export const APIRegiones = ({ 
    handleInput, 
    valores, 
    col = 'col-md-4', 
    select = 'form-select mx-auto',
    label = 'form-label',
    activeRegion,
    errorRegion,
    activeComuna,
    errorComuna,
    setActiveRegion,
    setErrorRegion,
    setActiveComuna,
    setErrorComuna,
}) => {

    const validateRegion = () => {
        if( valores.region === ''  ){
            setActiveComuna(false);
            setActiveRegion(false);
            setErrorRegion('Seleccione una Región');
            setErrorComuna('Seleccione una Comuna');
        } else {
            setActiveRegion(true);
            setErrorRegion('');
        }
    }



    return (<>
        <div className={ col }>
            <label className={ label }>Región</label>
            <select
                className={ `${ select }  ${ errorRegion === '' ? "" : styles.errorInput }` }
                onBlur={ validateRegion } 
                onChange={ handleInput }
                name="region"
                value={ valores.region }>
                <option value="">Seleccione</option>
                {
                    regions.map(el => (
                        <option key={ el.number } value={ el.name }>{ el.name }</option>
                    ))
                }
            </select>
            <span className={ styles.error }>
                { errorRegion !== "" ? errorRegion : "" }
            </span>
        </div>

        <div className={col}>
            <label className={ label }>Comuna</label>
            <select
                className={ `${ select }  ${ errorComuna === ''  ? "" : styles.errorInput }` } 
                onChange={ handleInput }
                onBlur={ (e) => validateInput(e, setErrorComuna, setActiveComuna, 'Seleccione una Comuna') } 
                name="comuna"
                value={ valores.comuna }>
                <option value="">Seleccione</option>
                {
                    regions.find((r) => r.name === valores.region)?.comunes.map((el) => (
                        <option key={ el.name } value={ el.name }>{ el.name }</option>
                    ))
                }
            </select>
            <span className={ styles.error }>
                { errorComuna !== "" ? errorComuna : "" }
            </span>
        </div>
    </>)
}
