import { useEffect, useState } from 'react';
import { SpinnerCard } from '../spinner/SpinnerCard';
import Chart from 'react-apexcharts';
import getSumaGastos from '../getSumaGastos';
import getSumaGastosXConcepto from '../getSumaGastosXConcepto';
import styles from './Graficos.module.css';



export default function GraficoTipoGastos() {

    const [ valorEquipos, setValorEquipos ] = useState(0);
    const [ valorMateriales, setValorMateriales ] = useState(0);
    const [ valorManoObra, setValorManoObra ] = useState(0);
    const [ valorCombustible, setValorCombustible ] = useState(0);
    const [ valorArriendos, setValorArriendos ] = useState(0);
    const [ valorUtilesOficina, setValorUtilesOficina ] = useState(0);
    const [ valorEPP, setValorEPP ] = useState(0);
    const [ valorMantenimiento, setValorMantenimiento ] = useState(0);
    const [ valorSeguros, setValorSeguros ] = useState(0);
    const [ valorFletes, setValorFletes ] = useState(0);
    const [ valorServicios, setValorServicios ] = useState(0);
    const [ valorExamenesMed, setValorExamenesMed ] = useState(0);
    const [ valorViaticos, setValorViaticos ] = useState(0);
    const [ valorContratistas, setValorContratistas ] = useState(0);
    const [ valorOtros, setValorOtros ] = useState(0);
    const [ valorFiniquito, setValorFiniquito ] = useState(0);
    const [ valorEBGCasaMatriz, setValorEBGCasaMatriz ] = useState(0);
    const [ valorPatentes, setValorPatentes ] = useState(0);
    const [ valorMultas, setValorMultas ] = useState(0);
    const [ valorVehiculos, setValorVehiculos ] = useState(0);
    const [ valorUtilesAseo, setValorUtilesAseo ] = useState(0);
    const [ gastosTotal, setGastosTotal ] = useState(undefined);

    const [ isLoadingData, setIsLoadingData ] = useState(true);


    const options = {
            colors: ['#0faa16'],
            chart: {
                type: 'bar',
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                    colors: {
                        backgroundBarColors: ['#B5C0B7'],
                    },
                },
            },
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: "12px",
                    fontWeight: 400,
                },
                formatter: function (val) {
                    return (`$ ` + new Intl.NumberFormat('de-DE').format(val) );
                },
            },
            xaxis: {
                categories: [   'Arriendos', "Contratistas", 'Combustible', 'EBG Casa Matriz', 'EPPs', 'Equipos', "Exam. Médicos", 'Finiquitos', 'Fletes',
                                "Mano de Obra", 'Mantenimientos', 'Materiales y Herr.', 'Multas', "Otros", 'Patentes', 'Seguros y/o Garantías',
                                'Servicios','Utls. de Aseo', 'Utls. de Escritorio', 'Vehículos',  "Viáticos", 
                ],
                labels: {
                    style: {
                        fontSize: "12px",
                        fontWeight: "600",
                    },
                    formatter: function (val) {
                        return (`$ ${new Intl.NumberFormat('de-DE').format(val / 1000000)}M`);
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        fontSize: "12px",
                    },
                },
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return ( val !== 0  ?  `${((val / gastosTotal)*100).toFixed(2)}%` : 0 );
                    },
                },
            }
            
        }

    const series = [
            {
                name: "Gasto",
                data: [ valorArriendos, valorContratistas, valorCombustible, valorEBGCasaMatriz, valorEPP, valorEquipos,
                        valorExamenesMed, valorFiniquito, valorFletes,  valorManoObra,
                        valorMantenimiento, valorMateriales, valorMultas, valorOtros, valorPatentes, valorSeguros, 
                        valorServicios, valorUtilesAseo, valorUtilesOficina, valorVehiculos, valorViaticos  ], 
            },
        ]


    useEffect( () => {
        getSumaGastos("proyectos", setGastosTotal)
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConcepto("proyectos",  "Equipos", setValorEquipos);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConcepto("proyectos",  "Materiales y Herramientas", setValorMateriales);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConcepto("proyectos",  "Mano de Obra", setValorManoObra);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConcepto("proyectos",  "Combustible", setValorCombustible);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConcepto("proyectos",  "Arriendos", setValorArriendos);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConcepto("proyectos",  "Utiles de escritorio", setValorUtilesOficina);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConcepto("proyectos",  "EPPs", setValorEPP);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConcepto("proyectos",  "Mantenimientos", setValorMantenimiento);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConcepto("proyectos",  "Seguros", setValorSeguros);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConcepto("proyectos",  "Fletes", setValorFletes);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConcepto("proyectos",  "Servicios", setValorServicios);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConcepto("proyectos",  "Exámenes médicos", setValorExamenesMed);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConcepto("proyectos",  "Viáticos", setValorViaticos);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConcepto("proyectos",  "Contratistas", setValorContratistas);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConcepto("proyectos",  "Otros", setValorOtros);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConcepto("proyectos",  "Finiquito", setValorFiniquito);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConcepto("proyectos",  "Patentes", setValorPatentes);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConcepto("proyectos",  "Multas", setValorMultas);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConcepto("proyectos",  "EBG Casa Matriz", setValorEBGCasaMatriz);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConcepto("proyectos",  "Vehículos", setValorVehiculos);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConcepto("proyectos",  "Utiles de aseo", setValorUtilesAseo);
    }, [gastosTotal]);

    useEffect(() => {
        if( gastosTotal >= 0) {
            setIsLoadingData(false);
        }
    }, [gastosTotal]);


    return (<>
        {
            isLoadingData 
            
                ?

            <div className={ `w-100 h-100 d-flex justify-content-center align-items-center ${ styles.wrapperLoading }` } >
                <SpinnerCard />
            </div> 
            
                :

            <Chart 
                options={options}
                series={series} 
                type="bar" 
                height={450}
            /> 
        }

    </>)
}
