
export const SelectAnio = ( { className, eventChange, anio } ) => {

  // Generamos una lista de opciones para el select
  const yearOptions = [];
  const maxYear = new Date().getFullYear();
  const minYear = 2010;

  for (let i = maxYear; i >= minYear; i--) {
    yearOptions.push(<option key={i} value={i}>{i}</option>);
  }


  return (
    <select
      className={ className }
      value={ anio } 
      onChange={ eventChange }
      name="anio"
      >
      <option className="text-center"  value="">Año</option>
      {
        yearOptions
      }
    </select>
  )
}



