import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    Title,
} from 'chart.js';

import 'chartjs-plugin-datalabels';

import { Bar } from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import { getCostosMaterialesInic } from '../getCostosMaterialesInic';
import { getCostosIndirectosInic } from '../getCostosIndirectosInic';
import { getCostosMOInic } from '../getCostosMOInic';
import { getCostosContratistasInic } from '../getCostosContratistasInic';
import { getUtilidadesInic } from '../getUtilidadesInic';
import { getUtilidadesProy } from '../getUtilidadesProy';
import { getCostosMOProy } from '../getCostosMOProy';
import { getCostosContratistasProy } from '../getContratistasProy';
import { getCostosMaterialesProy } from '../getCostosMaterialesProy';
import { getCostosIndirectosProy } from '../getCostosIndirectosProy';
import { getPresupuestosTotales } from '../getPresupuestosTotales';
import { SpinnerCard } from '../spinner/SpinnerCard';
import { getActiveProjectsNames } from '../getActiveProjectsNames';
import getUtilidadesFinales from '../getUtilidadesFinales';
import getCostosMOFinales from '../getCostosMOFinales';
import getCostosContratistasFin from '../getCostosContratistasFin';
import getCostosMaterialesFin from '../getCostosMaterialesFin';
import getCostosIndirectosFin from '../getCostosIndirectosFin';
import styles from './Chartjs.module.css'
import getListaProyectosNombresEnEjecucion from '../getListaProyectosNombresEnEjecucion';
import LeyendaPresupuestos from './LeyendaPresupuestos';



ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    Title,
);


const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            display: false,
        },
        title: {
            display: false,
            text: 'Gráfico de Presupuestos',
        },
        tooltip:{
            backgroundColor: 'rgba(12, 59, 35, 0.8)',
            callbacks: {
                label: function( context ) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }

                        if (context.parsed.y !== null) {
                            label += `$ ${new Intl.NumberFormat('de-DE').format(context.parsed.y)}`;
                        }

                        return label;
                },
                title: function ( context ) {
                    let titleCompleto = context[0].xLabel;
                    let data = JSON.stringify(titleCompleto);
                    console.log(data);
                    
                    return data;
                }                
            },
        },
    },
    scales: {
        y: {
            ticks: {
                callback: function(val) {
                    return (`$ ${new Intl.NumberFormat('de-DE').format(val / 1000000)}M`) 
                },
                font:{
                    weight: 'bold',
                    size: 12,
                }
            },
        },
        x: {
            ticks: {
                font:{
                    weight: 'bold',
                }
            }
        }
    },
    
};


export const Chartjs = () => {

    const [ proyectos, setProyectos ] = useState([]);
    const [ costosMatInic, setCostosMatInic ] = useState([]);
    const [ costosIndirectosInic, setCostosIndirectosInic ] = useState([]);
    const [ costosMOInic, setCostosMOInic ] = useState([]);
    const [ costosContratistasInic, setCostosContratistasInic ] = useState([]);
    const [ utilidadesInic, setutilidadesInic ] = useState([]);
    const [ utilidadesProy, setUtilidadesProy ] = useState([]);
    const [ costosMOProy, setCostosMOProy ] = useState([]);
    const [ costosContratistasProy, setCostosContratistasProy ] = useState([]);
    const [ costosMatProy, setcostosMatProy ] = useState([]);
    const [ costosIndirectosProy, setCostosIndirectosProy ] = useState([]);
    const [ utilidadesFinales, setUtilidadesFinales ] = useState([]);
    const [ costosMOFinales, setcostosMOFinales ] = useState([]);
    const [ costosContratistasFinales, setCostosContratistasFinales ] = useState([]);
    const [ costosMaterialesFinales, setcostosMaterialesFinales ] = useState([]);
    const [ costosIndirectosFinales, setCostosIndirectosFinales ] = useState([]);
    const [ PresupuestosTotales, setPresupuestosTotales ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        getActiveProjectsNames(setProyectos);
    }, [])

    useEffect(() => {
        getUtilidadesInic(setutilidadesInic);
    }, [])

    useEffect(() => {
        getCostosMOInic(setCostosMOInic);
    }, [])

    useEffect(() => {
        getCostosContratistasInic(setCostosContratistasInic);
    }, [])

    useEffect(() => {
        getCostosMaterialesInic(setCostosMatInic);
    }, [])

    useEffect(() => {
        getCostosIndirectosInic(setCostosIndirectosInic);
    }, [])

    useEffect(() => {
        getUtilidadesProy(setUtilidadesProy);
    }, [])

    useEffect(() => {
        getCostosMOProy(setCostosMOProy);
    }, [])

    useEffect(() => {
        getCostosContratistasProy(setCostosContratistasProy);
    }, [])

    useEffect(() => {
        getCostosMaterialesProy(setcostosMatProy);
    }, [])

    useEffect(() => {
        getCostosIndirectosProy(setCostosIndirectosProy);
    }, [])

    useEffect(() => {
        getUtilidadesFinales(setUtilidadesFinales)
    }, [])

    useEffect(() => {
        getCostosMOFinales(setcostosMOFinales);
    }, [])

    useEffect(() => {
        getCostosContratistasFin(setCostosContratistasFinales);
    }, [])

    useEffect(() => {
        getCostosMaterialesFin(setcostosMaterialesFinales);
    }, [])

    useEffect(() => {
        getCostosIndirectosFin(setCostosIndirectosFinales);
    }, [])

    useEffect(() => {
        getPresupuestosTotales(setPresupuestosTotales);
    }, [])

    setTimeout(() => {
        setLoading(false);
    }, 600);


    const barData = {
        labels: proyectos,
        datasets: [
            {
                label: 'Util. Inic.',
                data: utilidadesInic,
                backgroundColor: 'rgb(67, 191, 117)',
                stack: 'Stack 0',
                order: 1,
            },
            {
                label: 'MO EBG Inic',
                data: costosMOInic,
                backgroundColor: 'rgb(65, 139, 171)',
                stack: 'Stack 0',
                order: 1,
            },
            {
                label: 'MO Contrat. Inic.',
                data: costosContratistasInic,
                backgroundColor: 'rgb(128, 61, 192)',
                stack: 'Stack 0',
                order: 1,
            },
            {
                label: 'Material. Inic.',
                data: costosMatInic,
                backgroundColor: 'rgb(194, 66, 129)',
                stack: 'Stack 0',
                order: 1,
            },
            {
                label: 'CIP Inic.',
                data: costosIndirectosInic,
                backgroundColor: 'rgb(183, 152, 60)',
                stack: 'Stack 0',
                order: 1,
            },


            {
                label: 'Util. Proy.',
                data: utilidadesProy,
                backgroundColor: 'rgb(67, 191, 117)',
                stack: 'Stack 1',
                order: 2,
            },
            {
                label: 'Costos MO Proy.',
                data: costosMOProy,
                backgroundColor: 'rgb(65, 139, 171)',
                stack: 'Stack 1',
                order: 2,
            },
            {
                label: 'MO Contrat. Proy.',
                data: costosContratistasProy,
                backgroundColor: 'rgb(128, 61, 192)',
                stack: 'Stack 1',
                order: 2,
            },
            {
                label: 'Material. Proy.',
                data: costosMatProy,
                backgroundColor: 'rgb(194, 66, 129)',
                stack: 'Stack 1',
                order: 2,
            },
            {
                label: 'CIP Proy.',
                data: costosIndirectosProy,
                backgroundColor: 'rgb(183, 152, 60)',
                stack: 'Stack 1',
                order: 2,
            },


            {
                label: 'Util. Final.',
                data: utilidadesFinales,
                backgroundColor: 'rgb(67, 191, 117)',
                stack: 'Stack 2',
                order: 3,
            },
            {
                label: 'Costos MO Final.',
                data: costosMOFinales,
                backgroundColor: 'rgb(65, 139, 171)',
                stack: 'Stack 2',
                order: 3,
            },
            {
                label: 'MO Contrat. Final.',
                data: costosContratistasFinales,
                backgroundColor: 'rgb(128, 61, 192)',
                stack: 'Stack 2',
                order: 3,
            },
            {
                label: 'Material. Final.',
                data: costosMaterialesFinales,
                backgroundColor: 'rgb(194, 66, 129)',
                stack: 'Stack 2',
                order: 3,
            },
            {
                label: 'CIP Final.',
                data: costosIndirectosFinales,
                backgroundColor: 'rgb(183, 152, 60)',
                stack: 'Stack 2',
                order: 3,
            },


            {
                label: 'Presupuesto Total',
                data: PresupuestosTotales,
                backgroundColor: 'rgba(255, 0, 0, 0.7)',
                borderColor: 'rgba(255, 0, 0, 1)',
                borderDash: [5, 5],
                tension: 0,
                pointStyle: 'circle',
                pointRadius: 6,
                pointHoverRadius: 8,
                type: 'line',
                order: 0,
                stack: true,
            },
        ],
    };

    return (<>
        {
            loading

            ?

                <div className={ `w-100 h-100 d-flex justify-content-center align-items-center ${ styles.wrapperLoading }` } >
                    <SpinnerCard />
                </div>

            :
                <>
                    <LeyendaPresupuestos />
                    <div className={`${styles.Container}`}>
                        <Bar options={ options } data={ barData } />
                    </div>
                </>
        }
    </>)
}
