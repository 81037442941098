import styles from '../Form.module.css';

export const ModalForm = ( { id, form, title } ) => {


  return (
    <div 
      className="modal fade" 
      id={ id }  
      aria-labelledby="exampleModalLabel" 
      aria-hidden="true" 
      data-bs-backdrop="static" 
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className={ `modal-header ${ styles.modalHead } ` } >
            <h5 className="modal-title" id={ id }>
              Formulario de Registro de { title }
            </h5>
          </div>
          <div className={ `modal-body ${ styles.modalBody }` } >
            { form }
          </div>
        </div>
      </div>
    </div>
  )
}
