import { query, collection, onSnapshot, orderBy, where } from 'firebase/firestore';
import db from '../backend/DBFiresbase';

const  getCostosIndirectosFin = (estado) => {
    onSnapshot(query(collection(db, "proyectos"), where("estado", "==", "Ejecución"), orderBy("fechaRegistro", "asc")), (querySnapshot) => {
        const docs = [];

        querySnapshot.forEach((doc) => {
            docs.push({...doc.data(), id:doc.id});
        });

        const arrayGastos = docs.map(el => el.gastos.length ? el.gastos : [0]);

        let listaGastos = []

        arrayGastos.forEach( (array) => {
            listaGastos.push(array.filter(el => el ? (el.concepto !== "Materiales" && el.concepto !== "Contratistas" && el.concepto !== "Mano de Obra") : 0).reduce((acc, el) => acc + parseInt(el.valor), 0))
        })

        estado(listaGastos.length ? listaGastos : 0)
    });

}

export default  getCostosIndirectosFin;