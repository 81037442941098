import { useEffect, useState } from 'react';
import {  useNavigate, useParams } from 'react-router-dom';
import { getListaGastos } from '../../../helpers/getListaGastos';
import { ToastContainer } from 'react-toastify';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { formatNumDoc } from '../../../helpers/formatNumDoc';
import { showMessageError, showMessageExit } from '../../../helpers/ShowMessage';
import { InputFormRegister } from '../../pure/form/components';
import { BtnNavigateDetail } from '../../pure/form/BtnNavigateDetail';
import getDataCollectionProveedores from '../../../helpers/getDataCollectionProveedores';
import getIDDoc from '../../../helpers/getIDDoc';
import db from '../../../backend/DBFiresbase';
import moment from "moment";
import styles from './DetailStyles.module.css';



export const GastosDetail = () => {

  const { id } = useParams();
  const navigate = useNavigate();

  const valoresIniciales = {
    tipoDocumento: "",
    fechaGasto: "",
    valor: "",
    proveedor: "",
    concepto: "",
    formaPago: "",
    fechaRegistro: "",
    mesGasto:"",
    anioGasto:""
  }


  const proyecto = id.split("-")[0];
  const numDoc = id.split("-")[1];

  const [ dataGasto, setDataGasto ] = useState(valoresIniciales);
  const [ proveedores, setProveedores ] = useState([]);
  const [ idProyecto, setIdProyecto ] = useState("");
  const [ errorTipoDoc, setErrorTipoDoc ] = useState('');
  const [ activeTipoDoc, setActiveTipoDoc ] = useState(true);
  const [ errorFecha, setErrorFecha ] = useState('');
  const [ activeFecha, setActiveFecha ] = useState(true);
  const [ activeValorGasto, setActiveValorGasto ] = useState(true);
  const [ errorValorGasto, setErrorValorGasto ] = useState('');
  const [ errorProveedor, setErrorProveedor ] = useState('');
  const [ activeProveedor, setActiveProveedor ] = useState(true);
  const [ activeConcepto, setActiveConcepto ] = useState(true);
  const [ errorConcepto, setErrorConcepto ] = useState('');
  const [ errorFormaPago, setErrorFormaPago ] = useState('');
  const [ activeFormaPago, setActiveFormaPago ] = useState(true);




  //Obteniendo el valor del input
  const handleInput = (e) => {
    const {name, value} = e.target;
    setDataGasto({...dataGasto, [name]:value});
  }

  const handlePrecio = (e) => {
    const {name, value} = e.target;
    const formatValue = formatNumDoc(value);
    setDataGasto({ ...dataGasto, [name]:formatValue });
  }

  const validateInput = (e, setError, setActive, mensaje) => {
    const value = e.target.value;

    if(value === ""){
      setError(mensaje);
      setActive(false);
    } else{
      setError('');
      setActive(true);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const proyectoRef = doc(db, "proyectos", idProyecto);
    const docRef = await getDoc(proyectoRef);

    // Actualiza el array en el documento
    if( activeTipoDoc && activeFecha && activeValorGasto && activeProveedor && activeConcepto && activeFormaPago){
        const gastoIndex = docRef.data().gastos.findIndex(el => el.numDocumento === numDoc );
        const updatedGasto = {
          ...docRef.data().gastos[gastoIndex],
          tipoDocumento: dataGasto.tipoDocumento,
          numDocumento: dataGasto.numDocumento,
          fechaGasto: dataGasto.fechaGasto,
          valor: dataGasto.valor,
          proveedor: dataGasto.proveedor,
          concepto: dataGasto.concepto,
          proyecto: dataGasto.proyecto,
          formaPago: dataGasto.formaPago,
          mesGasto: moment(dataGasto.fechaGasto).format("MMMM"),
          anioGasto: moment(dataGasto.fechaGasto).format("YYYY"),
        }

        //Registrando el nuevo gasto editado
        const updateGastos = [...docRef.data().gastos];
        updateGastos[gastoIndex] = updatedGasto;

        //Actualizando el gasto en Firestore
        showMessageExit("Actualización  exitosa");
        updateDoc(proyectoRef, {gastos: updateGastos});

      } else {
        showMessageError("¡Error!, tiene una casilla sin llenar", "bottom-center");
      }
    
  }


  useEffect(() => {
    getListaGastos(proyecto, numDoc , setDataGasto)
  }, []);


  useEffect(() => {
    getIDDoc("proyectos", proyecto, setIdProyecto)
  }, []);

  useEffect(() => {
    getDataCollectionProveedores(setProveedores);
  }, []);



  return (
    
    <form 
      className={`row g-4 mx-auto mt-2 rounded ${styles.Form}`} 
      onSubmit={ handleSubmit }
      autoComplete="off"
    >

      <p className="text-center mb-0" >{`Gasto con ${dataGasto.tipoDocumento} N° ${ dataGasto.numDocumento }`}  </p>

      <InputFormRegister 
        title='Valor neto del gasto:'
        handleChange={ handlePrecio }
        handleBlur={ (e) =>  validateInput(e, setErrorValorGasto, setActiveValorGasto, 'Ingresar valor del gasto') }
        name="valor"
        value={ dataGasto.valor }
        error={ errorValorGasto }
        col={ 3 }
        margin='-0'
      />

      <div className="m-0 col-md-3">
        <label className={`form-label ${styles.labelForm}`}>Fecha del gasto:</label>
        <input
          onChange={ handleInput }
          onBlur={(e) => validateInput(e, setErrorFecha, setActiveFecha, 'Ingresar fecha del gasto') }
          type="date" 
          className={`form-control ${ styles.inputForm } ${ errorFecha === '' ? "" : styles.errorInput } `} 
          name="fechaGasto"
          value={ dataGasto.fechaGasto }
        />
        <span className={ styles.error }>
          { errorFecha !== "" ? errorFecha : "" }
        </span>
      </div>

      <div className="m-0 col-md-3 m-0">
        <label className={`form-label ${ styles.labelForm }`}>Tipo de Documento:</label>
        <select 
          className={`form-select ${ styles.inputForm } ${ errorTipoDoc === '' ? "" : styles.errorInput } `}  
          onChange={ handleInput }
          onBlur={ (e) => validateInput(e, setErrorTipoDoc, setActiveTipoDoc, 'Seleccione un Tipo de Documento') }
          name="tipoDocumento"
          value={ dataGasto.tipoDocumento }>
          <option value="">Seleccione</option>
          <option value="Boleta">Boleta</option>
          <option value="Boleta de Honorarios">Boleta de Honorarios</option>
          <option value="Boleta Exenta">Boleta Exenta</option>
          <option value="Comprobante">Comprobante</option>
          <option value="Costo de Oficina">Costo de Oficina</option>
          <option value="Costo de Personal">Costo de Personal</option>
          <option value="Factura">Factura</option>
          <option value="Factura exenta">Factura Exenta de IVA</option>
          <option value="Guía de Despacho">Guía de Despacho</option>
          <option value="Nota de Crédito">Nota de Crédito</option>
          <option value="Nota de Débito">Nota de Débito</option>
          <option value="Recibo">Recibo</option>
        </select>
        <span className={ styles.error }>
          { errorTipoDoc !== "" ? errorTipoDoc : "" }
        </span>
      </div>

      {
        dataGasto.tipoDocumento !== 'Costo de Personal' &&
        
        <div className="m-0 col-md-3">
          <label className={`form-label ${styles.labelForm}`}>Proveedor:</label>
          <select
            onChange={ handleInput }
            onBlur={ (e) => validateInput(e, setErrorProveedor, setActiveProveedor, 'Seleccione un Proveedor') }
            className={`form-select ${ styles.inputForm } ${ errorProveedor === '' ? "" : styles.errorInput } `} 
            id="inputProveedorGasto" 
            name="proveedor"
            value={ dataGasto.proveedor }
          >
            <option  value="">Seleccione</option>
              { proveedores.map((el, index) => {
                return(
                  <option value={el.razonSocial} key={index}> {el.razonSocial} </option>
                )
              })}
          </select>
          <span className={ styles.error }>
            { errorProveedor !== "" ? errorProveedor : "" }
          </span>
        </div>

      }
      

      <div className="m-0 col-md-3">
        <label className={`form-label ${styles.labelForm}`}>Concepto:</label>
        <select 
          className={`form-select ${ styles.inputForm } ${ errorConcepto === '' ? "" : styles.errorInput } `}
          onBlur={ (e) => validateInput(e, setErrorConcepto, setActiveConcepto, 'Seleccione un Concepto') } 
          onChange={ handleInput } 
          name="concepto"
          value={ dataGasto.concepto }>
          <option value="">Seleccione</option>
          <option value="Arriendos">Arriendos</option>
          <option value="Combustible">Combustible</option>
          <option value="Contratistas">Contratistas</option>
          <option value="EBG Casa Matriz">EBG Casa Matriz</option>
          <option value="EPPs">EPPs</option>
          <option value="Equipos">Equipos</option>
          <option value="Exámenes médicos">Exámenes Médicos</option>
          <option value="Finiquito">Finiquito</option>
          <option value="Fletes">Fletes</option>
          <option value="Mano de Obra">Mano de Obra</option>
          <option value="Mantenimientos">Mantenimientos</option>
          <option value="Materiales y Herramientas">Materiales y Herramientas</option>
          <option value="Multas">Multas</option>
          <option value="Otros">Otros</option>
          <option value="Patentes">Patentes</option>
          <option value="Seguros">Seguros y/o garantías</option>
          <option value="Servicios">Servicios</option>
          <option value="Utiles de aseo">Útiles de aseo</option>
          <option value="Utiles de escritorio">Útiles de escritorio</option>
          <option value="Vehículos">Vehículos(TAG/Mantenc./Leasing)</option>
          <option value="Viáticos">Viáticos</option>
        </select>
        <span className={ styles.error }>
          { errorConcepto !== "" ? errorConcepto : "" }
        </span>
      </div>

      <div className="m-0 col-md-3">
          <label className={`form-label ${styles.labelForm} `}>N° de Documento:</label>
          <input
            onChange={ handleInput }
            type="text"
            className={`form-control ${ styles.inputForm } `} 
            name="numDocumento"
            value={ dataGasto.numDocumento } 
            readOnly
            />
            <span className={ styles.error }>Este dato no puede ser editado</span>
      </div>

      

      <div className="m-0 col-md-3">
        <label className={`form-label ${styles.labelForm}`}>Proyecto:</label>
        <input 
          className={`form-control ${ styles.inputForm } `} 
          onChange={ handleInput } 
          name="proyecto"
          value={ dataGasto.proyecto }
          type='text'
          readOnly>
        </input>
        <span className={ styles.error }>Este dato no puede ser editado</span>
      </div>

      <div className="m-0 col-md-3">
        <label className={` form-label ${styles.labelForm} `}>Forma de pago:</label>
        <input 
          className={`form-control ${ styles.inputForm } ${ errorFormaPago === '' ? "" : styles.errorInput } `} 
          name="formaPago"
          value={ dataGasto.formaPago }
          readOnly
          type='text'>
        </input>
        <span className={ styles.error }>Este dato no puede ser editado</span>
      </div>

      <div className="m-0 col-md-3">
        <label className={`form-label ${styles.labelForm }`}>Fecha de pago:</label>
        <input
          className={`form-control ${ styles.inputForm } ${styles.labelForm} `}
          type="date" 
          name="fechaPago"
          value={ dataGasto.fechaPago }
          readOnly/>
        <span className={ styles.error }>Este dato no puede ser editado</span>
      </div>

      <BtnNavigateDetail handleClick={ () => navigate(-1) }  />

      <ToastContainer />
    </form>
    
  )
}
