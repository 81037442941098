
import { SpinnerCard } from "../helpers/spinner/SpinnerCard";
import  styles from "./CardImportes.module.css";

export default function CardImportes( {
    importe, 
    nombre, 
    iva , 
    asunto, 
    display,
    icon = '',
    colorImporte,
    utilidad,
    isLoading = true }) {


    return (<>
        <div className={ `card px-0 pb-2 mb-3 mb-lg-5 mb-xl-0 col-12 col-lg-2  col-xl-4 ${ styles.Card } ` } >

            <div className={ `card-header  text-start  fs-6 d-flex justify-content-between align-items-center ${ styles.cardHeader }` } >
                <p className="m-0" > { nombre } </p>

                { icon !== '' && <img className={ ` ${ styles.icon } ` } alt="$" src={ icon } /> }

                { (utilidad  && icon === '' ) && <i className={ `bi bi-graph-up-arrow ${ styles.colorGanancia }` }></i> }

                { (!utilidad  && icon === '' ) && <i className={ `bi bi-graph-down-arrow ${ styles.colorPerdida } ` }></i> }
            </div>

            {
                isLoading
                ?
                    <div className={ `w-100 h-100 d-flex justify-content-center align-items-center ${ styles.wrapperLoading }` } >
                        <SpinnerCard />
                    </div>
                :
                    <>
                        <div className=" mx-3 my-2 d-flex justify-content-between align-items-center">
                            <p className={ `card-title fs-5 fw-bold ${ colorImporte }` } >$ {importe}</p>
                        </div>

                        <div className={`${ display } w-100 ${ styles.fuente_iva }`}  >
                            <p className='m-0 ms-3 me-3 ' >{asunto}</p>
                            <p className='m-0' >$ {iva}</p>
                        </div>
                    </>
            }
        </div>
    </>)
}
