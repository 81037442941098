import { Link } from "react-router-dom"
import logo from "../../../../assets/images/ebg-logo-navbar.png"
import styles from "./Logo.module.css"

export default function Logo() {
    return (<>
        <div className= {styles.logo}>
            <Link to="/home" className="w-100 mx-auto d-flex justify-content-center">
                <img className="w-75 h-100 mx-auto" src= { logo } alt="logotipo EBG"/>
            </Link>
        </div>
    </>)
}
