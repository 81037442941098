
import { TablaRegistroProveedores } from "../../pure/form/TablaRegistroProveedores";
import styles from './Registros.module.css';


export default function RegistroProveedores() {

    return (
        <div className={ styles.WrapperContenedor } >
            <p className="h5 fw-bold align-self-start" >Registros de Proveedores</p>
            <hr className="border border-dark border-1  w-100 m-1"></hr>

            <div className={`${ styles.wrapperTable }`} >
                <TablaRegistroProveedores />
            </div>
        </div>
    )
}
