import styles from '../Form.module.css';

export const InputFormRegister = ( { 
          title, 
          handleChange,
          handleBlur,
          name,
          value,
          error,
          col = 4,
          placeholder = '',
          firstSymbol='$',
          secondSymbol='.00',
          margin='',
          type='text',
        } 
  ) => {


  return (
    <div className={ `col-md-${col} m${ margin }` }>
        <label className={` form-label ${ styles.labelForm } `}>{ title }</label>
        <div className={`input-group`}>
          {
            firstSymbol === '$' &&

            <span className={`input-group-text ${ styles.bgHeadModal }`}>$</span> 

          }
          <input
            onChange={ handleChange }
            onBlur={ handleBlur }
            type={ type }
            className={`form-control ${ styles.inputForm }  ${ error === '' ? "" : styles.errorInput }`} 
            name={ name }
            value={ value }
            placeholder={ placeholder }
            autoComplete="off"
          />
            {
              placeholder === '' &&

              <span className={`input-group-text ${ styles.bgHeadModal }`}> { secondSymbol } </span>
            }
          
        </div>
          <span className={ styles.error }>
            { error !== "" ? error : "" }
          </span>
      </div>
  )
}
