import {useState, useEffect} from 'react';
import { APIRegiones } from './APIRegiones';
import { formatNumDoc } from '../../../helpers/formatNumDoc';
import { validateInput } from '../../../helpers/validaciones';
import { showMessageError, showMessageExit } from '../../../helpers/ShowMessage';
import { ToastContainer } from 'react-toastify';
import { BtnsFormRegister, InputFormRegister } from './components';
import moment from 'moment';
import getDataCollection from '../../../helpers/getDataCollection';
import styles from "./Form.module.css";


export default function FormRegistroProyectos( { setData } ) {

    const valoresIniciales = {
        nombre: "",
        presupuesto: "",
        cliente: "",
        fechaInicio: "",
        fechaTermino: "",
        direccion: "",
        comuna: "",
        region:"",
        nDoc:"",
        mesInicio: "",
        anioInicio: "",
        fechaRegistro: "",
        descripcion: "",
        estado: "Ejecución",
        area:"",
        ventas:[],
        gastos:[],
        produccion: "",
        costoManoObraInicial:"",
        costoContratistasInicial:"",
        costosIndirectosInicial:"",
        costosMaterialesInicial:"",
        costoManoObraProyectado:"",
        costoContratistasProyectado:"",
        costosIndirectosProyectado:"",
        costosMaterialesProyectado:"",
    }

    const [valores, setValores] = useState(valoresIniciales);
    const [fechaRegistro, setFechaRegistro] = useState("");


    // ALmacenando los  datos de Firebase (proveedores)
    const [ clientes, setClientes ] = useState([]);
    const [ activeNombreProyecto, setActiveNombreProyecto ] = useState(false);
    const [ errorNombreProyecto, setErrorNombreProyecto ] = useState("");
    const [ activePresupuesto, setActivePresupuesto ] = useState(false);
    const [ errorPresupuesto, setErrorPresupuesto ] = useState("");
    const [ activeMaterialesInic, setActiveMaterialesInic ] = useState(false);
    const [ errorMaterialesInic, setErrorMaterialesInic ] = useState("");
    const [ activeManoObraInic, setACtiveManoObraInic ] = useState(false);
    const [ errorManoObraInic, setErrorManoObraInic ] = useState("");
    const [ activeIndirectosInic, setActiveIndirectosInic ] = useState(false);
    const [ errorIndirectosInic, setErrorIndirectosInic ] = useState("");
    const [ activeCliente, setActiveCliente ] = useState(false);
    const [ errorCliente, setErrorCliente ] = useState("");
    const [ activeFechaInicio, setActiveFechaInicio ] = useState(false);
    const [ errorFechaInicio, setErrorFechaInicio ] = useState("");
    const [ activeFechaTermino, setActiveFechaTermino ] = useState(false);
    const [ errorFechaTermino, setErrorFechaTermino ] = useState("");
    const [ activeAreaProyecto, setActiveAreaProyecto ] = useState(false);
    const [ errorAreaProyecto, setErrorAreaProyecto ] = useState("");
    const [ activeDireccion, setActiveDireccion ] = useState(false);
    const [ errorDireccion, setErrorDireccion ] = useState("");
    const [ activeDescripcion, setActiveDescripcion ] = useState(false);
    const [ errorDescripcion, setErrorDescripcion ] = useState("");
    const [ activeRegion, setActiveRegion ] = useState(false);
    const [ errorRegion, setErrorRegion ] = useState("");
    const [ activeComuna, setActiveComuna ] = useState(false);
    const [ errorComuna, setErrorComuna ] = useState("");
    

    //Captura de los valores del formulario
    const handleInput = (e) => {
        const {name, value} = e.target;
        setValores({...valores, [name]:value.toUpperCase() });
    }

    const handleInputAPI = (e) => {
        const {name, value} = e.target;
        setValores({ ...valores, [name]:value });
    }

    const handleInputNumber = (e) => {
        const {name, value} = e.target;
        const formatValue = formatNumDoc(value);
        setValores({ ...valores, [name]:formatValue });
    }

    //Limpia los inputs
    const cleanInputs = () => {
        setErrorNombreProyecto('');
        setErrorPresupuesto('');
        setErrorMaterialesInic('');
        setErrorManoObraInic('');
        setErrorIndirectosInic('');
        setErrorCliente('');
        setErrorFechaInicio('');
        setErrorFechaTermino('');
        setErrorAreaProyecto('');
        setErrorDireccion('');
        setErrorComuna('');
        setErrorRegion('');
        setErrorDescripcion('');
        setActiveNombreProyecto(false);
        setActivePresupuesto(false);
        setActiveMaterialesInic(false);
        setACtiveManoObraInic(false);
        setActiveIndirectosInic(false);
        setActiveCliente(false);
        setActiveFechaInicio(false);
        setActiveFechaTermino(false);
        setActiveAreaProyecto(false);
        setActiveDireccion(false);
        setActiveDescripcion(false);
        setActiveRegion(false);
        setActiveComuna(false);
        setValores(valoresIniciales);
    }

    //Enviando los datos del formulario
    const handleSubmit = (e) => {
        e.preventDefault();

        //Se capturan el mes y año de la fecha de la venta
        const mes = moment(valores.fechaInicio).format("MMMM");
        const anio = moment(valores.fechaInicio).format("YYYY");
        const mesTermino = moment(valores.fechaTermino).format("MMMM");
        const anioTermino = moment(valores.fechaTermino).format("YYYY");

        if( activeNombreProyecto &&
            activePresupuesto &&
            activeMaterialesInic &&
            activeManoObraInic && 
            activeIndirectosInic &&
            activeCliente &&
            activeFechaInicio &&
            activeFechaTermino &&
            activeAreaProyecto &&
            activeDireccion &&
            activeRegion &&
            activeComuna &&
            activeDescripcion){

                showMessageExit("Registro exitoso");
                setData({...valores, 
                    fechaRegistro: fechaRegistro, 
                    mesInicio: mes, 
                    anioInicio: anio, 
                    mesTermino: mesTermino,
                    anioTermino: anioTermino,
                });
                cleanInputs();

        } else {
            !activeNombreProyecto && setErrorNombreProyecto('Ingrese un Nombre de Proyecto');
            !activePresupuesto && setErrorPresupuesto('Ingrese Presupuesto de Proyecto');
            !activeMaterialesInic && setErrorMaterialesInic('Ingrese Costo de Materiales');
            !activeManoObraInic && setErrorManoObraInic('Ingrese Costo Mano de Obra');
            !activeIndirectosInic && setErrorIndirectosInic('Ingrese Costo Indirecto');
            !activeCliente && setErrorCliente('Seleccione un Cliente');
            !activeFechaInicio && setErrorFechaInicio('Ingrese una Fecha');
            !activeFechaTermino && setErrorFechaTermino('Ingrese una Fecha');
            !activeAreaProyecto && setErrorAreaProyecto('Seleccione un Area');
            !activeDireccion && setErrorDireccion('Ingrese la Dirección');
            !activeRegion && setErrorRegion('Seleccione una Región');
            !activeComuna && setErrorComuna('Seleccione una Comuna');
            !activeDescripcion && setErrorDescripcion('Ingrese un Descripción');
            showMessageError("¡Error!, tiene una casilla sin llenar", "bottom-center");
        }
    }

    //Capturando la fecha del registro
    const handleClick = () => {
        setFechaRegistro(moment().format('YYYY-MM-DD HH:mm:ss'));
    }

    // Mostrando los datos en la interfaz
    useEffect(() => {
        getDataCollection("clientes",setClientes)
    }, []);


    return (<>
        <form 
            className="row g-4 needs-validation" 
            onSubmit={ handleSubmit }
            autoComplete="off"
        >

            <InputFormRegister 
                title='Nombre del Proyecto:'
                handleChange={ handleInput }
                handleBlur={ (e) => validateInput(e, setErrorNombreProyecto, setActiveNombreProyecto, 'Ingrese un Nombre de Proyecto') }
                name="nombre"
                value={ valores.nombre }
                error={ errorNombreProyecto }
                placeholder= 'Ejemplo: ENEL PISO 12'
                firstSymbol=''
            />

            <InputFormRegister 
                title='Presupuesto del Proyecto:'
                handleChange={ handleInputNumber }
                handleBlur={(e) => validateInput(e, setErrorPresupuesto, setActivePresupuesto, 'Ingrese el Presupuesto del Proyecto') }
                name="presupuesto"
                value={ valores.presupuesto }
                error={ errorPresupuesto }
            />

            <InputFormRegister 
                title='Costos Materiales Iniciales:'
                handleChange={ handleInputNumber }
                handleBlur={ (e) => validateInput(e, setErrorMaterialesInic, setActiveMaterialesInic, 'Ingrese Costo de Materiales') }
                name="costosMaterialesInicial"
                value={ valores.costosMaterialesInicial }
                error={ errorMaterialesInic }
            />

            <InputFormRegister 
                title='Costos Mano de Obra Iniciales:'
                handleChange={ handleInputNumber }
                handleBlur={ (e) => validateInput(e, setErrorManoObraInic, setACtiveManoObraInic, 'Ingrese Costo de Mano de Obra') }
                name="costoManoObraInicial"
                value={ valores.costoManoObraInicial }
                error={ errorManoObraInic }
            />

            <div className="col-md-4">
                <label className={` form-label ${ styles.labelForm } `}>Costos Contratistas Iniciales:</label>
                <div className="input-group">
                    <span className={`input-group-text ${ styles.bgHeadModal }`}>$</span>
                    <input
                        onChange={ handleInputNumber }
                        type="text" 
                        className={ `form-control ${ styles.inputForm }` } 
                        name="costoContratistasInicial"
                        value={ valores.costoContratistasInicial }
                    />
                    <span className={`input-group-text ${ styles.bgHeadModal }`}>.00</span>
                </div>
            </div>

            <InputFormRegister 
                title='Costos Indirectos Iniciales:'
                handleChange={ handleInputNumber }
                handleBlur={(e) => validateInput(e, setErrorIndirectosInic, setActiveIndirectosInic, 'Ingrese Costos Indirectos') }
                name="costosIndirectosInicial"
                value={ valores.costosIndirectosInicial }
                error={ errorIndirectosInic }
            />


            <div className="col-md-4">
                <label className={ `form-label ${ styles.labelForm }` }>Cliente:</label>
                <select
                    onChange={ handleInput }
                    onBlur={ (e) => validateInput(e, setErrorCliente, setActiveCliente, 'Seleccione un Cliente') }
                    className={`form-select ${ styles.inputForm } ${ errorCliente === '' ? "" : styles.errorInput }`}  
                    name="cliente"
                    value={valores.cliente}>
                    <option  value="">SELECCIONE</option>
                        { clientes.map((el, index) => {
                        return(
                            <option value={el.razonSocial} key={index}> {el.razonSocial} </option>
                        )
                        })}
                </select>
                <span className={ styles.error }>
                    { errorCliente !== "" ? errorCliente : "" }
                </span>
            </div>

            <div className="col-md-4">
                <label className={` form-label ${ styles.labelForm } `}>Fecha de inicio:</label>
                <input
                    onChange={ handleInput }
                    onBlur={ (e) => validateInput(e, setErrorFechaInicio, setActiveFechaInicio, 'Ingrese Fecha de Inicio') }
                    type="date" 
                    className={`form-control ${ styles.inputForm } ${ errorFechaInicio === '' ? "" : styles.errorInput }`} 
                    name="fechaInicio"
                    value={ valores.fechaInicio }
                />
                <span className={ styles.error }>
                    { errorFechaInicio !== "" ? errorFechaInicio : "" }
                </span>
            </div>

            <div className="col-md-4">
                <label className={` form-label ${ styles.labelForm } `}>Fecha de término:</label>
                <input
                    onChange={ handleInput }
                    onBlur={ (e) => validateInput(e, setErrorFechaTermino, setActiveFechaTermino, 'Ingrese Fecha de Término') }
                    className={`form-control ${ styles.inputForm } ${ errorFechaTermino === '' ? "" : styles.errorInput }`}
                    type="date" 
                    name="fechaTermino"
                    value={ valores.fechaTermino }
                />
                <span className={ styles.error }>
                    { errorFechaTermino !== "" ? errorFechaTermino : "" }
                </span>
            </div>

            <div className="col-md-4">
                <label className={` form-label ${ styles.labelForm } `}>Área del proyecto</label>
                <select
                    onChange={ handleInput }
                    onBlur={ (e) => validateInput(e, setErrorAreaProyecto, setActiveAreaProyecto, 'Seleccione un Area de Proyecto') }
                    className={`form-select ${ styles.inputForm } ${ errorAreaProyecto === '' ? "" : styles.errorInput }`} 
                    name="area"
                    value={valores.area}
                >
                    <option value="">SELECCIONE</option>
                    <option value="OOCC">OOCC</option>
                    <option value="REMODELACIONES">REMODELACIONES</option>
                    <option value="LICITACIONES">LICITACIONES</option>
                </select>
                <span className={ styles.error }>
                    { errorAreaProyecto !== "" ? errorAreaProyecto : "" }
                </span>
            </div>


{/* //todo       ****  Datos  de  ubicación  **** */}
            <InputFormRegister 
                title='Dirección'
                handleChange={ handleInput }
                handleBlur={ (e) => validateInput(e, setErrorDireccion, setActiveDireccion, 'Ingrese una Dirección') }
                name="direccion"
                value={ valores.direccion }
                error={ errorDireccion }
                placeholder= 'Ejemplo: Av. El Parrón 345'
                firstSymbol=''
            />

            <APIRegiones 
                handleInput={ handleInputAPI }
                valores={ valores }
                activeRegion={ activeRegion }
                activeComuna={ activeComuna }
                errorRegion={ errorRegion }
                errorComuna={ errorComuna }
                setActiveRegion={ setActiveRegion }
                setActiveComuna={ setActiveComuna }
                setErrorRegion={ setErrorRegion }
                setErrorComuna={ setErrorComuna }
                label={` form-label ${ styles.labelForm } `}
                select={`form-select ${ styles.inputForm }`} 
            />

            <div className="col-md-10 mx-auto">
                <label className={` form-label ${ styles.labelForm } `}>Descripción:</label>
                <textarea 
                    onChange={ handleInput }
                    onBlur={ (e) => validateInput(e, setErrorDescripcion, setActiveDescripcion, 'Ingrese una descripción') }
                    className={`form-control ${ styles.inputForm } ${ errorDescripcion === '' ? "" : styles.errorInput }`}  
                    placeholder="Ingrese una descripción aquí" 
                    name="descripcion"
                    value={valores.descripcion}
                />
                <span className={ styles.error }>
                    { errorDescripcion !== "" ? errorDescripcion : "" }
                </span>
            </div>

            <BtnsFormRegister 
                cleanInputs={ cleanInputs }
                handleClick={ handleClick }
            />
            <ToastContainer />
        </form>
    </>)
}
