import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { showMessageError, showMessageExit } from '../../../helpers/ShowMessage';
import { APIRegiones } from "../../pure/form/APIRegiones";
import { formatNumDoc } from "../../../helpers/formatNumDoc";
import { validateInput } from "../../../helpers/validaciones";
import { InputFormRegister } from "../../pure/form/components";
import { BtnNavigateDetail } from "../../pure/form/BtnNavigateDetail";
import 'react-toastify/dist/ReactToastify.css';
import db from "../../../backend/DBFiresbase";
import getDataCollection from "../../../helpers/getDataCollection";
import moment from "moment";
import styles from "./DetailStyles.module.css";



export const ProyectosDetail = () => {

  const { id } = useParams();

  const navigate = useNavigate();

  const [ dataProyecto, setDataProyecto ] = useState({
    nombre: "",
    presupuesto: "",
    cliente: "",
    fechaInicio: "",
    fechaTermino: "",
    direccion: "",
    comuna: "",
    region:"",
    nDoc:"",
    tipoDoc:"",
    mesInicio: "",
    mesTermino: "",
    anioInicio: "",
    anioTermino: "",
    fechaRegistro: "",
    descripcion: "",
    estado: "",
    area:"",
    produccion: "",
    costoManoObraInicial:"",
    costoContratistasInicial:"",
    costosIndirectosInicial:"",
    costosMaterialesInicial:"",
    costoManoObraProyectado:"",
    costoContratistasProyectado:"",
    costosIndirectosProyectado:"",
    costosMaterialesProyectado:"",
  });

  const [ clientes, setClientes ] = useState([]);
  const [ activePresupuesto, setActivePresupuesto ] = useState(true);
  const [ errorPresupuesto, setErrorPresupuesto ] = useState("");
  const [ activeMaterialesInic, setActiveMaterialesInic ] = useState(true);
  const [ errorMaterialesInic, setErrorMaterialesInic ] = useState("");
  const [ activeManoObraInic, setACtiveManoObraInic ] = useState(true);
  const [ errorManoObraInic, setErrorManoObraInic ] = useState("");
  const [ activeIndirectosInic, setActiveIndirectosInic ] = useState(true);
  const [ errorIndirectosInic, setErrorIndirectosInic ] = useState("");
  const [ activeCliente, setActiveCliente ] = useState(true);
  const [ errorCliente, setErrorCliente ] = useState("");
  const [ activeFechaInicio, setActiveFechaInicio ] = useState(true);
  const [ errorFechaInicio, setErrorFechaInicio ] = useState("");
  const [ activeFechaTermino, setActiveFechaTermino ] = useState(true);
  const [ errorFechaTermino, setErrorFechaTermino ] = useState("");
  const [ activeAreaProyecto, setActiveAreaProyecto ] = useState(true);
  const [ errorAreaProyecto, setErrorAreaProyecto ] = useState("");
  const [ activeDireccion, setActiveDireccion ] = useState(true);
  const [ errorDireccion, setErrorDireccion ] = useState("");
  const [ activeDescripcion, setActiveDescripcion ] = useState(true);
  const [ errorDescripcion, setErrorDescripcion ] = useState("");
  const [ activeRegion, setActiveRegion ] = useState(true);
  const [ errorRegion, setErrorRegion ] = useState("");
  const [ activeComuna, setActiveComuna ] = useState(true);
  const [ errorComuna, setErrorComuna ] = useState("");
  const [ activeEstadoProyecto, setActiveEstadoProyecto ] = useState(true);
  const [ errorEstadoProyecto, setErrorEstadoProyecto ] = useState("");



  // Mostrando los datos en la interfaz
  useEffect(() => {
    getDataCollection("clientes",setClientes)
  }, []);

  //Obteniendo el valor del input
  const handleInput = (e) => {
    const {name, value} = e.target;
    setDataProyecto({...dataProyecto, [name]:value.toUpperCase()});
  }

  const handleInputLower = (e) => {
    const {name, value} = e.target;
    setDataProyecto({...dataProyecto, [name]:value});
  }

  const handleInputNumber = (e) => {
    const {name, value} = e.target;
    const formatValue = formatNumDoc(value);
    setDataProyecto({ ...dataProyecto, [name]:formatValue });
}

  const getDataProyectoDB = async () => {
    const docRef = doc(db, "proyectos", id);
    const docSnap = await getDoc(docRef);

    try {
      if (docSnap.exists()) {
        setDataProyecto(docSnap.data())
      }
    } catch (error) {
      console.error(error)
    }
  }

    //Envío de formulario
    const handleSubmit = (e) => {
      e.preventDefault();

      if( activePresupuesto &&
        activeMaterialesInic &&
        activeManoObraInic && 
        activeIndirectosInic &&
        activeCliente &&
        activeFechaInicio &&
        activeFechaTermino &&
        activeAreaProyecto &&
        activeDireccion &&
        activeRegion &&
        activeComuna &&
        activeDescripcion &&
        activeEstadoProyecto){
          showMessageExit("¡Proyecto actualizado con éxito!");

          const proyectoActualizado = doc(db, "proyectos", id);

          const updateProyecto = {
            ...dataProyecto,
            mesTermino: moment(dataProyecto.fechaTermino).format("MMMM"),
            anioTermino: moment(dataProyecto.fechaTermino).format("YYYY"),
            fechaTermino: dataProyecto.fechaTermino,
            fechaInicio: dataProyecto.fechaInicio,
            mesInicio: moment(dataProyecto.fechaInicio).format("MMMM"),
            anioInicio: moment(dataProyecto.fechaInicio).format("YYYY")
          }

          updateDoc(proyectoActualizado, updateProyecto );
        } else {
          showMessageError("¡Error!, tiene una casilla sin llenar", "bottom-center");
        }
      
  }

  useEffect(() => {
    getDataProyectoDB();
  }, []);

  return (<>
    <form 
      className={`row g-4 mx-auto mt-2 rounded ${styles.Form}`}  
      onSubmit={handleSubmit}
      autoComplete="off">

      <p className="text-center mb-3" > {dataProyecto.nombre} </p>

      <div className="mt-0 mb-3 col-md-3">
        <label className={`form-label ${styles.labelForm}`}>Nombre del proyecto:</label>
        <input 
          onChange={ handleInput }
          type="text"
          className={`form-control ${ styles.inputForm }`} 
          name="nombre"
          value={ dataProyecto.nombre } 
          readOnly
        />
        <span className={ styles.error }>Este dato no puede ser editado</span>
      </div>

      <div className="mt-0 mb-3 col-md-3">
        <label className={`form-label ${styles.labelForm}`} >Estado del Proyecto</label>
        <select
          onChange={ handleInputLower }
          onBlur={ (e) => validateInput(e, setErrorEstadoProyecto, setActiveEstadoProyecto, 'Ingrese Estado de Proyecto') }
          className={`form-select ${ styles.inputForm } ${ errorEstadoProyecto === '' ? "" : styles.errorInput } `}
          name='estado'
          value={ dataProyecto.estado }>
          <option value="">Seleccione</option>
          <option value="Ejecución">Ejecución</option>
          <option value="Finalizado">Finalizado</option>
        </select>
        <span className={ styles.error }>
          { errorEstadoProyecto !== "" ? errorEstadoProyecto : "" }
        </span>
      </div>

      <InputFormRegister 
        title='Presupuesto del proyecto:'
        handleChange={ handleInputNumber }
        handleBlur={ (e) => validateInput(e, setErrorPresupuesto, setActivePresupuesto, 'Ingrese Presupuesto de Proyecto') }
        name="presupuesto"
        value={ dataProyecto.presupuesto }
        error={ errorPresupuesto }
        col={ 3 }
        margin='b-1 my-0'
      />

      <InputFormRegister 
        title='Costos Materiales Iniciales:'
        handleChange={ handleInputNumber }
        handleBlur={ (e) => validateInput(e, setErrorMaterialesInic, setActiveMaterialesInic, 'Ingrese Costo de Materiales') }
        name="costosMaterialesInicial"
        value={ dataProyecto.costosMaterialesInicial }
        error={ errorMaterialesInic }
        col={ 3 }
        margin='b-1 my-0'
      />

      <InputFormRegister 
        title='Costos Mano de Obra Iniciales:'
        handleChange={ handleInputNumber }
        handleBlur={ (e) => validateInput(e, setErrorManoObraInic, setACtiveManoObraInic, 'Ingrese Costo Mano de Obra') }
        name="costoManoObraInicial"
        value={ dataProyecto.costoManoObraInicial }
        error={ errorManoObraInic }
        col={ 3 }
        margin='b-1 my-0'
      />

      <div className="mt-0 mb-3 col-md-3">
          <label className={`form-label ${styles.labelForm}`}>Costos Contratistas Iniciales:</label>
          <div className={`input-group`}>
              <span className={`input-group-text ${ styles.bgHeadModal }`}>$</span>
              <input
                onChange={ handleInputNumber }
                type="text" 
                className={`form-control ${ styles.inputForm }`} 
                name="costoContratistasInicial"
                value={dataProyecto.costoContratistasInicial}
              />
              <span className={`input-group-text ${ styles.bgHeadModal }`}>.00</span>
          </div>
      </div>

      <InputFormRegister 
        title='Costos Indirectos Iniciales:'
        handleChange={ handleInputNumber }
        handleBlur={ (e) => validateInput(e, setErrorIndirectosInic, setActiveIndirectosInic, 'Ingrese Costo Indirecto') }
        name="costosIndirectosInicial"
        value={ dataProyecto.costosIndirectosInicial }
        error={ errorIndirectosInic }
        col={ 3 }
        margin='b-1 my-0'
      />

      <div className="mt-0 mb-3 col-md-3">
          <label className={`form-label ${styles.labelForm}`}>Costos Materiales Proy:</label>
          <div className={`input-group`}>
              <span className={`input-group-text ${ styles.bgHeadModal } `}>$</span>
              <input
                onChange={ handleInputNumber }
                type="text" 
                className={`form-control ${ styles.inputForm }`} 
                name="costosMaterialesProyectado"
                value={ dataProyecto.costosMaterialesProyectado }
              />
              <span className={`input-group-text ${ styles.bgHeadModal }`}>.00</span>
          </div>
      </div>

      <div className="mt-0 mb-3 col-md-3">
        <label className={`form-label ${styles.labelForm}`}>Costos Mano de Obra Proy:</label>
        <div className={`input-group`}>
          <span className={`input-group-text ${ styles.bgHeadModal }`}>$</span>
          <input
            onChange={ handleInputNumber }
            type="text" 
            className={`form-control ${ styles.inputForm } `} 
            name="costoManoObraProyectado"
            value={ dataProyecto.costoManoObraProyectado }
          />
          <span className={`input-group-text ${ styles.bgHeadModal }`}>.00</span>
        </div>
      </div>

      <div className="mt-0 mb-3 col-md-3">
        <label className={`form-label ${styles.labelForm}`}>Costos Contratistas Proy:</label>
        <div className={`input-group `}>
          <span className={`input-group-text ${ styles.bgHeadModal }`}>$</span>
          <input
            onChange={ handleInputNumber }
            type="text" 
            className={`form-control ${ styles.inputForm }`} 
            name="costoContratistasProyectado"
            value={dataProyecto.costoContratistasProyectado}
          />
          <span className={`input-group-text ${ styles.bgHeadModal }`}>.00</span>
        </div>
      </div>

      <div className="mt-0 mb-3 col-md-3">
        <label className={`form-label ${styles.labelForm}`}>Costos Indirectos Proy:</label>
        <div className={`input-group`}>
          <span className={`input-group-text ${ styles.bgHeadModal }`}>$</span>
          <input
            onChange={ handleInputNumber }
            type="text" 
            className={`form-control ${ styles.inputForm }`} 
            name="costosIndirectosProyectado"
            value={dataProyecto.costosIndirectosProyectado}
          />
          <span className={`input-group-text ${ styles.bgHeadModal }`}>.00</span>
        </div>
      </div>


      <div className="mt-0 mb-3 col-md-3">
        <label className={`form-label ${styles.labelForm}`}>Cliente:</label>
        <select
            onChange={ handleInput }
            onBlur={ (e) => validateInput(e, setErrorCliente, setActiveCliente, 'Seleccione un Cliente') }
            className={`form-select ${ styles.inputForm } ${ errorCliente === '' ? "" : styles.errorInput } `} 
            name="cliente"
            value={ dataProyecto.cliente }>
            <option  value="">Seleccione</option>
              { clientes.map((el, index) => {
              return(
                <option value={el.razonSocial} key={index}> {el.razonSocial} </option>
              )
              })}
        </select>
        <span className={ styles.error }>
          { errorCliente !== "" ? errorCliente : "" }
        </span>
      </div>

      <div className={`mt-0 mb-3 col-md-3`}>
          <label className={`form-label ${styles.labelForm}`}>Fecha de inicio:</label>
          <input
            onChange={ handleInput }
            onBlur={ (e) => validateInput(e, setErrorFechaInicio, setActiveFechaInicio, 'Ingrese una Fecha') }
            type="date" 
            className={`form-control ${ styles.inputForm } ${ errorFechaInicio === '' ? "" : styles.errorInput } `}  
            name="fechaInicio"
            value={dataProyecto.fechaInicio}
          />
          <span className={ styles.error }>
            { errorFechaInicio !== "" ? errorFechaInicio : "" }
          </span>
      </div>

      <div className={`mt-0 mb-3 col-md-3`}>
        <label className={`form-label ${styles.labelForm}`}>Fecha de término:</label>
        <input
          onChange={ handleInput }
          onBlur={ (e) => validateInput(e, setErrorFechaTermino, setActiveFechaTermino, 'Ingrese una Fecha') }
          type="date" 
          className={`form-control ${ styles.inputForm } ${ errorFechaTermino === '' ? "" : styles.errorInput } `} 
          name="fechaTermino"
          value={dataProyecto.fechaTermino}
        />
        <span className={ styles.error }>
          { errorFechaTermino !== "" ? errorFechaTermino : "" }
        </span>
      </div>

      <div className="mt-0 mb-3 col-md-3">
        <label className={`form-label ${styles.labelForm}`}>Área del proyecto:</label>
        <select
          onChange={ handleInput }
          onBlur={ (e) => validateInput(e, setErrorAreaProyecto, setActiveAreaProyecto, 'Seleccione un Area') }
          className={`form-select ${ styles.inputForm } ${ errorAreaProyecto === '' ? "" : styles.errorInput } `}
          name="area"
          value={ dataProyecto.area }
        >
          <option value="">Seleccione</option>
          <option value="OOCC">OOCC</option>
          <option value="REMODELACIONES">REMODELACIONES</option>
          <option value="LICITACIONES">LICITACIONES</option>
          <option value="MUEBLERIA">MUEBLERIA</option>
        </select>
        <span className={ styles.error }>
          { errorAreaProyecto !== "" ? errorAreaProyecto : "" }
        </span>
      </div>


{/* //todo       ****  Datos  de  ubicación  **** */}
      <div className="mt-0 mb-3 col-md-3">
        <label className={`form-label ${styles.labelForm}`}>Dirección:</label>
        <input
          onChange={ handleInput }
          onBlur={ (e) => validateInput(e, setErrorDireccion, setActiveDireccion, 'Ingrese la Dirección') }
          name="direccion"
          value={ dataProyecto.direccion }
          type="text" 
          className={`form-control ${ styles.inputForm } ${ errorDireccion === '' ? "" : styles.errorInput } `} 
          placeholder='Ejemplo: Av. El Parrón 345' />
        <span className={ styles.error }>
          { errorDireccion !== "" ? errorDireccion : "" }
        </span>
      </div>

      <APIRegiones 
        handleInput={ handleInputLower }
        valores={ dataProyecto }
        col={ `col-3 m-0` }
        select={`form-select ${ styles.inputForm }`}
        label={`form-label ${styles.labelForm}`}
        activeRegion={ activeRegion }
        activeComuna={ activeComuna }
        errorRegion={ errorRegion }
        errorComuna={ errorComuna }
        setActiveRegion={ setActiveRegion }
        setActiveComuna={ setActiveComuna }
        setErrorRegion={ setErrorRegion }
        setErrorComuna={ setErrorComuna }
      />

      <div className="mt-2 mb-4 col-md-8 mx-auto">
        <label className={`form-label ${styles.label} m-0`}>Descripción:</label>
        <textarea 
          onChange={ handleInput }
          onBlur={ (e) => validateInput(e, setErrorDescripcion, setActiveDescripcion, 'Ingrese un Descripción') }
          className={`form-control ${ styles.inputForm } ${ errorDescripcion === '' ? "" : styles.errorInput } `} 
          placeholder="Ingrese una descripción aquí" 
          name="descripcion"
          value={dataProyecto.descripcion}
        />
        <span className={ styles.error }>
          { errorDescripcion !== "" ? errorDescripcion : "" }
        </span>
      </div>

      <BtnNavigateDetail handleClick={ () => navigate(-1) }  />

      <ToastContainer/>
    </form>
  </>)
}
