import styles from "./Form.module.css";

export const CardDataForm = ( { title, data, loading } ) => {
  return (
    <div className={ `rounded-2 d-flex flex-column justify-content-center align-items-center py-1 ${styles.cardGastosTotal} `}>
      <b className={`${styles.titleGasto}`} >{ title }</b>
      {
        loading 
        ?
          <b className={`${ styles.textoGasto }`} >$ { new Intl.NumberFormat('de-DE').format(data) }</b>
        :
          <div className={ `spinner-border ${styles.spinnerCard}` } role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
      }
      
    </div>
  )
}
