import { useState } from 'react';
import { collection, doc, setDoc } from 'firebase/firestore';
import { APIRegiones } from './APIRegiones';
import { InputRut } from './InputRut';
import { ToastContainer } from 'react-toastify';
import { useEffect } from 'react';
import { formatRut } from '../../../helpers/formatRut';
import { showMessageError, showMessageExit } from '../../../helpers/ShowMessage';
import { validateInput } from '../../../helpers/validaciones';
import { BtnsFormRegister, InputFormRegister } from './components';
import moment from 'moment';
import db from '../../../backend/DBFiresbase';
import styles from "./Form.module.css";




export default function FormRegistroProveedores( { proveedores } ) {

    const valoresIniciales = {
        razonSocial: "",
        suministro: "",
        formaPago: "",
        diasPago: "",
        direccion: "",
        comuna: "",
        region: "",
        fechaRegistro: "",
        rut: "",
    }
    
    const [ valores, setValores ] = useState(valoresIniciales);
    const [ fechaRegistro, setFechaRegistro ] = useState("");
    const [ inputCredito, setInputCredito ] = useState(false);
    const [ activeRazonSocial, setActiveRazonSocial ] = useState(false);
    const [ errorRazonSocial, setErrorRazonSocial ] = useState("");
    const [ activeRut, setActiveRut ] = useState(false);
    const [ errorRut, setErrorRut ] = useState("");
    const [ activeRegion, setActiveRegion ] = useState(false);
    const [ errorRegion, setErrorRegion ] = useState("");
    const [ activeComuna, setActiveComuna ] = useState(false);
    const [ errorComuna, setErrorComuna ] = useState("");
    const [ activeDireccion, setActiveDireccion ] = useState(false);
    const [ errorDireccion, setErrorDireccion ] = useState('');
    const [ activeGiro, setActiveGiro ] = useState(false);
    const [ errorGiro, setErrorGiro ] = useState('');


    //Captura de los valores del formulario
    const handleInput = (e) => {
    const {name, value} = e.target;
    setValores({...valores, [name]:value.toUpperCase() });
    }

    const handleInputAPI = (e) => {
        const {name, value} = e.target;
        setValores({ ...valores, [name]:value });
    }

    const handleClick = () => {
        setFechaRegistro(moment().format('YYYY-MM-DD HH:mm:ss'));
    }

    const handleRut = (e) => {
        const {name, value} = e.target;
        const formatValue = formatRut(value);
        setValores({ ...valores, [name]:formatValue });
    }


    const validateRazonSocial = (e) => {
        const value = e.target.value;
        const regName = /^[a-zA-Z0-9]+([\-\. ]?[a-zA-Z0-9]+)*\.?$/;

        if( value.trim() !== "" ){
            if( !regName.test(e.target.value.trim())){
                setActiveRazonSocial(false);
                setErrorRazonSocial("La Razón Social es incorrecta");
            } else {
                setActiveRazonSocial(true);
                setErrorRazonSocial('');
            }
        } else {
            setActiveRazonSocial(false);
            setErrorRazonSocial("Ingresar Razón Social");
        }
    }


    const cleanInputs = () => {
        setErrorRazonSocial('');
        setActiveRazonSocial(false);
        setErrorRut('');
        setActiveRut(false);
        setErrorComuna('');
        setErrorRegion('');
        setErrorDireccion('');
        setActiveDireccion(false);
        setActiveRegion(false);
        setActiveComuna(false);
        setErrorGiro('');
        setActiveGiro(false);
        setInputCredito(false);
        setValores(valoresIniciales);
    }

    //Enviando los datos del formulario
    const handleSubmit = (e) => {
        e.preventDefault();
        const mes = moment(valores.fecha).format("MMMM");
        const anio = moment(valores.fecha).format("YYYY");

        if ( activeRut && 
            activeRazonSocial &&
            activeRegion &&
            activeComuna &&
            activeDireccion &&
            activeGiro){
        
            //Envianda el registro del formulario a Firebase
            const nuevoProveedor = doc(collection(db, "proveedores"));
            showMessageExit("Registro exitoso");

            setDoc( nuevoProveedor, { 
                ...valores, 
                fechaRegistro: fechaRegistro,
                mes: mes, 
                anio: anio,
            });
            
            //Se limpian los valores del formulario
            cleanInputs(); 
            setInputCredito(false);
        } else {
            !activeRut && setErrorRut('Ingresar RUT');
            !activeRazonSocial && setErrorRazonSocial('Ingresar Razón Social');
            !activeRegion && setErrorRegion('Seleccione una Región');
            !activeComuna && setErrorComuna('Seleccione una Comuna');
            !activeDireccion && setErrorDireccion('Ingrese Dirección');
            !activeGiro && setErrorGiro('Ingrese un Giro');
            showMessageError("¡Error!, tiene una casilla sin llenar", "bottom-center");
        }
    }

    useEffect(() => {
        valores.formaPago === 'CREDITO' ? 
        setInputCredito(true) :
        setInputCredito(false);
    }, [valores.formaPago, inputCredito])


    return (<>
        <form 
            className="row g-4"
            onSubmit={ handleSubmit }
            autoComplete="off"
        >
            
{/* //?       ****  Datos  generales  **** */}
            <InputFormRegister 
                title='Razón Social'
                handleChange={ handleInput }
                handleBlur={ validateRazonSocial }
                name="razonSocial"
                value={ valores.razonSocial }
                error={ errorRazonSocial }
                placeholder = 'Ejm: SODIMAC'
                firstSymbol=''
            />

            <div className="col-md-4">
                <InputRut
                    handleRut={ handleRut }
                    setActiveRut={ setActiveRut }
                    setErrorRut={ setErrorRut }
                    errorRut={ errorRut }
                    activeRut={ activeRut }
                    clientes={ proveedores }
                    valores={ valores } 
                />
            </div>

            <InputFormRegister 
                title='Giro'
                handleChange={ handleInput }
                handleBlur={ (e) => validateInput(e, setErrorGiro, setActiveGiro, 'Ingrese un Giro') }
                name="suministro"
                value={ valores.suministro }
                error={ errorGiro }
                placeholder = 'Ejemplo: Fierro y acero'
                firstSymbol=''
            />

            <div className="col-md-4">
                <label className={` form-label ${ styles.labelForm } `}>Forma de Pago</label>
                <select 
                    className={` form-select ${ styles.inputForm }`} 
                    onChange={ handleInput }
                    name="formaPago"
                    value={ valores.formaPago }>
                    <option value="">Seleccione</option>
                    <option value="CONTADO">CONTADO</option>
                    <option value="CREDITO">CREDITO</option>
                </select>
            </div>

            {
                inputCredito &&
                
                <div className="col-md-4">
                    <label className={` form-label ${ styles.labelForm } `}>Días de pago:</label>
                    <div className="input-group">
                        <input
                            onChange={ handleRut }
                            type="text"
                            className={ `form-control ${ styles.inputForm }` } 
                            name="diasPago"
                            value={valores.diasPago}
                            maxLength="2" 
                            min="0"
                            />
                        <span className={`input-group-text ${ styles.bgHeadModal }`}>días</span>
                    </div>
                </div>
            }


{/* //?       ****  Datos  de  ubicación  **** */}
            <InputFormRegister 
                title='Dirección'
                handleChange={ handleInput }
                handleBlur={ (e) => validateInput(e, setErrorDireccion, setActiveDireccion, 'Ingrese una Dirección') }
                name="direccion"
                value={ valores.direccion }
                error={ errorDireccion }
                placeholder= 'Ejemplo: Av. El Parrón 345'
                firstSymbol=''
            />

            <APIRegiones 
                handleInput={ handleInputAPI }
                valores={ valores }
                errorRegion={ errorRegion }
                errorComuna={ errorComuna }
                setActiveRegion={ setActiveRegion }
                setActiveComuna={ setActiveComuna }
                setErrorRegion={ setErrorRegion }
                setErrorComuna={ setErrorComuna }
                activeRegion={ activeRegion }
                activeComuna={ activeComuna }
                label={` form-label ${ styles.labelForm } `}
                select={`form-select ${ styles.inputForm }`} 
            />

            <BtnsFormRegister 
                cleanInputs={ cleanInputs }
                handleClick={ handleClick }
            />

            <ToastContainer />
        </form>
    </>)
}
