import { TablaRegistroGastos } from "../../pure/form/TablaRegistroGastos";
import styles from "./Registros.module.css";


export default function RegistroDeGastos() {

    return (

        <div className={ styles.WrapperContenedor  } >
            <p className="h5 fw-bold align-self-start" >Registros de  Gastos</p>
            <hr className="border border-dark border-1  w-100 m-1"></hr>

            <div className={`${ styles.wrapperTable }`} >
                <TablaRegistroGastos />
            </div>
        </div>
    )
}