import { query, collection, onSnapshot, where } from 'firebase/firestore';
import { filterProjectsByState } from './filterProjectsByState';
import { getAscOrderedProjectsByDate } from './getAscOrderedProjectsByDate';
import db from '../backend/DBFiresbase';



export const getProductionProgressByAreaOfActiveProjects = ( area, setState ) => {

  const q = query(collection(db, 'proyectos' ), where( "area" , "==", area));

  onSnapshot(q, (querySnapshot) => {
    const docs = [];

    querySnapshot.forEach((doc) => {
      docs.push( {...doc.data()} );
    });

    const filteredProjectsInExecution = filterProjectsByState(docs, "Ejecución");

    const projectsOrderedByDate = getAscOrderedProjectsByDate(filteredProjectsInExecution);

    const budgets =  projectsOrderedByDate.map( el => el.produccion ? el.produccion : 0 );

    setState(budgets);
  });
}
