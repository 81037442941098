import { query, collection, onSnapshot, where } from 'firebase/firestore';
import { filterProjectsByState } from './filterProjectsByState';
import { getAscOrderedProjectsByDate } from './getAscOrderedProjectsByDate';
import db from '../backend/DBFiresbase';


export const  getValuesActiveProjectSalesByArea = (  area, setState ) => {
    const q = query(collection( db, "proyectos" ),where( "area", "==", area ));

    onSnapshot( q, (querySnapshot) => {
        const docs = [];

        querySnapshot.forEach((doc) => {
            docs.push({...doc.data(), id:doc.id});
        });

        const filteredProjectsInExecution = filterProjectsByState(docs, "Ejecución");

        const projectsOrderedByDate = getAscOrderedProjectsByDate(filteredProjectsInExecution);

        const valuesOfProjectSales = projectsOrderedByDate.map(el => {
            const totalSales = el.ventas.length ? parseInt(el.ventas.map(sale => parseInt(sale.valor)).reduce((a,b) => a+b)) : 0;
            return totalSales;
        });

        setState(valuesOfProjectSales);
    });

}

