import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyDGfrzqFekZ2rFhBWXe-WIBB1TT8x9w9wI",
    authDomain: "dashboard-ebg.firebaseapp.com",
    projectId: "dashboard-ebg",
    storageBucket: "dashboard-ebg.appspot.com",
    messagingSenderId: "828343214692",
    appId: "1:828343214692:web:55e1cf575ea7f95c8af571"
}

export const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);

export default  db;

