import {query, collection, onSnapshot , orderBy, where} from 'firebase/firestore';
import db from '../backend/DBFiresbase';

export const getListaVentasEnEjecucion = ( coleccion, estado ) => {
    const q = query(collection( db, coleccion ), where("estado", "==", "Ejecución"), orderBy("fechaRegistro", "asc"));

    onSnapshot(q, (querySnapshot) => {
        const docs = [];

        querySnapshot.forEach((doc) => {
            docs.push({...doc.data()});
        });

        
        const lista = docs.map(el => {
            const sumaVentas = el.ventas.length ? parseInt(el.ventas.map(venta => parseInt(venta.valor)).reduce((a,b) => a+b)) : 0;
            return sumaVentas;
        });
        

        estado(lista);
    });

}
