import { doc, deleteDoc } from 'firebase/firestore';
import db from "../../../backend/DBFiresbase"
import Swal from 'sweetalert2'


export const BtnDeleteRegister = ( { coleccion, id } ) => {

  const handleClick = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success m-2',
        cancelButton: 'btn btn-danger m-2'
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: '¿Desea eliminar el registro?',
      text: "Si elimina, ya no lo podrá recuperar",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const docRef = doc( db, coleccion, id );
        deleteDoc(docRef);
        swalWithBootstrapButtons.fire(
          '¡Eliminado!',
          'Registro fue eliminado con éxito',
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          '¡Cancelado!',
          'El registro se mantiene',
          'error'
        )
      }
    })
  }

  return (<>
    <button className='btn btn-danger py-0' onClick={ handleClick }>
      <i className="bi bi-trash3"></i>
    </button>

  </>)
}
