import {query, collection, onSnapshot , orderBy, where} from 'firebase/firestore';
import db from '../backend/DBFiresbase';

const getAvanceFacturado = (coleccion,  estado) => {
    const q = query(collection(db, coleccion ), where("estado", "==", "Ejecución"), orderBy("fechaRegistro", "asc"));

    onSnapshot(q, (querySnapshot) => {
        const docs = [];

        querySnapshot.forEach((doc) => {
            docs.push({...doc.data()});
        });

        
        const lista = docs.map(el => {
            const presupuesto = parseInt(el.presupuesto);
            const sumaVentas = el.ventas.length ? parseInt(el.ventas.map(venta => parseInt(venta.valor)).reduce((a,b) => a+b)) : 0;
            const porcentajeVenta = parseInt(((sumaVentas / presupuesto)*100).toFixed(2))
            return porcentajeVenta;
        });
        

        estado(lista);
    });

}

export default getAvanceFacturado