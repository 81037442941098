import { collection, doc, onSnapshot, query, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { BtnNavigateRegisters } from "./BtnNavigateRegisters";
import { BuscadorRazonSocial } from "./components/BuscadorRazonSocial";
import { SelectAnio } from "./SelectAnio";
import { SelectMes } from "./SelectMes";
import { ErrorBusqueda, TBodyLoading } from "./components";
import db from "../../../backend/DBFiresbase";
import styles from './Form.module.css';





//Cantidad de items para paginación de gastos de Mnao de Obra
const ITEMS_NAVEGACION = 15;

export const TablaRegistroProduccion = () => {

  const [proyectos, setProyectos] = useState([]);
  const [idProyecto, setIDProyecto] = useState("");
  const [value, setValue] = useState("");
  const [nombre, setNombre] = useState("");
  const [ loadingTable, setLoadingTable ] = useState(false);
  const [ mes, setMes ] = useState('');
  const [ anio, setAnio ] = useState('');
  const [ buscarProyecto, setBuscarProyecto ] = useState("");

  //Capturando los valores de los inputs del form
  const handleChange = (e, setState) => {
    const { value } = e.target;
    setState(value);
  }


  // Obteniendo  datos de Firebase
  const q = query(collection(db, "proyectos"));

  const getData = async () => {
    onSnapshot(q, (querySnapshot) => {
      const docs = [];

      querySnapshot.forEach((doc) => {
        docs.push({...doc.data(), id:doc.id});
      });

      const listaOrdenada = docs.sort((a,b) => b.fechaRegistro.localeCompare(a.fechaRegistro));
      listaOrdenada.sort((a,b) => new Date(b.fechaRegistro).getTime() - new Date(a.fechaRegistro).getTime() );
      setProyectos(listaOrdenada);

      setTimeout(() => {
        setLoadingTable(true);
      }, 800);
    });
  }
  
  //Obteniendo ID
  const getId = (id) => {
      setIDProyecto(id)
  }

  //Obteniendo el valor del input
  const handleInput = (e) => {
      const { value } = e.target;
      setValue(value);
  }

  //Obteniendo nombre de proyecto con ID
  const getNombre =  (nombre) => {
      setNombre(nombre)
  }

  //Envío de formulario
  const handleSubmit = (e) => {
      e.preventDefault();

      const nuevoAvance = doc(db, "proyectos", idProyecto);
      updateDoc(nuevoAvance, { produccion: value} )
      setValue("");
      setNombre("")
  }
  

  useEffect(() => {
      getData();
      getId();
      getNombre();
  }, [])

  const proyectosFiltrados = proyectos?.filter(el => {
    return(
      (!anio || el.anioInicio === anio) &&
      (!mes || el.mesInicio === mes) &&
      (!buscarProyecto || el.nombre.toUpperCase()?.includes(buscarProyecto))
    )
  });


  //? Mostrando los datos en el formulario
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  //? Separando los datos traídos de Firebase
  useEffect(() => {
    setItems([...proyectosFiltrados].splice(0, ITEMS_NAVEGACION))
    setCurrentPage(0);
    setTimeout(() => {
      setLoadingTable(true);
    }, 800);
  },[ proyectos, mes, anio, buscarProyecto ]);


  //todo Funciones de Navegación
  const nextHandler = () => {
    const totalElementos = proyectosFiltrados.length;

    const nextPage = currentPage + 1;
    const firstIndex = nextPage * ITEMS_NAVEGACION;

    if(firstIndex >= totalElementos) return;

    setItems([...proyectosFiltrados].splice(firstIndex, ITEMS_NAVEGACION));
    setCurrentPage(nextPage);
  }

  const prevHandler = () => {
    const prevPage = currentPage - 1;
    
    if(prevPage < 0) return;

    const firstIndex = prevPage * ITEMS_NAVEGACION;

    setItems([...proyectosFiltrados].splice(firstIndex, ITEMS_NAVEGACION));
    setCurrentPage(prevPage);
  }

  

  return (<>
    <form className=' mx-auto d-flex justify-content-evenly  my-4 align-items-center'>
      <div className=" form-check d-flex align-items-center flex-column" >
        <BuscadorRazonSocial 
          doc={ buscarProyecto }
          setDoc={ setBuscarProyecto }
          text="Buscar Proyecto..."
        />
      </div>

      <div className='d-flex align-items-center'>
        <SelectAnio 
          className={ `form-select ${ styles.selectForm } text-center pe-0 py-1` }
          eventChange={ (e) => handleChange(e, setAnio) }
          anio= { anio }
        />
      </div>

      <div className=' d-flex align-items-center gap-1'>
        <SelectMes 
          handleChange= { (e) => handleChange(e, setMes) }
          className={ `form-select ${ styles.selectForm } pe-0 py-1` }
        />
      </div>
    </form>

    <BtnNavigateRegisters 
      prevHandler={ prevHandler }
      currentPage={ currentPage }
      nextHandler={ nextHandler }
    />

    <table className={ `table text-center table-striped rounded-top ${ styles.tableResponsive } mx-auto px-2 mt-3` }>
      <thead className="rounded-top">
        <tr className={ `${ styles.tableHead } fs-6` } >
          <th>#</th>
          <th>Proyecto</th>
          <th>% Avance</th>
          <th>Actualizar</th>
        </tr>
      </thead>

      {
        loadingTable ?
        <tbody className={ `table-group-divider ${styles.tableBody} ` }>
          { items.length >=1 ?
        
            items.map((proyecto, index) => {
              return (
                <tr key= {proyecto.id}  className="table-secondary" >
                  <td className="fs-6" >{ index + 1 }</td>
                  <td className="fs-6" >{ proyecto.nombre }</td>
                  <td className="fs-6" >{ proyecto.produccion ?  proyecto.produccion : 0} %</td>
                  <td>
                    <button 
                      className={`btn py-0 ${ styles.btnEditar}`}
                      onClick={() => { getId(proyecto.id); getNombre(proyecto.nombre)} }
                      type="button" 
                      data-bs-toggle="modal" 
                      data-bs-target="#modalActualizarProduccion"
                    >
                      <i className="bi bi-pencil"></i>
                    </button>

                    {/* Modal de Formulario de Registro de Proyectos */}
                    <div className="modal fade" id="modalActualizarProduccion"  aria-labelledby="modalProduccionLabel" aria-hidden="true">
                      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                        <div className="modal-content">
                          <div className={ `modal-header ${ styles.modalHead } ` }>
                            <h5 className="modal-title" id="modalProduccionLabel">Registro Avance de Producción</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div className={ `modal-body ${ styles.modalBody }` }>
                            <form 
                              className="row g-4"
                              onSubmit={ handleSubmit }
                            >

                              <div className="col-md-7 mx-auto">
                                <label className={`form-label mb-3 w-100 ${ styles.labelForm } `}>{ nombre }</label>
                                  <div className="input-group d-flex justify-content-center">
                                    <input
                                      className={`form-control w-25 ${ styles.inputForm }`}
                                      type="text" 
                                      placeholder="Ingrese % de avance..."
                                      onChange={ handleInput }
                                      value={ value }
                                    />
                                    <span className={`input-group-text ${ styles.bgHeadModal }`}>%</span>
                                  </div>
                              </div>

                              <div className="col-md-10 mx-auto">
                                <button 
                                  className={ `btn fw-normal ${styles.btnPrimary}` }  
                                  data-bs-dismiss="modal"
                                >
                                  Registrar
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )
            }) :

            <ErrorBusqueda 
              colSpan={ 4 }
              text='proyectos registrados'
            />
          }
        </tbody>

        :

        <TBodyLoading col='4' />
      }
    </table>

  </>)
}
