import { useState, useEffect } from 'react';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { getDataTrabajador } from '../../../helpers/getDataTrabajador';
import { getImposiciones } from '../../../helpers/getImposiciones';
import { getDescuentos } from '../../../helpers/getDescuentos';
import { showMessageError, showMessageExit } from '../../../helpers/ShowMessage';
import { ToastContainer } from 'react-toastify';
import { getDataCollectionColaboradores } from '../../../helpers/getDataCollectionColaboradores';
import { validateInput } from '../../../helpers/validaciones';
import { formatNumDoc } from '../../../helpers/formatNumDoc';
import { getListaProyectosAsc } from '../../../helpers/getListaProyectosAsc';
import { BtnsFormRegister } from './components';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import getIDDoc from '../../../helpers/getIDDoc';
import db from '../../../backend/DBFiresbase';
import styles from "./Form.module.css";




export const FormRegistroManoObra = () => {

    const valoresIniciales = {
        fechaRegistro:"",
        fechaGasto:"",
        mesGasto: "",
        anioGasto: "",
        proyecto:"",
        nombre_trabajador: "",
        dias_trabajados: "",
        horas_no_trabajadas:"",
        horas_extras:"",
        asig_herramientas:"",
        aguinaldo:"",
        bono_asistencia:"",
        bono_seguridad:"",
        bono_produccion:"",
        total_imponible:"",
        total_no_imponible:"",
        valor: "",
        concepto:"Mano de Obra",
        tipo:"Fijo",
    }

    const [ activeFecha, setActiveFecha ] = useState(false);
    const [ errorFecha, setErrorFecha ] = useState('');
    const [ activeColaborador, setActiveColaborador ] = useState(false);
    const [ errorColaborador, setErrorColaborador ] = useState('');
    const [ activeDias, setActiveDias ] = useState(false);
    const [ errorDias, setErrorDias ] = useState('');
    const [ activeProyecto, setActiveProyecto ] = useState(false);
    const [ errorProyecto, setErrorProyecto ] = useState('');

    //Variables para enviar
    const [ valores, setValores ] = useState(valoresIniciales);
    const [ fechaRegistro, setFechaRegistro ] = useState("");

    const [ idTrabajador, setIdTrabajador ] = useState("");
    const [ nombreTrabajador, setNombreTrabajador ] = useState("");
    const [ dataTrabajador, setDataTrabajador ] = useState({});
    const [ sueldoBase, setSueldoBase ] = useState(0);
    const [ gratificacion, setGratificacion ] = useState(0);
    const [ bonoSeguridad, setBonoSeguridad ] = useState(0);
    const [ bonoAsistencia, setBonoAsistencia ] = useState(0);
    const [ bonoObjetivo, setBonoObjetivo ] = useState(0);

    const [ colacion, setColacion ] = useState(0);
    const [ movilizacion, setMovilizacion ] = useState(0);
    const [ totalImponible, setTotalImponible ] = useState(0);
    const [ totalNoImponible, setTotalNoImponible ] = useState(0);
    const [ afp, setAfp ] = useState(0);
    const [ salud, setSalud ] = useState(0);
    const [ cesantia, setCesantia ] = useState(0);
    const [ sis, setSis ] = useState(0);
    const [ mutual, setMutual ] = useState(0);

    const [ idProyecto, setIdProyecto ] = useState("");
    const [ nombreProyecto, setNombreProyecto ] = useState("");
    const [ proyectos, setProyectos ] = useState([]);
    const [ trabajadores, setTrabajadores ] = useState([]);

    const [ validaBonoSeguridad, setValidaBonoSeguridad ] = useState("1");
    const [ validaBonoAsistencia, setValidaBonoAsistencia ] = useState("1");
    const [ validaBonoObjetivo, setValidaBonoObjetivo ] = useState("0");



// todo  *** Capturar los valores de los inputs del Form  ***

    const handleInput = (e) => {
        const {name, value} = e.target;
        setValores({...valores, [name]:value});
    }

    const cambioRadioOption= (e, setEstado) =>{
        setEstado(e.target.value);
    }

    const handleClick = () => {
        setFechaRegistro(moment().format('YYYY-MM-DD HH:mm:ss'));
    }

    const handleInputNumeric = (e) => {
        const {name, value} = e.target;
        const formatValue = formatNumDoc(value);
        setValores({ ...valores, [name]:formatValue });
    }

    const cleanInputs = () => {
        setValores( valoresIniciales );
        setNombreTrabajador('');
        setNombreProyecto('');
        setActiveFecha(false);
        setActiveColaborador(false);
        setActiveDias(false);
        setActiveProyecto(false);
        setErrorColaborador('');
        setErrorDias('');
        setErrorFecha('');
        setErrorProyecto('');
        dataTrabajador({});
        idTrabajador('');
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        //Se capturan el mes y año de la fecha de la venta
        const mes = moment(valores.fechaGasto).format("MMMM");
        const anio = moment(valores.fechaGasto).format("YYYY");
        const valorAsigHerr = valores.asig_herramientas === '' ? 0 : valores.asig_herramientas;
        const valorBProduccion = valores.bono_produccion === '' ? 0 : valores.bono_produccion;
        const valorAguinaldo = valores.aguinaldo === '' ? 0 : valores.aguinaldo;
        const valorHrsExtras = valores.horas_extras === '' ? 0 : valores.horas_extras;
        const valorHorasNoTrabajadas = valores.horas_no_trabajadas === '' ? 0 : valores.horas_no_trabajadas;

        if( activeFecha &&
            activeColaborador &&
            activeDias &&
            activeProyecto){

                // Enviando Data a Firebase
                const nuevoGasto = doc(db, "proyectos", idProyecto);
                showMessageExit("¡Gasto agregado con éxito!");

                setTimeout(() => {
                    updateDoc(nuevoGasto, 
                        {gastos: arrayUnion({...valores,
                            nombre_trabajador: nombreTrabajador, 
                            fechaRegistro: fechaRegistro, 
                            anioGasto:anio, 
                            mesGasto:mes, 
                            proyecto:nombreProyecto,
                            bono_asistencia: bonoAsistencia,
                            bono_seguridad: bonoSeguridad,
                            bono_objetivo: bonoObjetivo,
                            bono_produccion: valorBProduccion,
                            aguinaldo: valorAguinaldo,
                            afp: afp,
                            prevision_salud: salud,
                            cesantia: cesantia,
                            mutual: mutual,
                            sis: sis,
                            total_imponible: totalImponible,
                            total_no_imponible: totalNoImponible,
                            valor: (parseInt(totalImponible) + parseInt(totalNoImponible) + parseInt(sis) + parseInt(mutual)),
                            concepto:"Mano de Obra",
                            tipo:"Fijo", 
                            gratificacion: gratificacion,
                            movilizacion: movilizacion,
                            colacion: colacion,
                            sueldo_base: sueldoBase,
                            asig_herramientas: valorAsigHerr,
                            horas_no_trabajadas: valorHorasNoTrabajadas,
                            horas_extras: valorHrsExtras,
                            })
                        }
                    );
                    cleanInputs();
                }, 1200)
                

        } else {
            !activeFecha && setErrorFecha('Ingresar fecha del gasto');
            !activeColaborador && setErrorColaborador('Ingresar Colaborador');
            !activeDias && setErrorDias('Ingrese los días trabajados');
            !activeProyecto && setErrorProyecto('Ingrese un Proyecto')
            showMessageError("¡Error!, tiene una casilla sin llenar", "bottom-center");
        }
    }


//todo   Obteniendo lista de Proyectos 
    useEffect(() => {
        getListaProyectosAsc(setProyectos);
    },[]);



//todo   Obteniendo lista de Colaboradores
    useEffect(() => {
        getDataCollectionColaboradores(setTrabajadores);
    },[]);


//todo   Obteniendo ID del Proyecto
    useEffect(() => {
        if(nombreProyecto === ""){
            setActiveProyecto(false)
            return;
        } else {
            setActiveProyecto(true)
        };
        getIDDoc("proyectos", nombreProyecto, setIdProyecto);
    },[nombreProyecto]);


//todo   Obteniendo ID del Trabajador
    useEffect(() => {
        if(nombreTrabajador === ""){
            setActiveColaborador(false);
            return
        } else {
            setActiveColaborador(true);
        };
        getIDDoc("colaboradores", nombreTrabajador, setIdTrabajador )
    },[nombreTrabajador]);

//todo   Obteniendo Data del trabajador desde Firestore
    useEffect(() => {
        if(idTrabajador === ""){
            return
        } else{
            setActiveColaborador(true);
        };
        getDataTrabajador(idTrabajador, setDataTrabajador);
    },[idTrabajador]);


//todo   Calculando sueldo base, gratificación y bonos
    useEffect(() => {
        if(Object.keys(dataTrabajador).length === 0) return;

        setSueldoBase( getImposiciones( valores.dias_trabajados, dataTrabajador.sueldo_base ));
        setGratificacion( getImposiciones( valores.dias_trabajados, dataTrabajador.gratificacion_legal ));
        setBonoSeguridad( validaBonoSeguridad === "1" ? getImposiciones( valores.dias_trabajados, dataTrabajador.bono_seguridad ) : 0);
        setBonoAsistencia( validaBonoAsistencia === "1" ? getImposiciones( valores.dias_trabajados, dataTrabajador.bono_asistencia ) : 0);
        setBonoObjetivo( validaBonoObjetivo === "1" ? getImposiciones( valores.dias_trabajados, dataTrabajador.bono_objetivo ) : 0);
        setColacion( getImposiciones( valores.dias_trabajados, dataTrabajador.colacion));
        setMovilizacion( getImposiciones( valores.dias_trabajados, dataTrabajador.movilizacion));

    },[ 
        dataTrabajador,  
        valores.dias_trabajados, 
        validaBonoSeguridad, 
        validaBonoAsistencia,
        validaBonoObjetivo ]);


//todo   Calculo del Imponible
    useEffect(() => {
        if( valores.dias_trabajados === "" ) {
            setActiveDias(false);
            return
        }else{
            setActiveDias(true);
        };

        setTotalImponible(
            sueldoBase + 
            gratificacion +
            (bonoSeguridad ? parseInt(bonoSeguridad) : 0) +
            (bonoAsistencia ? parseInt(bonoAsistencia) : 0) + 
            (bonoObjetivo ? parseInt(bonoObjetivo) : 0) +
            (valores.bono_produccion.trim().length > 1 ? parseInt(valores.bono_produccion) : 0) + 
            (valores.horas_extras.trim().length > 1 ? parseInt(valores.horas_extras) : 0) -
            (valores.horas_no_trabajadas.trim().length > 1 ? parseInt(valores.horas_no_trabajadas) : 0));

    },[ valores.dias_trabajados,
        valores.bono_produccion,
        valores.horas_extras,
        valores.horas_no_trabajadas,
        sueldoBase, 
        gratificacion, 
        bonoSeguridad,
        bonoAsistencia,
        bonoObjetivo ]);



//todo   Calculo del NO Imponible
    useEffect(() => {
        if( valores.dias_trabajados === "" )  return;

        setTotalNoImponible(
            (movilizacion ?  parseInt(movilizacion) : 0) +
            (colacion ?  parseInt(colacion) : 0) +
            (valores.asig_herramientas.trim().length > 1 ? parseInt(valores.asig_herramientas) : 0) +
            (valores.aguinaldo.trim().length > 1 ? parseInt(valores.aguinaldo) : 0));

    },[ valores.dias_trabajados,
        valores.asig_herramientas,
        valores.aguinaldo,
        colacion,
        movilizacion ]);


//todo   Calculo de todas las imposiciones
    useEffect(() => {
        if(totalImponible === 0)  return;

        setAfp( (totalImponible * dataTrabajador.afp) );
        setSalud( getDescuentos(totalImponible, "salud"));
        setCesantia( getDescuentos(totalImponible, "cesantia") );
        setSis( getDescuentos(totalImponible, "sis") );
        setMutual( getDescuentos(totalImponible, "mutual") );
    },[ totalImponible, dataTrabajador, ])



    return (<>
        
        <form 
            className="row g-4" 
            onSubmit={ handleSubmit }
            autoComplete="off"
        >

            <div className={`col-md-4`}>
                <label className={ `form-label ${ styles.labelForm }` }>Fecha del Gasto:</label>
                <input
                    onChange={ handleInput }
                    onBlur={(e) => validateInput(e, setErrorFecha, setActiveFecha, 'Ingresar fecha del gasto') }
                    type="date" 
                    className={ `form-control ${ styles.inputForm } ${ errorFecha === '' ? "" : styles.errorInput }`} 
                    name="fechaGasto"
                    value={valores.fechaGasto}
                />
                <span className={ styles.error }>
                    { errorFecha !== "" ? errorFecha : "" }
                </span>
            </div>

            <div className={`col-md-4`}>
                <label className={ `form-label ${ styles.labelForm }` }>Colaborador:</label>
                <select 
                    className={ `form-select ${ styles.inputForm } ${ errorColaborador === '' ? "" : styles.errorInput }`} 
                    onChange={(e) => setNombreTrabajador(e.target.value)} 
                    onBlur={ (e) => validateInput(e, setErrorColaborador, setActiveColaborador, 'Seleccione un Colaborador') }
                    name="nombre_trabajador"
                    value={nombreTrabajador}>
                    <option value="">Seleccione</option>
                    {   
                        trabajadores.map((el, index) => {
                        return(
                            <option value={ el } key={ index + el }> { el } </option>
                            )
                        })
                    }
                </select>
                <span className={ styles.error }>
                    { errorColaborador !== "" ? errorColaborador : "" }
                </span>
            </div>

            <div className={`col-md-4`}>
                <label className={ `form-label ${ styles.labelForm }` }>Proyecto:</label>
                <select 
                    className={ `form-select ${ styles.inputForm } ${ errorProyecto === '' ? "" : styles.errorInput }`} 
                    onChange={(e) => setNombreProyecto(e.target.value)} 
                    onBlur={ (e) => validateInput(e, setErrorProyecto, setActiveProyecto, 'Elija un Proyecto') }
                    name="proyecto"
                    value={ nombreProyecto }
                >
                    <option value="">Seleccione</option>
                    { proyectos.map((el, index) => {
                        return(
                            <option value={ el } key={ index + el }> { el } </option>
                        )
                        })}
                </select>
                <span className={ styles.error }>
                    { errorProyecto !== "" ? errorProyecto : "" }
                </span>
            </div>


            <div className={`col-md-3`}>
                <label className={ `form-label ${ styles.labelForm }` }>Días trabajados:</label>
                <div className={ `input-group  mx-auto `} >
                    <input
                        className={ `form-control ${ styles.inputForm } ${ errorDias === '' ? "" : styles.errorInput }`}
                        onBlur={ (e) => validateInput(e, setErrorDias, setActiveDias, 'Ingrese los días trabajados') }
                        name='dias_trabajados'
                        type="text"
                        onChange={ handleInputNumeric }
                        value={ valores.dias_trabajados }
                        placeholder='Ejm: 30'
                        maxLength={ 2 }
                        />
                    <span className={ `input-group-text ${ styles.bgHeadModal }`}>días</span>
                </div>
                <span className={ styles.error }>
                    { errorDias !== "" ? errorDias : "" }
                </span>
            </div>

        
            <fieldset className='col-md-3' >
                <legend className={ `${ styles.labelForm }` } >Bono de Seguridad:</legend>
                <div className='d-flex gap-3' >
                    <div className="form-check">
                        <input 
                            className={` form-check-input ${ styles.inputForm } `}
                            value="1" 
                            checked={ validaBonoSeguridad === "1" ? true : false }
                            onChange={ (e) => cambioRadioOption(e, setValidaBonoSeguridad) }
                            type="radio" 
                            name="radioBonoSeguridad" 
                            id="radioBonoSeguridad1" />
                        <label className= {` form-check-label ${ styles.labelForm }`} >
                            Sí
                        </label>
                    </div>
                    <div className="form-check">
                        <input 
                            className={` form-check-input ${ styles.inputForm } `}
                            value="0"
                            checked={ validaBonoSeguridad === "0" ? true : false }
                            onChange={ (e) => cambioRadioOption(e, setValidaBonoSeguridad) }
                            type="radio" 
                            name="radioBonoSeguridad" 
                            id="radioBonoSeguridad2" />
                        <label className={` form-check-label ${ styles.labelForm }`}>
                        No
                        </label>
                    </div>
                </div>
            </fieldset>

            <fieldset className='col-md-3'>
                <legend className={ `${ styles.labelForm }` } >Bono de Asistencia:</legend>
                <div className='d-flex gap-3' >
                    <div className="form-check">
                        <input 
                            className={` form-check-input ${ styles.inputForm } `}
                            value="1" 
                            checked={ validaBonoAsistencia === "1" ? true : false }
                            onChange={ (e) => cambioRadioOption(e, setValidaBonoAsistencia) }
                            type="radio" 
                            name="radioBonoAsistencia" 
                            id="radioBonoAsistencia1" />
                        <label className= {` form-check-label ${ styles.labelForm }`} >
                            Sí
                        </label>
                    </div>
                    <div className="form-check">
                        <input 
                            className={` form-check-input ${ styles.inputForm } `}
                            value="0"
                            checked={ validaBonoAsistencia === "0" ? true : false }
                            onChange={ (e) => cambioRadioOption(e, setValidaBonoAsistencia) }
                            type="radio" 
                            name="radioBonoAsistencia" 
                            id="radioBonoAsistencia2" />
                        <label className={` form-check-label ${ styles.labelForm }`}>
                        No
                        </label>
                    </div>
                </div>
            </fieldset>

            <fieldset className='col-md-3'>
                <legend className={ `${styles.labelForm}` } >Bono Objetivo:</legend>
                <div className='d-flex gap-3' >
                    <div className="form-check">
                        <input 
                            className={` form-check-input ${ styles.inputForm } `}
                            value="1" 
                            checked={ validaBonoObjetivo === "1" ? true : false }
                            onChange={ (e) => cambioRadioOption(e, setValidaBonoObjetivo) }
                            type="radio" 
                            name="radioBonoObjetivo" 
                            id="radioBonoObjetivo1" />
                        <label className= {` form-check-label ${ styles.labelForm }`} >
                            Sí
                        </label>
                    </div>
                    <div className="form-check">
                        <input 
                            className={` form-check-input ${ styles.inputForm } `}
                            value="0"
                            checked={ validaBonoObjetivo === "0" ? true : false }
                            onChange={ (e) => cambioRadioOption(e, setValidaBonoObjetivo) }
                            type="radio" 
                            name="radioBonoObjetivo" 
                            id="radioBonoObjetivo2" />
                        <label className={` form-check-label ${ styles.labelForm }`}>
                        No
                        </label>
                    </div>
                </div>
            </fieldset>

            <div className={`col-md-4`}>
                <label className={ `form-label ${ styles.labelForm }` }>Horas no trabajadas:</label>
                <div className="input-group mx-auto" >
                    <span className={ `input-group-text ${ styles.bgHeadModal }`}>$</span>
                    <input
                        onChange={  handleInputNumeric }
                        value={ valores.horas_no_trabajadas }
                        className={ `form-control ${ styles.inputForm } `} 
                        type="text" 
                        name='horas_no_trabajadas'
                        placeholder='Ejm: 12000'
                        />
                    <span className={ `input-group-text ${ styles.bgHeadModal }`}>.00</span>
                </div>
            </div>

            <div className={`col-md-4`}>
                <label className={ `form-label ${ styles.labelForm }` }>Horas extras:</label>
                <div className="input-group  mx-auto" >
                    <span className={ `input-group-text ${ styles.bgHeadModal }`}>$</span>
                    <input
                        onChange={ handleInputNumeric }
                        value={ valores.horas_extras }
                        className={ `form-control ${ styles.inputForm } `} 
                        type="text" 
                        name='horas_extras'
                        placeholder='Ejm: 50000'/>
                    <span className={ `input-group-text ${ styles.bgHeadModal }`}>.00</span>
                </div>
            </div>

            <div className={`col-md-4`}>
                <label className={ `form-label ${ styles.labelForm }` }>Asig. Herramienta:</label>
                <div className="input-group mx-auto" >
                    <span className={ `input-group-text ${ styles.bgHeadModal } `}>$</span>
                    <input
                        onChange={ handleInputNumeric }
                        className={ `form-control ${ styles.inputForm } `} 
                        type="text"
                        name='asig_herramientas'
                        value={ valores.asig_herramientas }
                        placeholder='Ejm: 200000'
                    />
                    <span className={ `input-group-text ${ styles.bgHeadModal }`}>.00</span>
                </div>
            </div>

            <div className={`col-md-4`} >
                <label className={ `form-label ${ styles.labelForm }` }>Bono Producción:</label>
                <div className="input-group mx-auto" >
                    <span className={ `input-group-text ${ styles.bgHeadModal } `}>$</span>
                    <input
                        onChange={ handleInputNumeric }
                        className={ `form-control ${ styles.inputForm } `} 
                        type="text"
                        name='bono_produccion'
                        value={ valores.bono_produccion } 
                        placeholder='Ejm: 200000'/>
                    <span className={ `input-group-text ${ styles.bgHeadModal }`}>.00</span>
                </div>
            </div>

            <div className={`col-md-4`} >
                <label className={ `form-label ${ styles.labelForm }` }>Aguinaldo:</label>
                <div className="input-group mx-auto" >
                    <span className={ `input-group-text ${ styles.bgHeadModal }`}>$</span>
                    <input
                        onChange={ handleInputNumeric }
                        className={ `form-control ${ styles.inputForm } `} 
                        type="text"
                        name='aguinaldo' 
                        placeholder='Ejm: 100000'
                        value={ valores.aguinaldo }/>
                    <span className={ `input-group-text ${ styles.bgHeadModal } `}>.00</span>
                </div>
            </div>

            <BtnsFormRegister 
                cleanInputs={ cleanInputs }
                handleClick={ handleClick }
            />

            <ToastContainer/>
        </form>
    

    </>)
}
