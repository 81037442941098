import { TablaRegistroProyectos } from '../../pure/form/TablaRegistroProyectos';
import styles from './Registros.module.css';



export default function RegistrosProyectos() {

    return (
        <div className={ styles.WrapperContenedor } >
            <p className="h5 fw-bold align-self-start" >Registros de Proyectos</p>
            <hr className="border border-dark border-1  w-100 m-1"></hr>

            <div className={`${ styles.wrapperTable }`} >
                <TablaRegistroProyectos />
            </div>
        </div>
    )
}
