import { query, collection, onSnapshot } from 'firebase/firestore';
import db from '../backend/DBFiresbase';

export const getDataCollectionColaboradores = (estado) => {
  onSnapshot(query(collection(db,"colaboradores")), (querySnapshot) => {
    const docs = [];

    querySnapshot.forEach((doc) => {
        docs.push({...doc.data(), id:doc.id});
    });

    let lista = docs.map(el => el.nombre) ;
    lista.sort((a,b) => a.localeCompare(b));

    estado(lista);
});
}
