import { query, collection, onSnapshot, where } from 'firebase/firestore';
import { filterProjectsByState } from './filterProjectsByState';
import { getAscOrderedProjectsByDate } from './getAscOrderedProjectsByDate';
import db from '../backend/DBFiresbase';




export const getProfitsOfActiveProjectsByArea = ( 
                                                  area, 
                                                  contractorCost,  
                                                  materialCost,
                                                  laborCost,
                                                  indirectCost,
                                                  setState ) => {

  const q = query(collection(db, 'proyectos' ), where( "area" , "==", area));

  onSnapshot(q, (querySnapshot) => {
    const docs = [];

    querySnapshot.forEach((doc) => {
        docs.push({...doc.data(), id:doc.id});
    });

    const filteredProjectsInExecution = filterProjectsByState(docs, "Ejecución");

    const projectsOrderedByDate = getAscOrderedProjectsByDate(filteredProjectsInExecution);

    const costs = projectsOrderedByDate.map(el => {
      const contractors = el[contractorCost] ? parseInt(el[contractorCost]) : 0;
      const materials = el[materialCost] ? parseInt(el[materialCost]) : 0;
      const mo = el[laborCost] ? parseInt(el[laborCost]) : 0;
      const cip = el[indirectCost] ? parseInt(el[indirectCost]) : 0;
      const budget = el.presupuesto ? parseInt(el.presupuesto) : 0;

      const profit = budget - mo - contractors - materials - cip;

      return profit;
    });

    setState(costs);
  });
}