
import { TablaRegistroProduccion } from "../../pure/form/TablaRegistroProduccion";
import styles from "./Registros.module.css";


export const RegistroProduccion = () => {

    return (

        <div className={`${ styles.WrapperContenedor } `} >
            <p className="h5 fw-bold align-self-start" >% de Avance de Producción</p>
            <hr className="border border-dark border-1  w-100 m-1"></hr>
            <div className={`${ styles.wrapperTable }`} >
                <TablaRegistroProduccion />
            </div>
        </div>
        
    )
}
