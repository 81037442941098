import moment from "moment";


export const validateCostDates = ( e, user, setError, setActive ) => {
  
  let value = e.target.value;
  
  let costDate = value && moment(value); 

  let currentDate = moment();

  if(value === ""){
    setError('Ingresar fecha del gasto');
    setActive(false);
    return;
  } 

  // Compara si el mes y año de la fecha de gasto es igual al mes y año de la fecha actual
  if (!(costDate.isSame(currentDate, 'month') 
      && costDate.isSame(currentDate, 'year')) 
      && user !== "Developer" 
      && user !== "Administrador" ) {
    setError('Fecha de registro defasada');
    setActive(false);
    return;
  } 

  setError('');
  setActive(true);

}
