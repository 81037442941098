import { doc, getDoc, updateDoc } from "firebase/firestore";
import {  useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { showMessageExit, showMessageError } from "../../../helpers/ShowMessage";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { APIRegiones } from "../../pure/form/APIRegiones";
import { formatRut } from "../../../helpers/formatRut";
import { InputRut } from "../../pure/form/InputRut";
import { InputFormRegister } from "../../pure/form/components";
import { validateInput } from "../../../helpers/validaciones";
import { BtnNavigateDetail } from "../../pure/form/BtnNavigateDetail";
import db from "../../../backend/DBFiresbase";
import getDataCollection from "../../../helpers/getDataCollection";
import styles from './DetailStyles.module.css';



export const ClientesDetail = () => {

  const { id } = useParams();

  const navigate = useNavigate();

  const valoresIniciales = {
    razonSocial: "",
    suministro: "",
    direccion: "",
    comuna: "",
    region: "",
    fechaRegistro: "",
    rut:"",
  }

  const [ valores, setValores ] = useState( valoresIniciales );
  const [ clientes, setClientes ] = useState([]);
  const [ activeRut, setActiveRut ] = useState(true);
  const [ errorRut, setErrorRut ] = useState("");
  const [ activeRegion, setActiveRegion ] = useState(true);
  const [ errorRegion, setErrorRegion ] = useState("");
  const [ activeComuna, setActiveComuna ] = useState(true);
  const [ errorComuna, setErrorComuna ] = useState("");
  const [ activeDireccion, setActiveDireccion ] = useState(true);
  const [ errorDireccion, setErrorDireccion ] = useState('');
  const [ activeGiro, setActiveGiro ] = useState(true);
  const [ errorGiro, setErrorGiro ] = useState('');

  const getDataClienteDB = async () => {
    const docRef = doc(db, "clientes", id);
    const docSnap = await getDoc(docRef);

    try {
      if (docSnap.exists()) {
        setValores(docSnap.data())
      }
    } catch (error) {
      console.error(error)
    }
  }


  //Obteniendo el valor del input
  const handleInput = (e) => {
    const {name, value} = e.target;
    setValores({...valores, [name]:value.toUpperCase()});
  }

  const handleInputAPI = (e) => {
    const {name, value} = e.target;
    setValores({...valores, [name]:value});
  }

  const handleRut = (e) => {
    const {name, value} = e.target;
    const formatValue = formatRut(value);
    setValores({ ...valores, [name]:formatValue });
  }


  //Envío de formulario
  const handleSubmit = (e) => {
    e.preventDefault();

    if( activeRut &&
        activeRegion &&
        activeComuna &&
        activeDireccion &&
        activeGiro ){
      showMessageExit("¡Cliente actualizado con éxito!");
      const proyectoActualizado = doc(db, "clientes", id);
      updateDoc(proyectoActualizado, valores );
    } else {
      !activeRut && setErrorRut('Ingresar RUT');
      !activeRegion && setErrorRegion('Seleccione una Región');
      !activeComuna && setErrorComuna('Seleccione una Comuna');
      !activeDireccion && setErrorDireccion('Ingrese Dirección');
      !activeGiro && setErrorGiro('Ingrese un Giro');
      showMessageError("¡Error!, tiene una casilla sin llenar", "bottom-center");
    }
  }


  useEffect(() => {
    getDataCollection("clientes", setClientes)
  }, [])

  useEffect(() => {
    getDataClienteDB();
  }, []);



  return (
    <form 
      className={`row g-4 mx-auto mt-2 rounded ${styles.Form}`} 
      onSubmit={ handleSubmit }
      autoComplete="off">

      <p className="text-center mb-0" > { valores.razonSocial } </p>

      <div className="m-0 col-md-3">
        <label className={`form-label ${styles.labelForm}`}>Razón Social del Cliente:</label>
        <input 
          type="text"
          className={`form-control ${ styles.inputForm }`} 
          name="nombre"
          defaultValue={ valores.razonSocial } 
          readOnly 
        />
          <span className={ styles.error }>Este dato no puede ser editado</span>
      </div>

      <div className="m-0 col-md-3">
        <InputRut 
          handleRut={ handleRut }
          setActiveRut={ setActiveRut }
          setErrorRut={ setErrorRut }
          errorRut={ errorRut }
          activeRut={ activeRut }
          clientes={ clientes }
          valores={ valores } 
        />
      </div>

      <InputFormRegister 
        title='Giro'
        handleChange={ handleInput }
        handleBlur={ (e) => validateInput(e, setErrorGiro, setActiveGiro, 'Ingrese un Giro') }
        name="suministro"
        value={ valores.suministro }
        error={ errorGiro }
        placeholder = 'Ejemplo: Energía Eléctrica'
        firstSymbol=''
        col='3'
        margin='-0'
      />

      <InputFormRegister 
        title='Dirección'
        handleChange={ handleInput }
        handleBlur={(e) => validateInput(e, setErrorDireccion, setActiveDireccion, 'Ingrese una Dirección') }
        name="direccion"
        value={ valores.direccion }
        error={ errorDireccion }
        placeholder = 'Ejemplo: Av. El Parrón 345'
        firstSymbol=''
        col='3'
        margin='-0'
      />

      <APIRegiones 
        handleInput={ handleInputAPI }
        valores={ valores }
        col={ `col-3 m-0` }
        select={`form-select ${ styles.inputForm }`}
        label={`form-label ${styles.labelForm}`}
        activeRegion={ activeRegion }
        activeComuna={ activeComuna }
        errorRegion={ errorRegion }
        errorComuna={ errorComuna }
        setActiveRegion={ setActiveRegion }
        setActiveComuna={ setActiveComuna }
        setErrorRegion={ setErrorRegion }
        setErrorComuna={ setErrorComuna }
      />

      <BtnNavigateDetail handleClick={ () => navigate(-1) }  />

      <ToastContainer/>
    </form>
  )
}
