import styles from '../Form.module.css';


export const BtnsFormRegister = ({
  col= 10,
  cleanInputs,
  handleClick,

}) => {


  return (
    <div className={ `col-${ col } mx-auto  d-flex justify-content-evenly ` }>
      <button
        onClick={ cleanInputs } 
        type='button'
        className={ `btn btn-danger fw-normal ${styles.btnFormRegister}` } 
        data-bs-dismiss="modal"
      >
        Salir
      </button> 
      
      
      <button
        onClick={ handleClick }
        type="submit" 
        className={ `btn fw-normal ${styles.btnPrimary}` } 
      >
        Registrar
      </button>

    </div>
  )
}
