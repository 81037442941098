import { formatNumDoc } from "../../../../helpers/formatNumDoc";
import styles from '../Form.module.css';


export const BuscadorDocs = ({ doc, setDoc, texto = "Buscar documento..." }) => {

  const handleDoc = (e) => {
    const { value } = e.target;
    const formatValue = formatNumDoc(value);
    setDoc(formatValue);
  }

  const handleKeyDown = (e) => {
    if(e.key === 'Enter'){
      e.preventDefault();
    }
  }


  return (
    <div className="input-group ">
      <span className={`input-group-text py-1 px-2 ${ styles.iconBuscador }`}>
        <i className="bi bi-search"></i>
      </span>
      <input
        onChange={ handleDoc }
        onKeyDown={ handleKeyDown }
        value= { doc }
        type="text" 
        className={ `form-control ${ styles.buscador } pe-0 py-1` } 
        placeholder={ texto }
        autoComplete="off"
      />
    </div>
  )
}
