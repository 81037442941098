import { query, collection, onSnapshot, where } from 'firebase/firestore';
import { filterProjectsByState } from './filterProjectsByState';
import { getAscOrderedProjectsByDate } from './getAscOrderedProjectsByDate';
import db from '../backend/DBFiresbase';




export const getFinalProfitsOfActiveProjectsByArea = ( area, setState ) => {
  const q = query(collection(db, 'proyectos' ), where( "area" , "==", area));

  onSnapshot(q, (querySnapshot) => {
    const docs = [];

    querySnapshot.forEach((doc) => {
        docs.push({...doc.data(), id:doc.id});
    });

    const filteredProjectsInExecution = filterProjectsByState(docs, "Ejecución");

    const projectsOrderedByDate = getAscOrderedProjectsByDate(filteredProjectsInExecution);

    const totalCosts = projectsOrderedByDate.map(project => project.gastos.length ? project.gastos : [0]);

    const budgets = projectsOrderedByDate.map(project => project.presupuesto);

    let costs = [];

    totalCosts.forEach( (cost) => {

      cost.length && costs.push(cost
        .map(gasto => gasto ? parseInt(gasto.valor) : 0 )
        .reduce((a,b) => a+b))
    })

    const finalProfits = budgets.map( (valor, indice) => valor - costs[indice] );

    setState(finalProfits.length ? finalProfits : 0);
  });
}
