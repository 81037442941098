import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const showMessageError = (mensaje, position = 'top-center') => {
    
    toast.error(`${mensaje}`, {
        position: position,
        autoClose: 1300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
}

export const showMessageExit = (mensaje) => {
    toast.success(`${mensaje}`, {
        position: "bottom-center",
        autoClose: 800,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
}

