import { query, collection, onSnapshot, where } from 'firebase/firestore';
import db from "../backend/DBFiresbase";

export const getVentas = (estado) => {
  const q = query(collection(db, "proyectos"), where('nombre', '!=', 'EBG Casa Matriz'));

  onSnapshot(q, (querySnapshot) => {
    const docs = [];

    querySnapshot.forEach((doc) => {
        docs.push({...doc.data(), id:doc.id});
    });

    const precioTotal = docs.map(el => el.ventas);

    let lista = [];
    
    precioTotal.forEach( (array) => {
        if(array.length){
            array.map(el => lista.push( el))
        }
    })

    estado(lista.sort((a,b) => new Date(b.fechaRegistro) - new Date(a.fechaRegistro)));

  });
}
