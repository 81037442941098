import { Link } from 'react-router-dom';
import styles from './Form.module.css';

export const BtnEditar = ( { id } ) => {

  return (
    <Link 
      className={`btn py-0 ${ styles.btnEditar}`}
      type="button"
      to={`id/${id}`}>
      <i className="bi bi-pencil"></i>
    </Link>
  )
}
