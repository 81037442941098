import { formatRut } from '../../../helpers/formatRut';
import styles from './Form.module.css';

export const InputRut = ( 
  { 
    handleRut, 
    setActiveRut, 
    setErrorRut, 
    errorRut, 
    activeRut, 
    clientes, 
    valores 
  } ) => {


  const validateRut = (e) => {
    const value = e.target.value;
    const formatValue = formatRut(value);

    const regName = /^[1-9][0-9]{0,1}\.[0-9]{3}\.[0-9]{3}\-[0-9kK]$/;

    if( value !== "" ){
        if( !regName.test(formatValue)){
            setActiveRut(false);
            setErrorRut("El RUT ingresado es incorrecto");
        }
        else {
            if( clientes.some(el => el.rut === formatValue)){
                setActiveRut(false);
                setErrorRut("El RUT ya está registrado");
            } else {
                setActiveRut(true);
                setErrorRut('');
            }
        }
    } else {
        setActiveRut(false);
        setErrorRut('Debe ingresar un RUT');
    }
  }

  return (<>
    <label className={` form-label ${ styles.labelForm } `}>RUT:</label>
    <input
        onChange={ handleRut }
        onBlur={ validateRut }
        name="rut"
        value={ valores.rut }
        type="text" 
        className={ `form-control ${ styles.inputForm } ${ errorRut === "" ? ""  : styles.errorInput } ` }  
        id="nombreCliente"
        placeholder='Ejemplo: 769987653' 
        maxLength={ 12 }
        autoComplete="off"/>
    <span className={ styles.error } >
        {   errorRut !== "" ? 
            errorRut : 
            (
                activeRut ? "" : "Ingrese RUT sin punto ni guión"
            )
        }
    </span>
</> )
}
