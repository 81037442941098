// import moment from "moment";


export const getFechaPago = ( fechaGasto, diasCredito, setState ) => {

  if(diasCredito === 0) {
    setState(fechaGasto);
  } else {
    // Convertir la cadena de fecha en un objeto Date
    const fecha_obj = new Date(fechaGasto);
    // Sumar los días usando el método setDate()
    fecha_obj.setDate(fecha_obj.getDate() + diasCredito);
  
    // Formatear la nueva fecha en formato ISO 8601
    const nueva_fecha_iso = fecha_obj.toISOString().slice(0, 10);

    setState(nueva_fecha_iso);
  }
}
