import { useEffect, useState } from 'react';
import { collection, doc, onSnapshot, orderBy, query, setDoc } from 'firebase/firestore';
import { BtnEditar } from './BtnEditar';
import { BtnNavigateRegisters } from './BtnNavigateRegisters';
import { SelectAnio } from './SelectAnio';
import { SelectMes } from './SelectMes';
import { getListaClientes } from '../../../helpers/getListaClientes';
import { BuscadorRazonSocial, ErrorBusqueda, ModalForm } from './components';
import { CardDataForm } from './CardDataForm';
import { useAuth } from '../../../context/authContext';
import db from '../../../backend/DBFiresbase';
import FormRegistroProyectos from './FormRegistroProyectos';
import moment from 'moment';
import styles from './Form.module.css';
import { BtnDeleteRegister } from './BtnDeleteRegister';



//Cantidad de items para paginación de gastos de Mnao de Obra
const ITEMS_NAVEGACION = 20;

export const TablaRegistroProyectos = () => {

  const { user } = useAuth();

  const [ loadingTable, setLoadingTable ] = useState(false);
  const [ loadingCardDataForm, setLoadingCardDataForm ] = useState(false);
  const [ estadoProy, setEstadoProy ] = useState('');
  const [ cliente, setCliente ] = useState('');
  const [ area, setArea ] = useState('');
  const [ mes, setMes ] = useState('');
  const [ anio, setAnio ] = useState('');
  const [ clientes, setClientes ] = useState([]);
  const [ buscarProyecto, setBuscarProyecto ] = useState("");
  const [ presupuestoTotal, setPresupuestoTotal ] = useState(0);

  //     //? Mostrando los datos en el formulario
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  //Capturando los valores de los inputs del form
  const handleChange = (e, setState) => {
    const { value } = e.target;
    setState(value);
  }

      // Enviando datos a Firebase
  const setData = async (linkObjeto) => {
    const nuevoProyecto = doc(collection(db, "proyectos"));
    await setDoc(nuevoProyecto, linkObjeto);
  }

    // Obteniendo  datos de Firebase
    const [ proyectos, setProyectos ] = useState([]);

    const q = query(collection(db, "proyectos"), orderBy("fechaRegistro", "desc"));

    const getData = async () => {
      onSnapshot(q, (querySnapshot) => {
        const docs = [];

        querySnapshot.forEach((doc) => {
            docs.push({...doc.data(), id:doc.id});
        });

        const listaOrdenada = docs.sort((a,b) => b.fechaRegistro.localeCompare(a.fechaRegistro));
        listaOrdenada.sort((a,b) => new Date(b.fechaRegistro).getTime() - new Date(a.fechaRegistro).getTime() );
        setProyectos(listaOrdenada);

        setTimeout(() => {
          setLoadingTable(true)
        }, 800);
      });
    }

// //todo   Obteniendo la data de cada Proyecto de Firestore para actualizar

  // Mostrando los datos en la interfaz
  useEffect(() => {
    getData();
  }, []);

  const proyectosFiltrados = proyectos?.filter(el => {
    return(
      (!anio || el.anioInicio === anio) &&
      (!mes || el.mesInicio === mes) &&
      (!estadoProy || el.estado === estadoProy) &&
      (!cliente || el.cliente === cliente) &&
      (!area || el.area === area) &&
      (!buscarProyecto || el.nombre.toUpperCase()?.includes(buscarProyecto))
    )
  })



//   //? Separando los datos traídos de Firebase
  useEffect(() => {
    setItems([...proyectosFiltrados].splice(0, ITEMS_NAVEGACION))
    setCurrentPage(0);
  },[ proyectos, estadoProy, mes, anio, cliente, area, buscarProyecto ])


//     //todo Funciones de Navegación
    const nextHandler = () => {
      const totalElementos = proyectosFiltrados.length;
  
      const nextPage = currentPage + 1;
      const firstIndex = nextPage * ITEMS_NAVEGACION;
  
      if(firstIndex >= totalElementos) return;
  
      setItems([...proyectosFiltrados].splice(firstIndex, ITEMS_NAVEGACION));
      setCurrentPage(nextPage);
    }


    const prevHandler = () => {
      const prevPage = currentPage - 1;
      
      if(prevPage < 0) return;
  
      const firstIndex = prevPage * ITEMS_NAVEGACION;
  
      setItems([...proyectosFiltrados].splice(firstIndex, ITEMS_NAVEGACION));
      setCurrentPage(prevPage);
    }

  useEffect(() => {
    getListaClientes(setClientes);
  }, []);


  useEffect(() => {
    setPresupuestoTotal(proyectosFiltrados
                        .reduce((acc, el) => acc + parseInt(el.presupuesto), 0 ));

    setTimeout(() => {
      setLoadingCardDataForm(true);
    }, 800);
  }, [proyectosFiltrados] );


  return (
    <>
      <div className="container d-flex justify-content-evenly mt-3 mx-auto">
        <CardDataForm 
          title='Total Proyectos'
          data={ presupuestoTotal }
          loading={ loadingCardDataForm }
        />
      </div>

      <form className=' mx-auto d-flex justify-content-evenly  my-4 align-items-center'>

        <div className=" form-check d-flex align-items-center flex-column" >
          <BuscadorRazonSocial 
            doc={ buscarProyecto }
            setDoc={ setBuscarProyecto }
            text="Buscar Proyecto..."
          />
        </div>

        <div className='d-flex align-items-center'>
          <SelectAnio 
            className={ `form-select ${ styles.selectForm } text-center pe-0 py-1` }
            eventChange={ (e) => handleChange(e, setAnio) }
            anio= { anio }
          />
        </div>

        <div className=' d-flex align-items-center gap-1'>
          <SelectMes 
            handleChange= { (e) => handleChange(e, setMes) }
            className={ `form-select ${ styles.selectForm } pe-0 py-1` }
          />
        </div>
        <button 
          className={ `btn py-1 ${styles.btnPrimary} fw-normal` } 
          type="button" 
          data-bs-toggle="modal" 
          data-bs-target="#modalRegistroProyectos" 
        >
          Registar
        </button>
      </form>
            

      {/* Modal de Formulario de Registro de Proyectos */}      
      <ModalForm 
        id='modalRegistroProyectos'
        form={ <FormRegistroProyectos setData={setData} /> }
        title='Proyectos'
      />
      <BtnNavigateRegisters 
        prevHandler={ prevHandler }
        currentPage={ currentPage }
        nextHandler={ nextHandler }
      />

      <div className="table-responsive">
        <table className={ `table text-center table-striped rounded-top ${styles.tableXXLarge} mx-auto px-2` }>
          <thead className="rounded-top">
            <tr className={ `${ styles.tableHead }` } >
              {
                user.perfil === 'Developer' &&
                <th>Eliminar</th>
              }
              <th>Editar</th>
              <th>Fecha Inicio</th>
              <th>Fecha Término</th>
              <th>
                <select
                  onChange={ (e) => handleChange(e, setEstadoProy) }
                  className={`form-select ${ styles.selectTable } pe-0 py-1 mx-auto`}
                  name='estadoProy'
                  value={ estadoProy }
                >
                  <option value="">Estado</option>
                  <option value="Ejecución">Ejecución</option>
                  <option value="Finalizado">Finalizado</option>
                </select>
              </th>
              <th>Nombre</th>
              <th>Presupuesto</th>
              <th>
                <select
                  onChange={(e) => handleChange(e, setCliente)}
                  className={ `form-select ${ styles.selectTable } pe-0 py-1 mx-auto` } 
                  name="cliente"
                  value={ cliente }>
                  <option value="">Cliente</option>
                  { clientes.map((el, index) => {
                      return(
                      <option value={el} key={ index + el }> {el} </option>
                      )
                  })}
                </select>
              </th>
              <th>
                <select
                  onChange={ (e) => handleChange(e, setArea) }
                  className={`form-select ${ styles.selectTable } pe-0 py-1 mx-auto`} 
                  name="area"
                  value={area}
                >
                  <option value="">Area</option>
                  <option value="OOCC">OOCC</option>
                  <option value="REMODELACIONES">REMODELACIONES</option>
                  <option value="LICITACIONES">LICITACIONES</option>
                  <option value="MUEBLERIA">MUEBLERIA</option>
                </select>
              </th>
              <th>Descripción</th>
              <th>Dirección</th>
              <th>Comuna</th>
              <th>Región</th>
            </tr>
          </thead>

          {
            loadingTable ? 
              <tbody className={ `table-group-divider ${styles.tableBody} ` }>
                { items.length >= 1 ?
                  
                  items.map((el) => {
                    return(
                      <tr key={ el.id } className="table-secondary" >
                        {
                          user.perfil === 'Developer' &&
                          <td>
                            <BtnDeleteRegister 
                              coleccion="proyectos"
                              id={ el.id } 
                            />
                          </td>
                        }
                        <td>
                          <BtnEditar id={ el.id } />
                        </td>
                        <td  className={ `${styles.thMedium}`} >{moment(el.fechaInicio).format('DD-MM-YYYY')}</td>
                        <td  className={ `${styles.thMedium}`} >{moment(el.fechaTermino).format('DD-MM-YYYY')}</td>
                        <td>{el.estado}</td>
                        <td>{el.nombre}</td>
                        <td  className={ `${styles.thMedium}`} >$ {new Intl.NumberFormat('de-DE').format(el.presupuesto)}</td>
                        <td>{el.cliente}</td>
                        <td>{el.area}</td>
                        <td>{el.descripcion}</td>
                        <td >{el.direccion}</td>
                        <td>{el.comuna}</td>
                        <td>{el.region}</td>
                      </tr>
                    )
                  }) :

                  <ErrorBusqueda 
                    colSpan={ 11 }
                    text='proyectos registrados'
                  />
                }
              </tbody>

            :

            <tbody className={ `table-group-divider ${styles.tableBody}` }>
                <tr   className={ `table-secondary ` }  >
                    <td colSpan="12" > 
                    <div className={ `spinner-border ${styles.tableSpinner}` } role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    </td>
                </tr>
            </tbody>
            }
        </table>
      </div>

    </>
  )
}
