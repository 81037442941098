import * as XLSX from 'xlsx';

export const exportToExcel = (data, filename) => {
  const wb = XLSX.utils.book_new();

  // Crea una hoja de cálculo con los datos de la tabla
  const ws = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, ws, 'Tabla');

  // Descarga el archivo Excel
  XLSX.writeFile(wb, `${filename}.xlsx`);
}
