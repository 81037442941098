

export const validateInput = (e, setError, setActive, mensaje) => {
  const value = e.target.value;
    
        if(value.length === 0){
          setError(mensaje);
          setActive(false);
        } else{
          setError('');
          setActive(true);
        }
}
