import { useEffect, useState } from "react";
import { useAuth } from "../../../context/authContext";
import { useDispatch, useSelector } from "react-redux";
import { getGastos } from "../../../store/slices/thunks";
import { BtnNavigateRegisters } from "./BtnNavigateRegisters";
import { BtnDeleteGasto } from "./BtnDeleteGasto";
import { BtnEditar } from "./BtnEditar";
import { SelectAnio } from "./SelectAnio";
import { SelectMes } from "./SelectMes";
import { CardDataForm } from "./CardDataForm";
import { BuscadorDocs } from "./components/BuscadorDocs";
import { getListaProyectosAsc } from "../../../helpers/getListaProyectosAsc";
import { TBodyLoading, ErrorBusqueda, ModalForm } from "./components";
import getDataCollectionProveedores from "../../../helpers/getDataCollectionProveedores";
import moment from "moment";
import FormRegistroDeGastos from "./FormRegistroDeGastos";
import styles from './Form.module.css';



//Cantidad de items para paginación de gastos de Mnao de Obra
const ITEMS_NAVEGACION = 20;

export const TablaRegistroGastos = () => {

  const { user } = useAuth();

//todo  Obteniendo  todos los gatos de los proyectos con Redux
    const dispacth= useDispatch();
    const { gastos= [] } = useSelector( state => state.gastos );

    useEffect(() => {
      dispacth( getGastos() );
  }, []);
//todo FIN  Obteniendo  todos los gatos de los proyectos con Redux ***

//todo  Clonando los gastos traídos desde Redux *****
  const [ cloneGastos, setCloneGastos ] = useState(gastos);
  const [ anio, setAnio ] = useState("");
  const [ mes, setMes ] = useState("");
  const [ conceptoGasto, setConceptoGasto ] = useState("");
  const [ proyecto, setProyecto ] = useState("");
  const [ proveedor, setProveedor ] = useState("");
  const [ factura, setFactura ] = useState("");
  const [ tipoDocumento, setTipoDocumento ]  = useState("");
  const [ precioGastoTotal, setPrecioGastoTotal ] = useState(0);
  const [ gastosTotalEBG, setGastosTotalEBG ] = useState(0);
  const [ loadingCardDataForm, setLoadingCardDataForm ] = useState(false);
  const [ loadingTable, setLoadingTable ] = useState(false);
  const [ totalNotaCredito, setTotalNotaCredito ] = useState(0);


  //Capturando los valores de los inputs del form
  const handleChange = (e, setState)=>{
    const { value } = e.target;
    setState(value);
  }

  useEffect(() => {
    const listaOrdenada = [...gastos].sort((a,b) => b.fechaRegistro.localeCompare(a.fechaRegistro));
    listaOrdenada.sort((a,b) => new Date(b.fechaRegistro).getTime() - new Date(a.fechaRegistro).getTime() );
    setCloneGastos(listaOrdenada);
  }, [gastos])

//todo Generando los gastos filtrados
  const gastosFiltrados = cloneGastos?.filter(el => {

  return (
    (!proyecto || el.proyecto === proyecto) &&
    (!conceptoGasto || el.concepto === conceptoGasto) &&
    (!proveedor || el.proveedor === proveedor) &&
    (!anio || el.anioGasto === anio) &&
    (!mes || el.mesGasto === mes) &&
    (!factura || el.numDocumento.includes(factura) ) &&
    (!tipoDocumento || el.tipoDocumento === tipoDocumento)
  );
});

//? Mostrando los datos en el formulario
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

//? Separando los datos traídos de Firebase
  useEffect(() => {
    setItems([...gastosFiltrados].splice(0, ITEMS_NAVEGACION))
    setCurrentPage(0);
    setTimeout(() => {
      setLoadingTable(true);
    }, 1000);
  },[ proyecto, conceptoGasto, proveedor, anio, mes, factura, cloneGastos, tipoDocumento ])


//todo Funciones de Navegación
  const nextHandler = () => {
    const totalElementos = gastosFiltrados.length;

    const nextPage = currentPage + 1;
    const firstIndex = nextPage * ITEMS_NAVEGACION;

    if(firstIndex >= totalElementos) return;

    setItems([...gastosFiltrados].splice(firstIndex, ITEMS_NAVEGACION));
    setCurrentPage(nextPage);
  }


  const prevHandler = () => {
    const prevPage = currentPage - 1;
    
    if(prevPage < 0) return;

    const firstIndex = prevPage * ITEMS_NAVEGACION;

    setItems([...gastosFiltrados].splice(firstIndex, ITEMS_NAVEGACION));
    setCurrentPage(prevPage);
  }

  useEffect(() => {
    const gastosNotasCredito = gastosFiltrados?.filter(gasto => gasto.tipoDocumento === 'Nota de Crédito' && gasto.proyecto !== 'EBG Casa Matriz') || 0
    const totalNotaCredito = gastosNotasCredito.reduce((acc, el) => acc + parseInt(el.valor), 0 );
    setTotalNotaCredito(totalNotaCredito);
  }, [gastosFiltrados])


  useEffect(() => {
    const gastosDiferentesEBG = gastosFiltrados?.filter(el => el.proyecto !== 'EBG Casa Matriz') || 0;

    const gastosDiferentesNotaCreditoEBG = gastosDiferentesEBG?.filter(el => el.tipoDocumento !== 'Nota de Crédito') || 0;

    const valorGastoDiferenteNotaCreditoEBG = gastosDiferentesNotaCreditoEBG.reduce((acc, el) => acc + parseInt(el.valor), 0 );

    setPrecioGastoTotal( valorGastoDiferenteNotaCreditoEBG - totalNotaCredito);

  }, [totalNotaCredito, gastosFiltrados])


  useEffect(() => {
    const gastosEBG = gastosFiltrados?.filter(el => el.proyecto === 'EBG Casa Matriz') || 0;
    const gastosEBGDiferentesNotaCredito = gastosEBG?.filter(el => el.tipoDocumento !== 'Nota de Crédito') || 0;
    const notasCreditoEBG = gastosEBG?.filter(el => el.tipoDocumento === 'Nota de Crédito') || 0;

    const valorNotaCreditoEBG = notasCreditoEBG.reduce((acc, el) => acc + parseInt(el.valor), 0 );
    const valorGastoDiferenteNotaCredito = gastosEBGDiferentesNotaCredito.reduce((acc, el) => acc + parseInt(el.valor), 0 );

    setGastosTotalEBG( valorGastoDiferenteNotaCredito - valorNotaCreditoEBG );

    setTimeout(() => {
      setLoadingCardDataForm(true);
    }, 1200)
  }, [gastosFiltrados])

//todo ******** Fin Clonando los gastos traídos desde Redux *****

//todo  Mostrando los datos en la interfaz  *****
  const [ proyectos, setProyectos ] = useState([]);
  const [ proveedores, setProveedores ] = useState([]);

  useEffect(() => {
      getListaProyectosAsc(setProyectos);
  }, []);

  useEffect(() => {
      getDataCollectionProveedores(setProveedores);
  }, []);
//todo FIN   Mostrando los datos en la interfaz  *****


  return (<>
    <div className="container d-flex justify-content-evenly mt-3">
      <CardDataForm 
        title='Total Gastos'
        data={ precioGastoTotal }
        loading={ loadingCardDataForm }
      />

      <CardDataForm 
        title='Total Gastos EBG Casa Matriz'
        data={ gastosTotalEBG }
        loading={ loadingCardDataForm }
      />
    </div>

    <form className=' mx-auto d-flex justify-content-evenly mt-5 mb-4 align-items-center'>

      <div className=" form-check d-flex align-items-center" >
        <BuscadorDocs 
          doc={ factura }
          setDoc={ setFactura }
        />
      </div>

      <div className='d-flex align-items-center'>
        <SelectAnio 
          className={ `form-select ${ styles.selectForm } text-center pe-0 py-1` }
          eventChange={ (e) => handleChange(e, setAnio) }
          anio= { anio }
        />
      </div>

      <div className=' d-flex align-items-center gap-1'>
        <SelectMes 
          handleChange= { (e) => handleChange(e, setMes) }
          className={ `form-select ${ styles.selectForm } pe-0 py-1` }
        />
      </div>

      {
        user.perfil !== 'Gerente' &&
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className={ `btn py-1 ${styles.btnPrimary} fw-normal w-100 ` }
            data-bs-toggle="modal"
            data-bs-target="#modalRegistroGastos"
          >
            Registrar
          </button>
        </div>
      }

    </form>

    {/* Modal de Formulario de Registro */}
    <ModalForm 
      id='modalRegistroGastos'
      form={ <FormRegistroDeGastos /> }
      title='Gastos'
    />

    <BtnNavigateRegisters 
      prevHandler={ prevHandler }
      currentPage={ currentPage }
      nextHandler={ nextHandler }
    />

    <div className="table-responsive">
      <table className={ `table text-center table-striped rounded-top  mx-auto px-2` }>
        <thead className="rounded-top">
          <tr className={ `${ styles.tableHead }` } >
            { 
              (user.perfil === "Developer"  || user.perfil === "Administrador")  &&
              <th>Eliminar</th>
            }
            {
              user.perfil !== 'Gerente' &&
              <th>Editar</th>
            }
            <th>Fecha</th>
            <th>Gasto</th>
            <th>
              <select
                onChange={ (e) => handleChange(e, setConceptoGasto) }
                className={ `form-select ${ styles.selectTable } pe-0 py-1` }
                name="concepto"
                value={ conceptoGasto }
              >
                <option value="">Concepto</option>
                <option value="Arriendos">Arriendos</option>
                <option value="Combustible">Combustible</option>
                <option value="Contratistas">Contratistas</option>
                <option value="EBG Casa Matriz">EBG Casa Matriz</option>
                <option value="EPPs">EPPs</option>
                <option value="Equipos">Equipos</option>
                <option value="Exámenes médicos">Exámenes Médicos</option>
                <option value="Finiquito">Finiquito</option>
                <option value="Fletes">Fletes</option>
                <option value="Mano de Obra">Mano de Obra</option>
                <option value="Mantenimientos">Mantenimientos</option>
                <option value="Materiales y Herramientas">Materiales y Herramientas</option>
                <option value="Multas">Multas</option>
                <option value="Otros">Otros</option>
                <option value="Patentes">Patentes</option>
                <option value="Seguros">Seguros y/o garantías</option>
                <option value="Servicios">Servicios</option>
                <option value="Utiles de aseo">Útiles de aseo</option>
                <option value="Utiles de escritorio">Útiles de escritorio</option>
                <option value="Vehículos">Vehículos(TAG/Mantenc./Leasing)</option>
                <option value="Viáticos">Viáticos</option>
              </select>
            </th>

            <th>
              <select
                onChange={(e) => handleChange(e, setProyecto)}
                className={ `form-select ${ styles.selectTable } pe-0 py-1 mx-auto` } 
                name="proyecto"
                value={ proyecto }>
                <option value="">Proyecto</option>
                  { 
                    proyectos.map((el, index) => {
                      return(
                      <option value={ el } key={index + el}> { el } </option>
                      )
                    })
                  }
              </select>
            </th>

            <th  className={` w-xl-100 w-25`}>
              <select
                onChange={(e) => handleChange(e, setProveedor)}
                className={ `form-select ${ styles.selectTable } pe-0 py-1 mx-auto ` } 
                name="proveedor"
                value={ proveedor }>
                <option value="">Proveedor</option>
                { proveedores.map((el) => {
                    return(
                    <option value={el.razonSocial} key={el.razonSocial}> {el.razonSocial.toUpperCase()} </option>
                    )
                })}
              </select>
            </th>
            <th>
              <select 
                className={ `form-select ${ styles.selectTable } pe-0 py-1 mx-auto `}
                onChange={ (e) => handleChange(e, setTipoDocumento) }
                name="tipoDocumento"
                value={ tipoDocumento }
              >
                <option value="">Tipo Doc</option>
                <option value="Boleta">Boleta</option>
                <option value="Boleta de Honorarios">Boleta de Honorarios</option>
                <option value="Boleta Exenta">Boleta Exenta</option>
                <option value="Comprobante">Comprobante</option>
                <option value="Costo de Oficina">Costo de Oficina</option>
                <option value="Costo de Personal">Costo de Personal</option>
                <option value="Factura">Factura</option>
                <option value="Factura exenta">Factura Exenta de IVA</option>
                <option value="Guía de Despacho">Guía de Despacho</option>
                <option value="Nota de Crédito">Nota de Crédito</option>
                <option value="Nota de Débito">Nota de Débito</option>
                <option value="Recibo">Recibo</option>
              </select>
            </th>
            <th>N° Documento</th>
          </tr>
        </thead>

        {
          loadingTable ? 
            <tbody className={ `table-group-divider ${styles.tableBody}` }>
              { items.length >= 1 ?
                
                items.map((el,index) => {

                  return(
                    <tr key= { index }  className={ `table-secondary ` }  >
                        { 
                          (user.perfil === "Developer"  || user.perfil === "Administrador")  &&
                          <td>
                            <BtnDeleteGasto 
                              proyecto={ `${el.proyecto}` } 
                              documento={ `${el.numDocumento}` }
                            />
                          </td>
                        }
                        {
                          user.perfil !== 'Gerente' &&
                          <td>
                            <BtnEditar id={ `${el.proyecto}-${el.numDocumento}`} />
                        </td>
                        }
                        <td> { moment(el.fechaGasto).format('DD-MM-YYYY') }</td>
                        <td>$ { new Intl.NumberFormat('de-DE').format(el.valor) }</td>
                        <td> { el.concepto } </td>
                        <td> { el.proyecto } </td>
                        <td> { el.proveedor } </td>
                        <td> { el.tipoDocumento } </td>
                        <td> { el.numDocumento } </td>
                    </tr>
                    )
                  }) :

                <ErrorBusqueda 
                  colSpan={ 9 }
                  text='gastos registrados'
                />
              }
            </tbody>

            :

            <TBodyLoading col='9' />
        }

      </table>
    </div>
  </>)
}