import ItemSubmenu from "./ItemSubmenu";
import { useAuth } from '../../../../context/authContext';
import "./Submenu.css"


export default function SubmenuRegistros() {

    const {user} = useAuth();

    return (<>

        <ul className="wrapper_SubmenuContainer">
            <ItemSubmenu titulo="Gastos" ruta="registros/gastos"/>
            <ItemSubmenu titulo="Proveedores" ruta="registros/proveedores"/>

            {  
                (user.perfil === "Developer" || user.perfil === "Gerente") && 
                <ItemSubmenu titulo="Clientes" ruta="registros/clientes"/>
            }

            {  
                (user.perfil === "Developer" || user.perfil === "Gerente") && 
                <ItemSubmenu titulo="Proyectos" ruta="registros/proyectos"/>
            }

            {   
                (user.perfil === "Developer" || user.perfil === "Gerente") && 
                <ItemSubmenu titulo="Ventas" ruta="registros/ventas"/> 
            }

            {
                (user.perfil === "Developer" || user.perfil === "Gerente") && 
                <ItemSubmenu titulo="Producción" ruta="registros/produccion"/> 
            }
            {/* <ItemSubmenu titulo="Mano de Obra" ruta="registros/mano-de-obra"/>
            <ItemSubmenu titulo="Colaboradores" ruta="registros/colaboradores"/> */}
        </ul>

    </>)
}
