import { useState } from "react";

import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { ToastContainer } from "react-toastify";

import { showMessageError, showMessageExit } from "../../../helpers/ShowMessage";



export const FormResetPass = ( ) => {

  const [ email, setEmail ] = useState('');

  const onChange = (e) => {
    const value = e.target.value;
    setEmail(value)
  }

  const onSubmit = (e) => {
    e.preventDefault();


    if ( email === '' ) {
      showMessageError("Ingrese un correo válido");
      return
    };

    const auth = getAuth();

    sendPasswordResetEmail(auth, email)
      .then(() => {
        showMessageExit("Envío exitoso, revise su bandeja de correo")
        setEmail('');
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          showMessageError("Usuario no registrado o incorrecto");
      }
      });
  }

  return (
    <form 
      className="p-4 d-flex flex-column gap-4 align-items-center justify-content-evenly form_login "
      onSubmit={ onSubmit }
    >
      <span className="text_reset_pass" >Ingresa tu correo electrónico y te enviaremos un enlace para restablecer tu contraseña.</span>

      <div className="w-100">
        <input
          onChange={ onChange }
          type="email"
          className={`form-control`} 
          name="email"
          value={ email }
          placeholder="Ingresar email"
        />
        <label className="form-label mt-1">EMAIL</label>
      </div>

      <button className="btn btn-light w-75">
        ENVIAR
      </button>
      <ToastContainer/>
    </form>
  )
}
