import { useState } from 'react';
import { APIRegiones } from './APIRegiones';
import { ToastContainer } from "react-toastify";
import { showMessageError, showMessageExit } from '../../../helpers/ShowMessage';
import { formatRut } from '../../../helpers/formatRut';
import { InputRut } from './InputRut';
import { validateInput } from '../../../helpers/validaciones';
import { BtnsFormRegister, InputFormRegister } from './components';
import moment from 'moment';
import styles from './Form.module.css';



export default function FormRegistroClientes( { setData, clientes } ) {

    const valoresIniciales = {
        razonSocial: "",
        suministro: "",
        direccion: "",
        comuna: "",
        region: "",
        fechaRegistro: "",
        rut:"",
    }

    const [ valores, setValores ] = useState(valoresIniciales);
    const [ fechaRegistro, setFechaRegistro ] = useState("");
    const [ activeRazonSocial, setActiveRazonSocial ] = useState(false);
    const [ errorRazonSocial, setErrorRazonSocial ] = useState("");
    const [ activeRut, setActiveRut ] = useState(false);
    const [ errorRut, setErrorRut ] = useState("");
    const [ activeRegion, setActiveRegion ] = useState(false);
    const [ errorRegion, setErrorRegion ] = useState("");
    const [ activeComuna, setActiveComuna ] = useState(false);
    const [ errorComuna, setErrorComuna ] = useState("");
    const [ activeDireccion, setActiveDireccion ] = useState(false);
    const [ errorDireccion, setErrorDireccion ] = useState('');
    const [ activeGiro, setActiveGiro ] = useState(false);
    const [ errorGiro, setErrorGiro ] = useState('');


    const handleInput = (e) => {
        const {name, value} = e.target;
        setValores({ ...valores, [name]:value.toUpperCase()});
    }
    
    const handleInputAPI = (e) => {
        const {name, value} = e.target;
        setValores({ ...valores, [name]:value });
    }

    const handleRut = (e) => {
        const {name, value} = e.target;
        const formatValue = formatRut(value);
        setValores({ ...valores, [name]:formatValue });
    }


    const validateRazonSocial = (e) => {
        const value = e.target.value;
        const regName = /^[a-zA-Z0-9]+([\-\. ]?[a-zA-Z0-9]+)*\.?$/;

        if( value.trim() !== "" ){
            if( !regName.test(e.target.value.trim())){
                setActiveRazonSocial(false);
                setErrorRazonSocial("La Razón Social es incorrecta");
            } else {
                setActiveRazonSocial(true);
                setErrorRazonSocial('');
            }
        } else {
            setActiveRazonSocial(false);
            setErrorRazonSocial("Debe ingresar una razón social");
        }
    }

    //Limpiando los errores al Salir
    const cleanInputs = () => {
        setErrorRazonSocial('');
        setActiveRazonSocial(false);
        setErrorRut('');
        setActiveRut(false);
        setErrorRegion('');
        setErrorComuna('');
        setActiveComuna(false);
        setActiveRegion(false);
        setErrorDireccion('');
        setActiveDireccion(false);
        setErrorGiro('');
        setActiveGiro(false);
        setValores(valoresIniciales);
    }


    //Envianda el registro del formulario a Firebase
    const handleSubmit = (e) => {
        e.preventDefault();
        const mes = moment(valores.fecha).format("MMMM");
        const anio = moment(valores.fecha).format("YYYY");

        if ( activeRut && 
            activeRazonSocial &&
            activeRegion &&
            activeComuna &&
            activeDireccion &&
            activeGiro) {
            
            showMessageExit("Registro exitoso");

            setData({
                ...valores, 
                fechaRegistro: fechaRegistro, 
                mes: mes, 
                anio: anio,
            });
            cleanInputs();
            
        } else {
            !activeRut && setErrorRut('Ingresar RUT');
            !activeRazonSocial && setErrorRazonSocial('Ingresar Razón Social');
            !activeRegion && setErrorRegion('Seleccione una Región');
            !activeComuna && setErrorComuna('Seleccione una Comuna');
            !activeDireccion && setErrorDireccion('Ingrese Dirección');
            !activeGiro && setErrorGiro('Ingrese un Giro');
            showMessageError("¡Error!, tiene una casilla sin llenar", "bottom-center");
        }
    }


    const handleClick = () => {
        setFechaRegistro(moment().format('YYYY-MM-DD HH:mm:ss'));
    }


    return (<>

        <form 
            className="row g-4"
            onSubmit={ handleSubmit }
            autoComplete="off">
            
{/* //?       ****  Datos  generales  **** */}
            <InputFormRegister 
                title='Razón Social'
                handleChange={ handleInput }
                handleBlur={ validateRazonSocial }
                name="razonSocial"
                value={ valores.razonSocial }
                error={ errorRazonSocial }
                placeholder = 'Ejemplo: ENEL'
                firstSymbol=''
            />

            <div className="col-md-4">
                <InputRut 
                    handleRut={ handleRut }
                    setActiveRut={ setActiveRut }
                    setErrorRut={ setErrorRut }
                    errorRut={ errorRut }
                    activeRut={ activeRut }
                    clientes={ clientes }
                    valores={ valores } 
                />
            </div>

            <InputFormRegister 
                title='Giro'
                handleChange={ handleInput }
                handleBlur={ (e) => validateInput(e, setErrorGiro, setActiveGiro, 'Ingrese un Giro') }
                name="suministro"
                value={ valores.suministro }
                error={ errorGiro }
                placeholder = 'Ejemplo: Energía Eléctrica'
                firstSymbol=''
            />

            <InputFormRegister 
                title='Dirección'
                handleChange={ handleInput }
                handleBlur={(e) => validateInput(e, setErrorDireccion, setActiveDireccion, 'Ingrese una Dirección') }
                name="direccion"
                value={ valores.direccion }
                error={ errorDireccion }
                placeholder = 'Ejemplo: Av. El Parrón 345'
                firstSymbol=''
            />

            <APIRegiones 
                handleInput={ handleInputAPI }
                valores={ valores }
                activeRegion={ activeRegion }
                activeComuna={ activeComuna }
                errorRegion={ errorRegion }
                errorComuna={ errorComuna }
                setActiveRegion={ setActiveRegion }
                setActiveComuna={ setActiveComuna }
                setErrorRegion={ setErrorRegion }
                setErrorComuna={ setErrorComuna }
                label={` form-label ${ styles.labelForm } `}
                select={`form-select ${ styles.inputForm }`}
            />

            <BtnsFormRegister 
                cleanInputs={ cleanInputs }
                handleClick={ handleClick }
            />

            <ToastContainer />
        </form>
    </>)
}
