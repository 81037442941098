import { query, collection, onSnapshot, where } from 'firebase/firestore';
import { filterProjectsByState } from './filterProjectsByState';
import { getAscOrderedProjectsByDate } from './getAscOrderedProjectsByDate';
import db from '../backend/DBFiresbase';


export const getFinalCostsOfActiveProjectsByArea = ( area, concept, setState  ) => {

  const q = query(collection(db, 'proyectos' ), where( "area" , "==", area));

  onSnapshot(q, (querySnapshot) => {
    const docs = [];

    querySnapshot.forEach((doc) => {
        docs.push({...doc.data(), id:doc.id});
    });

    const filteredProjectsInExecution = filterProjectsByState(docs, "Ejecución");

    const projectsOrderedByDate = getAscOrderedProjectsByDate(filteredProjectsInExecution);

    const costs = projectsOrderedByDate.map(project => project.gastos.length ? project.gastos : [0]);

    let conceptCosts = [];

    costs.forEach( (array) => {
      conceptCosts.push(array.filter(cost => cost.concepto === concept).reduce((acc, cost) => acc + parseInt(cost.valor), 0))
    })

    setState(conceptCosts.length ? conceptCosts : 0);
  });

}
