import { query, collection, onSnapshot, where} from 'firebase/firestore';
import db from '../backend/DBFiresbase';

const getSumaPresupuesto = (coleccion, estado) => {

    onSnapshot(query(collection(db,coleccion), where("nombre", "!=", "EBG Casa Matriz")), (querySnapshot) => {
        const docs = [];
        let suma = 0;

        querySnapshot.forEach((doc) => {
            docs.push({...doc.data(), id:doc.id});
        });

        const precioTotal = docs.map(el => el.presupuesto);
            
        precioTotal.forEach((el) => {
            return suma += parseInt(el);
        });

        estado(suma);
    });
}

export default getSumaPresupuesto