

export const getAscOrderedProjectsByDate = (projects) => {
  
  const projectsOrdered = projects.sort((a,b) => a.fechaRegistro.localeCompare(b.fechaRegistro));
  
  const checkProjects = projectsOrdered.sort((a,b) => new Date(a.fechaRegistro).getTime() - new Date(b.fechaRegistro).getTime() );
  
  return checkProjects

}
