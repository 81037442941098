import { useEffect, useState } from 'react';
import { getListaVentasEnEjecucion } from '../getListaVentasEnEjecucion';
import { SpinnerCard } from '../spinner/SpinnerCard';
import { getActiveProjectsNames } from '../getActiveProjectsNames';
import Chart from 'react-apexcharts';
import getAvanceFacturado from '../getAvanceFacturado';
import getAvanceProduccion from '../getAvanceProduccion';

import styles from './Graficos.module.css';


export const GraficoBarraDobleAvances = (  ) => {

    const [ listaProyectos, setListaProyectos ] = useState([]);
    const [ produccion, setProduccion ] = useState([]);
    const [ facturado, setFacturado ] = useState([]);
    const [ listaVentas, setListaVentas ] = useState([]);

    const [ loading, setLoading ] = useState(true);


    const options = {
        colors: ['#008ffb','#0faa16'],
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false,
            },
        },
        legend:{
            fontWeight: 500,
            fontSize: '13px',
            position: 'top',
            horizontalAlign: 'center',
            itemMargin: {
                horizontal: 20,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
                columnWidth: '55%',
                endingShape: 'rounded',
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val + "%";
            },
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: listaProyectos,
            labels: {
                style: {
                    fontSize: "12px",
                    fontWeight: "600",
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: "12px",
                    fontWeight: "600",
                },
            },
        },
        fill: {
            opacity: 1
        },
        tooltip: {

            y: {
                formatter: function (value, {  seriesIndex, dataPointIndex, w } ) {
                    return  ("$ " +  new  Intl.NumberFormat('de-DE').format(listaVentas[dataPointIndex]));
                }
            },
        }
    }


    const series = [
        {
            name: '% Ventas',
            data: facturado,
        }, 
        {
            name: '% Producción',
            data: produccion
        },
    ]

    useEffect(() => {
        getActiveProjectsNames(setListaProyectos);
    }, [])

    useEffect(() => {
        getAvanceProduccion("proyectos", setProduccion);
    }, [])

    useEffect(() => {
        getAvanceFacturado("proyectos", setFacturado);
    }, [])

    useEffect(() => {
        getListaVentasEnEjecucion("proyectos", setListaVentas);        
    }, [])

    setTimeout(() => {
        setLoading(false);
    }, 500);


    return (<>
        {
            loading 
            
                ?

            <div className={ `w-100 h-100 d-flex justify-content-center align-items-center ${ styles.wrapperLoading }` } >
                <SpinnerCard />
            </div>

                :

                <Chart 
                options={options}
                series={series}
                type="bar"
                height={450}
            />
        }
        
        </>)
}
