import { TablaRegistroClientes } from '../../pure/form/TablaRegistroClientes';
import styles from './Registros.module.css';



export default function RegistroClientes() {

    return (
        <div className={ styles.WrapperContenedor } >
            <p className="h5 fw-bold align-self-start" >Registros de Clientes</p>
            <hr className="border border-dark border-1  w-100 m-1"></hr>

            <div className={`${ styles.wrapperTable }`} >
                <TablaRegistroClientes />
            </div>
        </div>
    )
}
