import { query, collection, onSnapshot, where } from 'firebase/firestore';
import db from '../backend/DBFiresbase';

export const getActiveProjectsNames = ( setState ) => {

    const q = query(collection(db,"proyectos"), where("estado", "==", "Ejecución"));

    onSnapshot( q, (querySnapshot) => {
        const docs = [];

        querySnapshot.forEach((doc) => {
            docs.push({...doc.data(), id:doc.id});
        });

        const projectsOrderedByDate = docs.sort((a,b) => a.fechaRegistro.localeCompare(b.fechaRegistro));
        projectsOrderedByDate.sort((a,b) => new Date(a.fechaRegistro).getTime() - new Date(b.fechaRegistro).getTime() );

        let projectNames = projectsOrderedByDate.map(el => [ el.nombre,  "$ " + new Intl.NumberFormat('de-DE').format(el.presupuesto)])

        setState(projectNames);
    });

}

