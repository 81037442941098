import { TablaReporteGastos } from "../../pure/form/TablaReporteGastos";
import styles from './Reportes.module.css';


export const ReporteGastos = () => {

  return (
    <div className={ styles.WrapperContenedor } >
      <p className="h5 fw-bold align-self-start" >Reporte de  Gastos</p>
      <hr className="border border-dark border-1  w-100 m-1"></hr>

      <div className={`${ styles.wrapperTable }`} >
        <TablaReporteGastos />
      </div>

    </div>
  );
}
