import { useState } from "react";
import { formatRut } from "../../../../helpers/formatRut";
import styles from '../Form.module.css';

export const BuscadorRut = ({ 
    doc,
    setDoc,
  }) => {

  const [errorRut, setErrorRut] = useState(true);

  const handleDoc = (e) => {
    const { value } = e.target;
    const formatValue = formatRut(value);
    setDoc(formatValue);
  }

  const handleKeyDown = (e) => {
    if(e.key === 'Enter'){
      e.preventDefault();
    }
  }

  const validateRut = (e) => {
    const value = e.target.value;
    const formatValue = formatRut(value);

    const regName = /^[1-9][0-9]{0,1}\.[0-9]{3}\.[0-9]{3}\-[0-9kK]$/;

    if( value !== "" ){
      if( !regName.test(formatValue)){
          setErrorRut(false);
      }
      else {
        setErrorRut(true);
      }
    } else {
      setErrorRut(true)
    }
  }

  return (<>
    <div className="input-group ">
      <span className={`input-group-text py-1 px-2 ${ styles.iconBuscador }`}>
        <i className="bi bi-search"></i>
      </span>
      <input
        onChange={ handleDoc }
        onKeyDown={ handleKeyDown }
        onBlur={ validateRut }
        name="rut"
        value={ doc }
        type="text" 
        className={ `form-control ${ styles.buscador } pe-0 py-1` }   
        placeholder="Buscar RUT..." 
        maxLength={ 12 }
        autoComplete="off"
        />
    </div>
    <span className={ `${styles.error} mt-1`  } >
      { !errorRut 
        ? "El RUT ingresado es incorrecto"
        : 
          (
            doc.length >= 8 ? "" : "Ingrese RUT sin punto ni guión"
          )
      }
    </span>
  </>
  )
}
