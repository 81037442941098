import {query, collection, onSnapshot, orderBy, where} from 'firebase/firestore';
import db from '../backend/DBFiresbase';



export const getUtilidadesProy = ( estado ) => {
    const q = query(collection(db,"proyectos"), where("estado", "==", "Ejecución"), orderBy("fechaRegistro", "asc"));

    onSnapshot(q, (querySnapshot) => {
        const docs = [];

        querySnapshot.forEach((doc) => {
            docs.push({...doc.data(), id:doc.id});
        });

        const listaCostos = docs.map(el => {
            const contratistas = el.costoContratistasProyectado ? parseInt(el.costoContratistasProyectado) : 0;
            const materiales = el.costosMaterialesProyectado ? parseInt(el.costosMaterialesProyectado) : 0;
            const mo = el.costoManoObraProyectado ? parseInt(el.costoManoObraProyectado) : 0;
            const cip = el.costosIndirectosProyectado ? parseInt(el.costosIndirectosProyectado) : 0;
            const presupuesto = el.presupuesto ? parseInt(el.presupuesto) : 0;

            const utilidad = presupuesto - mo - contratistas - materiales - cip;

            return utilidad;
        });

        estado(listaCostos);
    });
}