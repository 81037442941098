

export const getSumFilteredExpense = (costs) => {

  const totalCosts = []

  let costsFiltered = costs?.map(gasto => gasto.filter(el => el.tipoDocumento === 'Nota de Crédito').length >= 1
                        ?
                          gasto.filter(el => el.tipoDocumento === 'Nota de Crédito') 
                        : 0 
                      );

  costsFiltered.forEach( (array) => {
    array.length && totalCosts.push(array.map(cost => parseInt(cost.valor)).reduce((a,b) => a+b))
  });

  if( totalCosts.length ) return totalCosts.reduce((a,b) => a+b);
  
  return 0;
}
