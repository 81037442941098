import { collection, onSnapshot, query} from "firebase/firestore";
import { setGastos, setProyectos, startLoadingGastos } from "./gastosSlice";
import db from "../../backend/DBFiresbase";




export const getGastos = () => {

    return async( dispacth, getState) => {

        dispacth( startLoadingGastos() );
        
        // Traer gastos de firestore
        const q = query(collection(db, "proyectos"));

        const getData = async () => {
            onSnapshot(q, (querySnapshot) => {
                const docs = [];
        
                querySnapshot.forEach((doc) => {
                    docs.push({...doc.data(), id:doc.id});
                });

                const listaProyectos = docs.map(el => el.nombre);
                const listaGastos = docs.map(el => el.gastos );
                let lista = [];

                listaGastos.forEach( (array) => {
                    if(array.length){
                        array.map(el => lista.push( el))
                    }
                })

                // const listaProyectosOrdenados = listaProyectos.sort((a,b) => new Date(b.fechaRegistro) - new Date(a.fechaRegistro))
                const Gastos = lista.sort((a,b) => new Date(b.fechaRegistro) - new Date(a.fechaRegistro));

                dispacth( setProyectos({ proyectos: listaProyectos }));
                dispacth( setGastos({ gastos: Gastos }) );

            });
        }

        getData();
    }
}
