import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { getListaGastoMO } from "../../../helpers/getListaGastoMO";
import { ToastContainer } from "react-toastify";
import { formatNumBonos } from "../../../helpers/formatNumBonos";
import { formatNumDoc } from "../../../helpers/formatNumDoc";
import { getDataTrabajador } from "../../../helpers/getDataTrabajador";
import { getDescuentos } from "../../../helpers/getDescuentos";
import { getImposiciones } from "../../../helpers/getImposiciones";
import { showMessageError, showMessageExit } from "../../../helpers/ShowMessage";
import { InputFormRegister } from "../../pure/form/components";
import { BtnNavigateDetail } from "../../pure/form/BtnNavigateDetail";
import getIDDoc from "../../../helpers/getIDDoc";
import db from "../../../backend/DBFiresbase";
import moment from "moment";
import styles from './DetailStyles.module.css';




export const ManoObraDetail = () => {

  const { id } = useParams();

  const navigate = useNavigate();


  const valoresIniciales = {
    fechaRegistro:"",
    fechaGasto:"",
    mesGasto: "",
    anioGasto: "",
    proyecto:"",
    nombre_trabajador: "",
    dias_trabajados: 0,
    horas_no_trabajadas:0,
    horas_extras:0,
    asig_herramientas:0,
    bono_produccion: 0,
    aguinaldo: 0,
    bono_asistencia: 0,
    bono_seguridad: 0,
    total_imponible:"",
    total_no_imponible: 0,
    valor: 0,
    concepto:"Mano de Obra",
    tipo:"Fijo",
  }

  const proyecto = id.split("-")[0];
  const idDoc = `${id.split("-")[1]}-${id.split("-")[2]}-${id.split("-")[3]}`;

  const [ dataGasto, setDataGasto ] = useState(valoresIniciales);
  const [ idProyecto, setIdProyecto ] = useState("");
  const [ nombreTrabajador, setNombreTrabajador ] = useState("");
  const [ idTrabajador, setIdTrabajador ] = useState("");
  const [ dataTrabajador, setDataTrabajador ] = useState({});
  const [ sueldoBase, setSueldoBase ] = useState(0);
  const [ gratificacion, setGratificacion ] = useState(0);
  const [ bonoSeguridad, setBonoSeguridad ] = useState(0);
  const [ bonoAsistencia, setBonoAsistencia ] = useState(0);
  const [ bonoObjetivo, setBonoObjetivo ] = useState(0);
  const [ colacion, setColacion ] = useState(0);
  const [ movilizacion, setMovilizacion ] = useState(0);
  const [ totalImponible, setTotalImponible ] = useState(0);
  const [ totalNoImponible, setTotalNoImponible ] = useState(0);
  const [ afp, setAfp ] = useState(0);
  const [ salud, setSalud ] = useState(0);
  const [ cesantia, setCesantia ] = useState(0);
  const [ sis, setSis ] = useState(0);
  const [ mutual, setMutual ] = useState(0);
  const [ activeFecha, setActiveFecha ] = useState(true);
  const [ errorFecha, setErrorFecha ] = useState('');
  const [ activeDias, setActiveDias ] = useState(true);
  const [ errorDias, setErrorDias ] = useState('');
  const [ activeBonoSeguridad, setActiveBonoSeguridad ] = useState(true)
  const [ errorBonoSeguridad, setErrorBonoSeguridad ] = useState("");
  const [ activeBonoAsistencia, setActiveBonoAsistencia ] = useState(true);
  const [ errorBonoAsistencia, setErrorBonoAsistencia ] = useState("");
  const [ activeBonoObjetivo, setActiveBonoObjetivo ] = useState(true);
  const [ errorBonoObjetivo, setErrorBonoObjetivo ] = useState("");
  const [ activeHorasNoTrabajadas, setActiveHorasNoTrabajadas ] = useState(true);
  const [ errorHorasNoTrabajadas, setErrorHorasNoTrabajadas ] = useState("");
  const [ activeHorasExtras, setActiveHorasExtras ] = useState(true);
  const [ errorHorasExtras, setErrorHorasExtras ] = useState("")
  const [ activeAsigHerramientas, setActiveAsigHerramientas ] = useState(true);
  const [ errorAsigHerramientas, setErrorAsigHerramientas ] = useState("");
  const [ activeBonoProduccion, setActiveBonoProduccion ] = useState(true);
  const [ errorBonoProduccion, setErrorBonoProduccion ] = useState("");
  const [ activeAguinaldo, setActiveAguinaldo ] = useState(true);
  const [ errorAguinaldo, setErrorAguinaldo ] = useState("");


  //Obteniendo el valor del input
  const handleInput = (e) => {
    const {name, value} = e.target;
    setDataGasto({...dataGasto, [name]:value});
  }

  const handleInputNumeric = (e) => {
    const {name, value} = e.target;
    const formatValue = formatNumBonos(value); 
    setDataGasto({ ...dataGasto, [name]:formatValue });
}

  const handleInputDias = (e) => {
    const {name, value} = e.target;
    const formatValue = formatNumDoc(value); 
    setDataGasto({ ...dataGasto, [name]:formatValue });
  }

  const validateInput = (e, setError, setActive, mensaje) => {
    const value = e.target.value;

    if(value === ""){
      setError(mensaje);
      setActive(false);
    } else{
      setError('');
      setActive(true);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const proyectoRef = doc(db, "proyectos", idProyecto);
    const docRef = await getDoc(proyectoRef);

    // Actualiza el array en el documento
    if( activeFecha &&
        activeDias &&
        activeBonoSeguridad &&
        activeBonoAsistencia &&
        activeBonoObjetivo &&
        activeHorasNoTrabajadas &&
        activeHorasExtras &&
        activeAsigHerramientas &&
        activeBonoProduccion &&
        activeAguinaldo){
        
          const gastoIndex = docRef.data().gastos.findIndex(el => el.fechaRegistro === idDoc );
          const updatedGasto = {
            ...docRef.data().gastos[gastoIndex],
            fechaGasto: dataGasto.fechaGasto,
            dias_trabajados: dataGasto.dias_trabajados,
            bono_seguridad: dataGasto.bono_seguridad,
            bono_asistencia: dataGasto.bono_asistencia,
            bono_objetivo: dataGasto.bono_objetivo,
            horas_no_trabajadas: dataGasto.horas_no_trabajadas,
            horas_extras: dataGasto.horas_extras,
            asig_herramientas: dataGasto.asig_herramientas,
            bono_produccion: dataGasto.bono_produccion,
            aguinaldo: dataGasto.aguinaldo,
            mesGasto: moment(dataGasto.fechaGasto).format("MMMM"),
            anioGasto: moment(dataGasto.fechaGasto).format("YYYY"),
            afp: afp,
            prevision_salud: salud,
            cesantia: cesantia,
            mutual: mutual,
            sis: sis,
            total_imponible: totalImponible,
            total_no_imponible: totalNoImponible,
            valor: (  parseInt(totalImponible) + parseInt(totalNoImponible) 
                    + parseInt(afp) + parseInt(salud) + parseInt(sis) + parseInt(cesantia) + parseInt(mutual)),
            gratificacion: gratificacion,
            movilizacion: movilizacion,
            colacion: colacion,
            sueldo_base: sueldoBase
          }

          //Registrando el nuevo gasto editado
        const updateGastos = [...docRef.data().gastos];
        updateGastos[gastoIndex] = updatedGasto;

        //Actualizando el gasto en Firestore
        showMessageExit("Actualización  exitosa");
        updateDoc(proyectoRef, {gastos: updateGastos});
        } else {
          showMessageError("¡Error!, tiene una casilla sin llenar", "bottom-center");
        }
  }

  useEffect(() => {
    getListaGastoMO(proyecto, idDoc , setDataGasto)
  }, []);


  useEffect(() => {
    if(proyecto === ""){
      return
    } else {
      getIDDoc("proyectos", proyecto, setIdProyecto)
    };
    
  }, [proyecto])

  //todo   Obteniendo ID del Trabajador

  useEffect(() => {
    if(dataGasto.nombre_trabajador === '') {
      return
    } else {
      setNombreTrabajador(dataGasto.nombre_trabajador);
    }
  }, [ dataGasto.nombre_trabajador])

  useEffect(() => {
    if(nombreTrabajador === ""){
      return
    } else {
      getIDDoc("colaboradores", nombreTrabajador, setIdTrabajador )
    };
  },[nombreTrabajador]);

// //todo   Obteniendo Data del trabajador desde Firestore
  useEffect(() => {
    if(idTrabajador === ""){
      return
    } else {
      getDataTrabajador(idTrabajador, setDataTrabajador);
    }

  },[idTrabajador]);



  //todo   Calculando sueldo base, gratificación y bonos
  useEffect(() => {
    if(Object.keys(dataTrabajador).length === 0) return;

    setSueldoBase( getImposiciones( dataGasto.dias_trabajados, dataTrabajador.sueldo_base ));
    setGratificacion( getImposiciones( dataGasto.dias_trabajados, dataTrabajador.gratificacion_legal ));
    setBonoSeguridad(  getImposiciones( dataGasto.dias_trabajados, dataTrabajador.bono_seguridad ) );
    setBonoAsistencia( getImposiciones( dataGasto.dias_trabajados, dataTrabajador.bono_asistencia ) );
    setBonoObjetivo(  getImposiciones( dataGasto.dias_trabajados, dataTrabajador.bono_objetivo ) );
    setColacion( getImposiciones( dataGasto.dias_trabajados, dataTrabajador.colacion));
    setMovilizacion( getImposiciones( dataGasto.dias_trabajados, dataTrabajador.movilizacion));

  },[ dataTrabajador,  dataGasto.dias_trabajados, ]);


// //todo   Calculo del Imponible
  useEffect(() => {
    if( dataGasto.dias_trabajados === "" ) {
        setActiveDias(false);
        return
    }else{
        setActiveDias(true);
    };

    setTotalImponible(
      sueldoBase + 
      gratificacion +
      (bonoSeguridad ? parseInt(bonoSeguridad) : 0) +
      (bonoAsistencia ? parseInt(bonoAsistencia) : 0) + 
      parseInt(dataGasto.bono_objetivo) +
      (dataGasto.bono_produccion.length > 1 ? parseInt(dataGasto.bono_produccion) : 0) + 
      (dataGasto.horas_extras.length > 1 ? parseInt(dataGasto.horas_extras) : 0) -
      (dataGasto.horas_no_trabajadas.length > 1 ? parseInt(dataGasto.horas_no_trabajadas) : 0));

  },[ dataGasto.dias_trabajados,
    dataGasto.bono_produccion,
    dataGasto.horas_extras,
    dataGasto.horas_no_trabajadas,
    dataGasto.bono_objetivo,
    sueldoBase, 
    gratificacion, 
    bonoSeguridad,
    bonoAsistencia,
    bonoObjetivo ]);


// //todo   Calculo del NO Imponible
  useEffect(() => {
    if( dataGasto.dias_trabajados === "" )  return;

    setTotalNoImponible(
        (movilizacion ?  parseInt(movilizacion) : 0) +
        (colacion ?  parseInt(colacion) : 0) +
        (dataGasto.asig_herramientas.length > 1 ? parseInt(dataGasto.asig_herramientas) : 0) +
        (dataGasto.aguinaldo.length > 1 ? parseInt(dataGasto.aguinaldo) : 0));

  },[ dataGasto.dias_trabajados,
    dataGasto.asig_herramientas,
    dataGasto.aguinaldo,
    colacion,
    movilizacion ]);


// //todo   Calculo de todas las imposiciones
  useEffect(() => {
    if(totalImponible === 0)  return;

    setAfp( (totalImponible * dataTrabajador.afp) );
    setSalud( getDescuentos(totalImponible, "salud"));
    setCesantia( getDescuentos(totalImponible, "cesantia") );
    setSis( getDescuentos(totalImponible, "sis") );
    setMutual( getDescuentos(totalImponible, "mutual") );
  },[ totalImponible, dataTrabajador, ])


  return (
    <form 
      className={`row g-4 mx-auto mt-2 rounded ${styles.Form}`}
      onSubmit={ handleSubmit }
      autoComplete="off">

      <p className="text-center mb-3" > Costo de Mano de Obra Detallado </p>

      <InputFormRegister 
        title='Fecha de Facturación:'
        handleChange={ handleInput }
        handleBlur={ (e) => validateInput(e, setErrorFecha, setActiveFecha, 'Ingresar fecha de la venta') }
        name="fechaVenta"
        value={ dataGasto.fechaGasto}
        defaultValue={ dataGasto.fechaGasto }
        error={ errorFecha }
        placeholder = 'Ejm: 67465'
        firstSymbol=''
        margin='t-0 mb-3'
        col={ 3 }
        type="date"
      />

      <div className={`col-md-3 m-0`}>
        <label className={`form-label ${styles.labelForm}`}>Colaborador:</label>
        <input
          onChange={ handleInput }
          type="text" 
          className={ `form-control ${ styles.inputForm }` }
          name="nombre_trabajador"
          value={ dataGasto.nombre_trabajador }
          readOnly
        />
        <span className={ styles.error }>Este dato no puede ser editado</span>
      </div>

      <div className={`col-md-3 m-0`}>
        <label className={`form-label ${styles.labelForm}`}>Proyecto:</label>
        <input
          onChange={ handleInput }
          type="text" 
          className={ `form-control ${ styles.inputForm }` }
          name="proyecto"
          value={ dataGasto.proyecto }
          readOnly
        />
        <span className={ styles.error }>Este dato no puede ser editado</span>
      </div>

      <InputFormRegister 
        title='Días trabajados:'
        handleChange={ handleInputDias }
        handleBlur={ (e) => validateInput(e, setErrorDias, setActiveDias, 'Ingrese los días trabajados') }
        name="dias_trabajados"
        value={ dataGasto.dias_trabajados}
        defaultValue={ dataGasto.dias_trabajados }
        error={ errorDias }
        firstSymbol=''
        margin='t-0 mb-3'
        col={ 3 }
        secondSymbol="dias"
      />

      <InputFormRegister 
        title='Bono de Seguridad:'
        handleChange={ handleInputNumeric }
        handleBlur={ (e) => validateInput(e, setErrorBonoSeguridad, setActiveBonoSeguridad, 'Ingrese un monto') }
        name="bono_seguridad"
        value={ dataGasto.bono_seguridad }
        error={ errorBonoSeguridad }
        margin='t-0 mb-3'
        col={ 3 }
      />

      <InputFormRegister 
        title='Bono Asistencia:'
        handleChange={ handleInputNumeric }
        handleBlur={ (e) => validateInput(e, setErrorBonoAsistencia, setActiveBonoAsistencia, 'Ingrese un monto')}
        name="bono_asistencia"
        value={ dataGasto.bono_asistencia }
        error={ errorBonoAsistencia }
        margin='t-0 mb-3'
        col={ 3 }
      />

      <InputFormRegister 
        title='Bono Objetivo:'
        handleChange={ handleInputNumeric }
        handleBlur={ (e) => validateInput(e, setErrorBonoObjetivo, setActiveBonoObjetivo, 'Ingrese un monto') }
        name="bono_objetivo"
        value={ dataGasto.bono_objetivo }
        error={ errorBonoObjetivo }
        margin='t-0 mb-3'
        col={ 3 }
      />

      <InputFormRegister 
        title='Horas no trabajadas:'
        handleChange={ handleInputNumeric }
        handleBlur={ (e) => validateInput(e, setErrorHorasNoTrabajadas, setActiveHorasNoTrabajadas, 'Ingrese un monto') }
        name="horas_no_trabajadas"
        value={ dataGasto.horas_no_trabajadas }
        error={ errorHorasNoTrabajadas }
        margin='t-0 mb-3'
        col={ 3 }
      />

      <InputFormRegister 
        title='Horas Extras:'
        handleChange={ handleInputNumeric }
        handleBlur={ (e) => validateInput(e, setErrorHorasExtras, setActiveHorasExtras, 'Ingrese un monto') }
        name="horas_extras"
        value={ dataGasto.horas_extras }
        error={ errorHorasExtras }
        margin='t-0 mb-3'
        col={ 3 }
      />

      <InputFormRegister 
        title='Asig. Herramienta:'
        handleChange={ handleInputNumeric }
        handleBlur={ (e) => validateInput(e, setErrorAsigHerramientas, setActiveAsigHerramientas, 'Ingrese un monto') }
        name="asig_herramientas"
        value={ dataGasto.asig_herramientas }
        error={ errorAsigHerramientas }
        margin='t-0 mb-3'
        col={ 3 }
      />

      <InputFormRegister 
        title='Bono Producción:'
        handleChange={ handleInputNumeric }
        handleBlur={ (e) => validateInput(e, setErrorBonoProduccion, setActiveBonoProduccion, 'Ingrese un monto') }
        name="bono_produccion"
        value={ dataGasto.bono_produccion }
        error={ errorBonoProduccion }
        margin='t-0 mb-3'
        col={ 3 }
      />

      <InputFormRegister 
        title='Aguinaldo:'
        handleChange={ handleInputNumeric }
        handleBlur={ (e) => validateInput(e, setErrorAguinaldo, setActiveAguinaldo, 'Ingrese un monto') }
        name="aguinaldo"
        value={ dataGasto.aguinaldo }
        error={ errorAguinaldo }
        margin='t-0 mb-3'
        col={ 3 }
      />

      <BtnNavigateDetail 
        handleClick={ () => navigate(-1) }  
        margin="mt-3"
      />

      <ToastContainer />
    </form>
  )
}
