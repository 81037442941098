import { useEffect, useState } from 'react';
import { SpinnerCard } from '../spinner/SpinnerCard';
import Chart from 'react-apexcharts';
import getSumaGastosXConceptoEBG from '../getSumaGastosXConceptoEBG';
import getSumaGastosEBG from '../getSumaGastosEBG';
import styles from './Graficos.module.css';



export default function GraficoGastosEBG() {

    const [ valorEquipos, setValorEquipos ] = useState(0);
    const [ valorMateriales, setValorMateriales ] = useState(0);
    const [ valorManoObra, setValorManoObra ] = useState(0);
    const [ valorCombustible, setValorCombustible ] = useState(0);
    const [ valorArriendos, setValorArriendos ] = useState(0);
    const [ valorUtilesOficina, setValorUtilesOficina ] = useState(0);
    const [ valorEPP, setValorEPP ] = useState(0);
    const [ valorMantenimiento, setValorMantenimiento ] = useState(0);
    const [ valorSeguros, setValorSeguros ] = useState(0);
    const [ valorFletes, setValorFletes ] = useState(0);
    const [ valorServicios, setValorServicios ] = useState(0);
    const [ valorExamenesMed, setValorExamenesMed ] = useState(0);
    const [ valorViaticos, setValorViaticos ] = useState(0);
    const [ valorContratistas, setValorContratistas ] = useState(0);
    const [ valorOtros, setValorOtros ] = useState(0);
    const [ valorFiniquito, setValorFiniquito ] = useState(0);
    const [ valorEBGCasaMatriz, setValorEBGCasaMatriz ] = useState(0);
    const [ valorPatentes, setValorPatentes ] = useState(0);
    const [ valorMultas, setValorMultas ] = useState(0);
    const [ valorVehiculos, setValorVehiculos ] = useState(0);
    const [ valorUtilesAseo, setValorUtilesAseo ] = useState(0);
    const [ gastosTotal, setGastosTotal ] = useState(0);

    const [ loading, setLoading ] = useState(true);


    const options = {
            colors: ['#0faa16'],
            chart: {
                type: 'bar',
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                    colors: {
                        backgroundBarColors: ['#B5C0B7'],
                    },
                },
            },
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: "12px",
                    fontWeight: 400,
                },
                formatter: function (val) {
                    return (`$ ` + new Intl.NumberFormat('de-DE').format(val) );
                },
            },
            xaxis: {
                categories: [   'Arriendos', "Contratistas", 'Combustible', 'EBG Casa Matriz', 'EPPs', 'Equipos', "Exam. Médicos", 'Finiquitos', 'Fletes',
                                "Mano de Obra", 'Mantenimientos', 'Materiales y Herr.', 'Multas', "Otros", 'Patentes', 'Seguros y/o Garantías',
                                'Servicios','Utls. de Aseo', 'Utls. de Escritorio', 'Vehículos', "Viáticos", 
                ],
                labels: {
                    style: {
                        fontSize: "12px",
                        fontWeight: "600",
                    },
                    formatter: function (val) {
                        return (`$ ${new Intl.NumberFormat('de-DE').format(val / 1000000)}M`);
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        fontSize: "12px",
                    },
                },
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return ( val !== 0  ?  `${((val / gastosTotal)*100).toFixed(2)}%` : 0);
                    },
                },
            }
            
        }

    const series = [
            {
                name: "Gasto",
                data: [ valorArriendos, valorContratistas, valorCombustible, valorEBGCasaMatriz, valorEPP, valorEquipos,
                    valorExamenesMed, valorFiniquito, valorFletes,  valorManoObra,
                    valorMantenimiento, valorMateriales, valorMultas, valorOtros, valorPatentes, valorSeguros, 
                    valorServicios, valorUtilesAseo, valorUtilesOficina, valorVehiculos, valorViaticos  ], 
            },
        ]


    useEffect( () => {
        getSumaGastosEBG(setGastosTotal)
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConceptoEBG( "Equipos", setValorEquipos);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConceptoEBG( "Materiales y Herramientas", setValorMateriales);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConceptoEBG( "Mano de Obra", setValorManoObra);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConceptoEBG( "Combustible", setValorCombustible);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConceptoEBG( "Arriendos", setValorArriendos);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConceptoEBG( "Utiles de escritorio", setValorUtilesOficina);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConceptoEBG( "EPPs", setValorEPP);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConceptoEBG( "Mantenimientos", setValorMantenimiento);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConceptoEBG( "Seguros", setValorSeguros);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConceptoEBG( "Fletes", setValorFletes);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConceptoEBG( "Servicios", setValorServicios);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConceptoEBG( "Exámenes médicos", setValorExamenesMed);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConceptoEBG( "Viáticos", setValorViaticos);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConceptoEBG( "Contratistas", setValorContratistas);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConceptoEBG( "Otros", setValorOtros);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConceptoEBG( "Finiquito", setValorFiniquito);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConceptoEBG( "Patentes", setValorPatentes);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConceptoEBG("Multas", setValorMultas);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConceptoEBG( "EBG Casa Matriz", setValorEBGCasaMatriz);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConceptoEBG("Vehículos", setValorVehiculos);
    }, [gastosTotal]);

    useEffect(() => {
        getSumaGastosXConceptoEBG("Utiles de aseo", setValorUtilesAseo);
    }, [gastosTotal]);

    useEffect(() => {
        if( gastosTotal > 0) {
            setLoading(false);
        }
    }, [gastosTotal]);


    return (<>
        {
            loading
            ?

            <div className={ `w-100 h-100 d-flex justify-content-center align-items-center ${ styles.wrapperLoading }` } >
                <SpinnerCard />
            </div> 

            :

            <Chart 
                options={ options }
                series={ series } 
                type="bar" 
                height={ 450 }
            />
        }
        
    </>)
}