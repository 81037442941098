import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getListaVentaXIndex } from "../../../helpers/getListaVentaXIndex";
import { formatNumDoc } from "../../../helpers/formatNumDoc";
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getVentas } from "../../../helpers/getVentas";
import { getFechaPago } from "../../../helpers/getFechaPago";
import { showMessageError, showMessageExit } from "../../../helpers/ShowMessage";
import { ToastContainer } from "react-toastify";
import { InputFormRegister } from "../../pure/form/components";
import { BtnNavigateDetail } from "../../pure/form/BtnNavigateDetail";
import getIDDoc from "../../../helpers/getIDDoc";
import db from "../../../backend/DBFiresbase";
import styles from './DetailStyles.module.css';




export const VentasDetail = () => {

  const { id } = useParams();
  const navigate = useNavigate();

  const proyecto = id.split("-")[0];
  const numFactura = id.split("-")[1];

  const [ idProyecto, setIdProyecto ] = useState("");
  const [ dataVenta, setDataVenta ] = useState({});
  const [ ventas, setVentas ] = useState([]);
  const [ errorNumConformidad, setErrorNumConformidad ] = useState('');
  const [ activeNumConformidad, setActiveNumConformidad ] = useState(true);
  const [ conformidadDuplicada, setConformidadDuplicada ] = useState("");
  const [ errorValorVenta, setErrorValorVenta ] = useState('');
  const [ activeValorVenta, setActiveValorVenta ] = useState(true);
  const [ errorFecha, setErrorFecha ] = useState('');
  const [ activeFecha, setActiveFecha ] = useState(true);
  const [ errorOrdenCompra, setErrorOrdenCompra ] = useState('');
  const [ activeOrdenCompra, setActiveOrdenCompra ] = useState(true);
  const [ errorOrdenCompraAdicional, setErrorOrdenCompraAdicional ] = useState('');
  const [ activeOrdenCompraAdicional, setactiveOrdenCompraAdicional ] = useState(true);
  const [ fechaVencimiento, setFechaVencimiento ] = useState('');



  //Obteniendo el valor del input
  const handleInput = (e) => {
    const {name, value} = e.target;
    setDataVenta({...dataVenta, [name]:value});
  }

  const validateInputNumeric = (e) => {
    const { name, value } = e.target;
    const formatValue = formatNumDoc(value);
    setDataVenta( { ...dataVenta, [name]:formatValue } );
  }

  const validateNumDoc = (e, setError, setActive, duplicado, doc) => {
    const value = e.target.value;

    if(value === ""){
        setError(`Ingresar N° de ${doc}`);
        setActive(false);
    } else {
        if(duplicado=== true || duplicado.length >= 1){
            setError(`La ${doc} ya está registrada`);
            setActive(false);
        } else {
            setActive(true);
            setError('');
        }
    }
  }

  const validateInput = (e, setError, setActive, mensaje) => {
    const value = e.target.value;

    if(value === ""){
      setError(mensaje);
      setActive(false);
    } else{
      setError('');
      setActive(true);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const proyectoRef = doc(db, "proyectos", idProyecto);
    const docRef = await getDoc(proyectoRef);

    // Actualiza el array en el documento
    if( activeNumConformidad &&  
        activeValorVenta  && 
        activeFecha && 
        activeOrdenCompra &&
        activeOrdenCompraAdicional
      ){
      const ventaIndex = docRef.data().ventas.findIndex( el => el.numDocumento === numFactura );
      const updatedVenta = {
        ...docRef.data().ventas[ventaIndex],
        numConformidad: dataVenta.numConformidad,
        valor: dataVenta.valor,
        fechaVenta: dataVenta.fechaVenta,
        fechaVencimiento: dataVenta.fechaVencimiento,
        ordenCompra: dataVenta.ordenCompra,
        nOCAdicional: dataVenta.nOCAdicional,
      }

      //Registrando la nueva venta editada
      const updateVentas = [...docRef.data().ventas];
      updateVentas[ventaIndex] = updatedVenta;

      //Actualizando la venta en Firestore
      showMessageExit("Actualización  exitosa");
      updateDoc(proyectoRef, { ventas: updateVentas });

    } else {
      showMessageError("¡Error!, tiene una casilla sin llenar", "bottom-center");
    }
  }


  useEffect(() => {
    getVentas(setVentas);
  }, [])

  useEffect(() => {
    getIDDoc("proyectos", proyecto, setIdProyecto)
  }, [])

  useEffect(() => {
    getListaVentaXIndex(proyecto, numFactura, setDataVenta);
  }, []);


  //Validando si hay un N° de conformidad duplicado
  useEffect(() => {
    let conformidad = ventas.map(el => el.numConformidad);

    if(dataVenta.numConformidad !== ''){
        setConformidadDuplicada(conformidad.includes(dataVenta.numConformidad));
    }
  },[ventas, dataVenta.numConformidad, conformidadDuplicada]);


  //Mostrando la fecha de pago o fecha de cobro
  useEffect(() => {
    ( dataVenta.fechaVenta !== undefined ) &&
    getFechaPago(dataVenta.fechaVenta, 30, setFechaVencimiento)
}, [dataVenta.fechaVenta])


  return (
    <form 
      className={`row g-4 mx-auto mt-2 rounded ${styles.Form}`}  
      onSubmit={ handleSubmit }
      autoComplete="off">

      <p className="text-center mb-0" >  {`Venta con Factura N° ${ dataVenta.numDocumento }`} </p>

      <div className="m-0 col-md-3">
        <label className={`form-label ${styles.labelForm} `}>Tipo de Venta:</label>
        <input
          className={`form-control ${ styles.inputForm }`}
          name="tipoVenta"
          defaultValue={dataVenta.tipoVenta}
          type="text"
          readOnly>
        </input>
        <span className={ styles.error }>Este dato no puede ser editado</span>
      </div>

      <div className="m-0 col-md-3">
        <label className={`form-label ${styles.labelForm} `}>N° de Factura:</label>
        <input
          className={`form-control ${ styles.inputForm }`}
          name="numDocumento"
          value={ dataVenta.numDocumento }
          type="text"
          readOnly>
        </input>
        <span className={ styles.error }>Este dato no puede ser editado</span>
      </div>

      <div className="m-0 col-md-3">
        <label className={`form-label ${styles.labelForm} `}>Proyecto:</label>
        <input
          className={`form-control ${ styles.inputForm }`}
          name="proyecto"
          defaultValue={dataVenta.proyecto}
          type="text"
          readOnly>
        </input>
        <span className={ styles.error }>Este dato no puede ser editado</span>
      </div>

      <div className="m-0 col-md-3">
        <label className={`form-label ${styles.labelForm} `}>Cliente:</label>
        <input
          className={`form-control ${ styles.inputForm }`}
          name="cliente"
          defaultValue={dataVenta.cliente}
          type="text"
          readOnly>
        </input>
        <span className={ styles.error }>Este dato no puede ser editado</span>
      </div>

      <div className="m-0 col-md-3">
        <label className={`form-label ${styles.labelForm} `}>RUT del Cliente:</label>
        <input
          className={`form-control ${ styles.inputForm }`}
          name="rutCliente"
          defaultValue={dataVenta.rutCliente}
          type="text"
          readOnly>
        </input>
        <span className={ styles.error }>Este dato no puede ser editado</span>
      </div>

      {
        dataVenta?.tipoVenta === 'Contractual' &&

        <InputFormRegister 
          title='N° Orden de Compra:'
          handleChange={ validateInputNumeric }
          handleBlur={ (e) => validateInput(e, setErrorOrdenCompra, setActiveOrdenCompra, 'Ingresar Orden de Compra') }
          name="ordenCompra"
          value={ dataVenta.ordenCompra }
          error={ errorOrdenCompra }
          placeholder = 'Ejm: 67465'
          firstSymbol=''
          margin='-0'
          col={ 3 }
        />
      }

      {
        dataVenta?.tipoVenta === 'Adicional' &&

        <InputFormRegister 
          title='N° Orden de Compra Adicional:'
          handleChange={ validateInputNumeric }
          handleBlur={ (e) => validateInput(e, setErrorOrdenCompraAdicional, setactiveOrdenCompraAdicional, 'Ingresar Orden de Compra') }
          name="nOCAdicional"
          value={ dataVenta.nOCAdicional }
          error={ errorOrdenCompraAdicional }
          placeholder = 'Ejm: 67465'
          firstSymbol=''
          margin='-0'
          col={ 3 }
        />
      }        

      <InputFormRegister 
        title='N° de Conformidad:'
        handleChange={ validateInputNumeric }
        handleBlur={ (e) => validateNumDoc(e, setErrorNumConformidad, setActiveNumConformidad, conformidadDuplicada, 'Conformidad') }
        name="numConformidad"
        value={ dataVenta.numConformidad }
        error={ errorNumConformidad }
        placeholder = 'Ejm: 67465'
        firstSymbol=''
        margin='-0'
        col={ 3 }
      />

      <InputFormRegister 
        title='Valor de la Factura:'
        handleChange={ validateInputNumeric }
        handleBlur={ (e) => validateInput(e, setErrorValorVenta, setActiveValorVenta, 'Ingresar el Valor Venta') }
        name="valor"
        value={ dataVenta.valor }
        error={ errorValorVenta }
        margin='-0'
        col={ 3 }
      />

      <InputFormRegister 
        title='Fecha de Facturación:'
        handleChange={ handleInput }
        handleBlur={ (e) => validateInput(e, setErrorFecha, setActiveFecha, 'Ingresar fecha de la venta') }
        name="fechaVenta"
        value={ dataVenta.fechaVenta }
        defaultValue={ dataVenta.fechaVenta }
        error={ errorFecha }
        placeholder = 'Ejm: 67465'
        firstSymbol=''
        margin='-0'
        col={ 3 }
        type="date"
      />

      <div className="m-0 col-md-3">
        <label className={`form-label ${styles.labelForm}`}>Fecha de Vencimiento:</label>
        <input
          type="date" 
          className={`form-control ${ styles.inputForm }`} 
          name="fechaVencimiento"
          defaultValue={fechaVencimiento}
          readOnly
        />
        <span className={ styles.error }>Este dato no puede ser editado</span>
      </div>

      <BtnNavigateDetail handleClick={ () => navigate(-1) }  />

      <ToastContainer />
    </form>
  )
}
